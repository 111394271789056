import {FreeformTextArea} from "@components";
import {FormQuestion} from "@store";
import {Box, BoxProps, Text} from "ferns-ui";
import React, {ReactElement} from "react";

export const FollowUpResponseBox = ({
  boxProps = {},
  disabled = false,
  followUpResponse = "",
  question,
  selectedOptions,
  onChange,
  onBlur,
}: {
  boxProps?: BoxProps;
  disabled?: boolean;
  followUpResponse?: string;
  question: FormQuestion;
  selectedOptions: string[];
  onChange: (value: any, isFollowUpResponse?: boolean) => void;
  onBlur: (value: any, isFollowUpResponse?: boolean) => Promise<void>;
}): ReactElement | null => {
  const shouldShowFollowUpResponse = Boolean(
    question?.followUpResponseSettings?.allowFollowUpResponse &&
      selectedOptions.some((so) =>
        question.followUpResponseSettings?.qualifyingOptions?.includes(so)
      )
  );
  if (!shouldShowFollowUpResponse) {
    return null;
  }
  return (
    <Box {...boxProps}>
      <Box marginTop={2} width="100%">
        <Text>{question.followUpResponseSettings?.prompt}</Text>
      </Box>
      <Box marginTop={2}>
        <FreeformTextArea
          answerRequiredErr={undefined}
          disabled={disabled}
          value={[followUpResponse]}
          onBlur={async (result): Promise<void> => {
            await onBlur(result[0], true);
          }}
          onChange={(result): void => {
            onChange(result[0], true);
          }}
        />
      </Box>
    </Box>
  );
};

import {
  AssessmentScore,
  FormQuestions,
  FormSignature,
  HeadingBox,
  QuestionPromptText,
} from "@components";
import {useAnalytics, useReadProfile} from "@hooks";
import {
  clearFormInstanceData,
  Form,
  useGetFormInstancesByIdQuery,
  usePatchFormInstancesByIdMutation,
  useSetFormInstanceState,
} from "@store";
import {StaffStackScreenProps} from "@types";
import {pageOnError} from "@utils";
import {Box, Button, Modal, Page, ScrollView, Spinner, Text} from "ferns-ui";
import React, {ReactElement, useEffect, useMemo, useState} from "react";
import {useDispatch} from "react-redux";

interface FormScoreModalProps {
  formInstanceId: string;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const FormScoreModal = ({
  formInstanceId,
  visible,
  setVisible,
}: FormScoreModalProps): React.ReactElement => {
  return (
    <Modal
      primaryButtonOnClick={(): void => setVisible(false)}
      primaryButtonText="Close"
      title="Assessment Complete"
      visible={visible}
      onDismiss={(): void => setVisible(false)}
    >
      <AssessmentScore formInstanceId={formInstanceId} />
    </Modal>
  );
};

interface ViewFormScreenProps extends StaffStackScreenProps<"ViewForm"> {}

export const ViewFormScreen = ({route, navigation}: ViewFormScreenProps): ReactElement | null => {
  const dispatch = useDispatch();
  const profile = useReadProfile();
  const formId = route.params.formId;
  const {data: formInstance} = useGetFormInstancesByIdQuery(formId);
  const [updateFormInstance] = usePatchFormInstancesByIdMutation();
  const form = formInstance?.form as Form | undefined;
  const [shouldScroll, setShouldScroll] = useState(true);
  const [showScoreModal, setShowScoreModal] = useState(false);
  const logEvent = useAnalytics();

  useSetFormInstanceState({
    formInstanceData: formInstance as any,
  });

  // Dispatch clearFormInstanceData when the component is unmounted
  useEffect(() => {
    return (): void => {
      if (formInstance?._id) {
        dispatch(clearFormInstanceData(formInstance._id));
      }
    };
  }, [dispatch, formInstance?._id]);

  // determine if form needs completion or not by the user and status of the form
  const formNeedsCompletion = useMemo(() => {
    if (!formInstance) {
      return false;
    } else {
      return formInstance.status === "Sent To User";
    }
  }, [formInstance]);

  const questionsAndAnswers: any[] = useMemo(
    () =>
      form?.questions.map((q) => ({
        question: q,
        answer: formInstance?.answers.find((a) => q._id === a.questionId),
      })) || [],
    [form?.questions, formInstance?.answers]
  );

  // Set the title of the screen to the form name.
  useEffect(() => {
    if (formInstance) {
      navigation.setOptions({title: form?.name || "Form"});
    }
  }, [form?.name, formInstance, navigation]);

  // we want to log every time a user views a form
  useEffect(() => {
    void logEvent({
      name: "FormInstanceViewed",
      userType: profile?.type,
      collectionModel: "formInstances",
      isActivityLogEvent: true,
      appliedUserId: formInstance?.userId,
      docId: formId,
    });
    // we only want to use this to log page views, so we don't want to run this effect again
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!formInstance || !form) {
    return (
      <Box alignItems="center" justifyContent="center" paddingY={4} width="100%">
        <Spinner />
      </Box>
    );
  }
  return (
    <Page navigation={navigation} scroll={false} onError={pageOnError}>
      <FormScoreModal
        formInstanceId={formInstance._id}
        setVisible={setShowScoreModal}
        visible={showScoreModal}
      />
      <ScrollView scrollEnabled={shouldScroll} showsVerticalScrollIndicator={false}>
        {formNeedsCompletion ? (
          <>
            <FormQuestions formInstanceId={formInstance._id} />
            {Boolean(form.requireUserSignature) ? (
              <FormSignature formId={formId} setShouldScroll={setShouldScroll} />
            ) : (
              <Button
                text="Complete"
                onClick={async (): Promise<void> => {
                  void logEvent({
                    name: "FormInstanceCompletedByUser",
                    userType: profile?.type,
                    collectionModel: "formInstances",
                    isUserUpdateEvent: true,
                    appliedUserId: formInstance.userId,
                    docId: formId,
                  });
                  await updateFormInstance({
                    id: formId,
                    body: {
                      status: "Completed",
                    },
                  });
                  setShowScoreModal(true);
                }}
              />
            )}
          </>
        ) : (
          <>
            <Box color="base" paddingY={2} rounding="md">
              <AssessmentScore formInstanceId={formInstance._id} />
            </Box>
            {questionsAndAnswers.map((item, index) => (
              <Box key={item.question._id} padding={2}>
                {Boolean(item.question.type !== "Heading") && (
                  <QuestionPromptText index={index} prompt={item.question.prompt} />
                )}
                {Boolean(item.question.type === "Heading") && (
                  <HeadingBox prompt={item.question.prompt} />
                )}
                {Boolean(item.answer) &&
                  item.answer.answers.map((a: any, i: any) => (
                    <Box key={item.question._id + i} marginTop={2} width="100%">
                      <Text>{a}</Text>
                    </Box>
                  ))}
              </Box>
            ))}
          </>
        )}
      </ScrollView>
    </Page>
  );
};

import {useFetchPopulatedScheduleItems, useReadProfile} from "@hooks";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  Form,
  FormInstance,
  GetUsersByIdRes,
  usePatchFormInstancesByIdMutation,
  useSelectFormInstance,
  useUIError,
  useUpdateFormInstanceState,
} from "@store";
import {
  BooleanField,
  Box,
  DateTimeField,
  MultiselectField,
  printDate,
  printTime,
  SelectField,
  Text,
} from "ferns-ui";
import {DateTime} from "luxon";
import React, {ReactElement, useCallback, useEffect, useState} from "react";

interface ScheduleItemFormInstanceLinkingProps {
  formInstanceId: string;
}

export const ScheduleItemFormInstanceLinking = ({
  formInstanceId,
}: ScheduleItemFormInstanceLinkingProps): ReactElement | null => {
  const rtkFormInstance = useSelectFormInstance(formInstanceId);
  const {
    data: formInstance,
    readOnly = false,
    linkItemToggle = false,
    isSupervisor = false,
  } = rtkFormInstance ?? {};
  const profile = useReadProfile();
  const uiError = useUIError();
  const updateFormInstState = useUpdateFormInstanceState(formInstanceId);
  const [updateFormInstance] = usePatchFormInstancesByIdMutation();

  const form = formInstance?.form as Form | undefined;
  const signed = formInstance?.status === "Signed";

  const [scheduleItemUsers, setScheduleItemUsers] = useState<
    {
      userId: GetUsersByIdRes;
    }[]
  >([]);

  // Pull all schedule items for the logged in staff member and selected user that are within -31 or
  // +7 days with unknown attendance status.
  const {data: scheduleItems} = useFetchPopulatedScheduleItems(
    form?.allowScheduleItemLinking && formInstance?.userId && profile
      ? {
          "users.userId": formInstance?.userId,
          // If the profile is a superuser/supervisor allow linking schedule items for other staff
          // members.
          ...(isSupervisor ? {} : {"staff.userId": {$in: [profile._id]}}),
          attendanceStatus: "Unknown",
          startDatetime: {$gte: DateTime.now().startOf("day").minus({days: 31}).toISO()},
          endDatetime: {$lte: DateTime.now().startOf("day").plus({days: 7}).toISO()},
        }
      : skipToken
  );

  const UnsetAttendanceData = {
    attendanceStatus: null as unknown as string | undefined,
    attended: [],
  };

  const UnsetLinkingData = {
    ...UnsetAttendanceData,
    scheduleItemId: null as unknown as string | undefined,
  };

  const resetLinkingState = useCallback(() => {
    setScheduleItemUsers([]);
  }, []);

  const updateFormInstanceData = useCallback(
    async (body: Partial<FormInstance>) => {
      await updateFormInstance({id: formInstanceId, body})
        .unwrap()
        .catch((error) => {
          uiError(`Error saving update to form. Please try again before continuing.`, error);
        });
    },
    [formInstanceId, updateFormInstance, uiError]
  );

  // keep data up to date if store is updated
  useEffect(() => {
    if (formInstance?.scheduleItemId) {
      const selectedScheduleItem = scheduleItems.data?.find(
        (scheduleItem) => scheduleItem._id === formInstance?.scheduleItemId
      );
      if (selectedScheduleItem) {
        setScheduleItemUsers(selectedScheduleItem?.users ?? []);

        // Filter out any unmatched users from the current attended list
        const validAttendees = (formInstance.attended ?? []).filter((attendedUser) =>
          selectedScheduleItem.users.some(
            (scheduleUser) => scheduleUser.userId._id === attendedUser.userId
          )
        );
        if (validAttendees.length !== formInstance.attended?.length) {
          void updateFormInstanceData({attended: validAttendees});
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInstance, scheduleItems?.data]);

  const linkedScheduleItemOptions = scheduleItems.data?.map((scheduleItem) => {
    // TODO: Store timezone on the schedule item.
    const timezone =
      scheduleItem.users.find((u) => u.userId?.timezone)?.userId?.timezone ?? "America/New_York";

    return {
      label: `${printDate(scheduleItem.startDatetime)}, ${printTime(scheduleItem.startDatetime, {
        timezone,
        showTimezone: false,
      })} - ${printTime(scheduleItem.endDatetime, {timezone, showTimezone: true})}: ${
        scheduleItem.type
      }`,
      value: scheduleItem._id,
    };
  });

  if (!rtkFormInstance || !formInstance) {
    return null;
  }

  return (
    <Box>
      {Boolean(!readOnly && form?.allowScheduleItemLinking && scheduleItems?.data?.length) && (
        <Box maxWidth={100} paddingY={2}>
          {Boolean(!signed && !readOnly) && (
            <BooleanField
              title="Link Schedule Item  "
              value={linkItemToggle}
              onChange={async (value): Promise<void> => {
                updateFormInstState({linkItemToggle: value});
                const updatedForm = {
                  ...formInstance,
                  ...UnsetLinkingData,
                };
                await updateFormInstanceData(updatedForm);
              }}
            />
          )}
          {Boolean(linkItemToggle) && (
            <Box width={300}>
              <SelectField
                disabled={signed || readOnly}
                options={[{label: "Please Select", value: "unknown"}, ...linkedScheduleItemOptions]}
                placeholder="None selected"
                requireValue
                title="Select Encounter"
                value={formInstance?.scheduleItemId}
                onChange={async (id): Promise<void> => {
                  if (id) {
                    const selectedScheduleItem = scheduleItems.data?.find(
                      (scheduleItem) => scheduleItem._id === id
                    );
                    if (selectedScheduleItem) {
                      await updateFormInstanceData({
                        serviceDate: selectedScheduleItem?.startDatetime,
                        scheduleItemId: id,
                        ...UnsetAttendanceData,
                      });
                      return;
                    }
                  }
                  resetLinkingState();
                  await updateFormInstanceData({
                    ...formInstance,
                    ...UnsetLinkingData,
                  });
                }}
              />
            </Box>
          )}
        </Box>
      )}
      <Box>
        <Box direction="row" paddingY={1} width="100%">
          <Box flex="grow" marginRight={2} maxWidth="50%">
            <DateTimeField
              disabled={linkItemToggle || signed || readOnly}
              helperText={`${
                linkItemToggle
                  ? "Linked schedule item dates/times are updated automatically upon selection of encounter from the dropdown above."
                  : "Note: You are reporting in your local timezone."
              }`}
              title="Date Of Service"
              type="datetime"
              value={formInstance?.serviceDate}
              onChange={async (r?: string): Promise<void> => {
                await updateFormInstanceData({
                  serviceDate: r,
                });
              }}
            />
          </Box>
        </Box>
        {Boolean(formInstance?.serviceDate) && Boolean(!form?.hideAttendanceStatus) && (
          <Box direction="row" width="100%">
            <Box flex="grow" marginRight={2} maxWidth="50%">
              <SelectField
                disabled={signed || readOnly}
                options={[
                  ...["Attended", "No-Show", "Cancellation", "Reschedule"].map((status) => {
                    return {label: status, value: status};
                  }),
                ]}
                placeholder="None Selected"
                requireValue={false}
                title={`Attendance Status${linkItemToggle ? "" : "- Optional"}`}
                value={formInstance?.attendanceStatus}
                // value={attendanceStatus}
                onChange={async (status?: string): Promise<void> => {
                  if (formInstance.attendanceStatus && !status) {
                    // handle clearing of attendance status when "None Selected" is chosen
                    await updateFormInstanceData({
                      ...UnsetAttendanceData,
                    });
                  } else {
                    await updateFormInstanceData({
                      attendanceStatus: status as any,
                    });
                    // Default to all users when status is attended
                    if (status === "Attended") {
                      // If a schedule item is linked, default to all users in the schedule item
                      // else default to the user of whom the form instance is for.
                      const allUsers = formInstance?.scheduleItemId
                        ? scheduleItemUsers.map((u) => {
                            return {userId: u.userId._id};
                          })
                        : [{userId: formInstance?.userId}];
                      await updateFormInstanceData({
                        attendanceStatus: status as any,
                        attended: allUsers,
                      });
                    } else {
                      await updateFormInstanceData({
                        attendanceStatus: status as any,
                        attended: [],
                      });
                    }
                  }
                }}
              />
              {Boolean(form?.allowScheduleItemLinking) && Boolean(!linkItemToggle) && (
                <Text size="sm">
                  Note: Patient engagement will not be tracked via attendance status unless a
                  schedule item is linked to a note.
                </Text>
              )}
            </Box>
          </Box>
        )}
      </Box>
      {/* Only update attended array if schedule item has more than one attendee and status is attended */}
      {Boolean(
        linkItemToggle &&
          scheduleItemUsers?.length > 1 &&
          formInstance?.attendanceStatus === "Attended"
      ) && (
        <Box paddingY={3}>
          <MultiselectField
            options={scheduleItemUsers.map((user) => ({
              value: user.userId._id,
              label: user.userId.name,
            }))}
            title="Please select all users in attendance"
            value={(formInstance.attended ?? []).reduce<string[]>((acc, attendedUser) => {
              const matchedUser = scheduleItemUsers.find(
                (scheduleUser) => scheduleUser.userId._id === attendedUser.userId
              );
              if (matchedUser) {
                acc.push(matchedUser.userId._id);
              }
              return acc;
            }, [])}
            onChange={async (selected: string[]): Promise<void> => {
              const attendees = selected.map((userId) => ({userId}));
              await updateFormInstanceData({
                attended: attendees,
              });
            }}
          />
        </Box>
      )}
    </Box>
  );
};

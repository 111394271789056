import {skipToken} from "@reduxjs/toolkit/query/react";
import {FetchPopulatedRes, GetMessagesArgs, PopulatedMessage, useGetMessagesQuery} from "@store";
import flatten from "lodash/flatten";
import uniq from "lodash/uniq";
import {useMemo} from "react";

import {useGetPopulateUserLookup} from "./useGetPopulateUserLookup";

export function useFetchPopulatedMessages(
  query: GetMessagesArgs | typeof skipToken
): FetchPopulatedRes<PopulatedMessage> {
  const messagesQuery = useGetMessagesQuery(query);
  const populateIdList = uniq(
    flatten(
      messagesQuery?.data?.data?.map(
        (m) => [...m?.taggedUsers?.map((u) => u.userId)].filter((id) => id) as string[]
      )
    )
  );

  const userLookupTableQuery = useGetPopulateUserLookup(populateIdList);
  const userLookupTable = userLookupTableQuery.userLookup;

  const userData = useMemo(() => {
    const populatedList: PopulatedMessage[] = [];
    for (const item of messagesQuery.data?.data ?? []) {
      const populated = {...item} as any as PopulatedMessage;

      if (Object.keys(userLookupTable).length) {
        populated.taggedUsers = item.taggedUsers.map((u) => ({
          userId: userLookupTable[u?.userId],
        }));
      } else {
        populated.taggedUsers = [];
      }

      populatedList.push(populated);
    }
    return populatedList;
  }, [userLookupTable, messagesQuery.data?.data]);

  return {
    data: {...messagesQuery.data, data: userData},
    isSuccess:
      messagesQuery.isSuccess && (populateIdList.length === 0 || userLookupTableQuery.isSuccess),
    error: messagesQuery.error || userLookupTableQuery.error,
    isError: messagesQuery.isError || userLookupTableQuery.isError,
    isLoading: messagesQuery.isLoading || userLookupTableQuery.isLoading,
    isFetching: messagesQuery.isFetching || userLookupTableQuery.isFetching,
  };
}

import {useSelectCurrentUserId} from "@ferns-rtk";
import {
  useGetAvatarsQuery,
  useGetUserBioQuery,
  useGetUsersByIdQuery,
  usePatchAvatarsByIdMutation,
  usePatchUserBioByIdMutation,
  usePostAvatarsMutation,
  usePostUserBioMutation,
  userName,
} from "@store";
import {StaffStackScreenProps} from "@types";
import {IsWeb, pageOnError} from "@utils";
import {
  Avatar,
  Box,
  Button,
  Card,
  Heading,
  IconButton,
  Page,
  Spinner,
  Text,
  TextArea,
} from "ferns-ui";
import React, {ReactElement, useEffect, useState} from "react";

interface Props extends StaffStackScreenProps<"UserBio"> {}

export const UserBioScreen = ({route, navigation}: Props): ReactElement | null => {
  const {data: userBioList} = useGetUserBioQuery({userId: route.params.userId});
  const {data: userAvatarList} = useGetAvatarsQuery({ownerId: route.params.userId});
  const userBio = userBioList?.data?.[0];
  const displayedUserId = route.params.userId;
  const userAvatar = userAvatarList?.data?.[0];
  const [aboutMe, setAboutMe] = useState(userBio?.aboutMe ?? undefined);
  const [editing, setEditing] = useState(false);
  const [avatar, setAvatar] = useState(userAvatar ?? undefined);
  // This is used to save the previous value of the bio before changes are made
  // so that they user can cancel changes and retain the original value
  const [prevEdit, setPrevEdit] = useState(userBio?.aboutMe ?? undefined);
  const isCurrentUserBio = route.params.userId === useSelectCurrentUserId();
  const [updateUserBio, {isLoading: updateLoading}] = usePatchUserBioByIdMutation();
  const [createUserBio, {isLoading: createLoading}] = usePostUserBioMutation();
  const [createAvatar] = usePostAvatarsMutation();
  const [updateAvatar] = usePatchAvatarsByIdMutation();
  const {data: displayedUser} = useGetUsersByIdQuery(displayedUserId);

  // Once the user bio is loaded, set the about me text.
  useEffect(() => {
    if (userBio) {
      setAboutMe(userBio.aboutMe);
      setPrevEdit(userBio.aboutMe);
    } else if (displayedUser) {
      setAboutMe(`Hi, my name is ${userName(displayedUser)}!`);
    }
    setAvatar(userAvatar);
  }, [userBio, userAvatar, displayedUser]);

  if (!displayedUser) {
    return <Spinner />;
  }

  let location = "";
  if (displayedUser?.address?.city && displayedUser?.address?.state) {
    location = `${displayedUser?.address?.city}, ${displayedUser?.address?.state}`;
  }

  return (
    <Page navigation={navigation} onError={pageOnError}>
      <Card padding={5}>
        <Box direction="row" justifyContent="center" marginBottom={3}>
          <Avatar
            // TODO: Edit avatar requires permission checks in mobile
            name={userName(displayedUser)}
            size="xl"
            src={avatar?.imageMediaLink}
            status={Boolean(isCurrentUserBio && IsWeb) ? "imagePicker" : undefined}
            onChange={async (image): Promise<void> => {
              if (avatar) {
                await updateAvatar({
                  id: avatar._id,
                  body: {
                    width: String(image.width ?? 0),
                    height: String(image.height ?? 0),
                    imageUri: image.uri,
                    imageFormat: image.avatarImageFormat,
                  },
                });
              } else {
                await createAvatar({
                  ownerId: displayedUser?._id,
                  width: String(image.width ?? 0),
                  height: String(image.height ?? 0),
                  imageUri: image.uri,
                  imageFormat: image.avatarImageFormat,
                });
              }
            }}
          />
        </Box>
        <Box alignItems="center" direction="row" justifyContent="center" marginBottom={3}>
          <Text bold size="lg">
            {location}
          </Text>
        </Box>
        <Box
          alignItems="center"
          direction="row"
          justifyContent="between"
          marginBottom={5}
          marginTop={3}
        >
          <Box alignSelf="start">
            <Heading>Bio</Heading>
          </Box>
          {isCurrentUserBio && (
            <IconButton
              accessibilityHint="Edit bio"
              accessibilityLabel="Edit"
              iconName="pencil"
              onClick={(): void => {
                setEditing(true);
                setPrevEdit(aboutMe);
              }}
            />
          )}
        </Box>

        {editing ? (
          <Box>
            <TextArea
              key="aboutMe"
              helperText={"Use 'Shift + Enter' to create a newline"}
              placeholder="We'd love to get to know you!"
              rows={6}
              value={aboutMe}
              onChange={setAboutMe}
            />
            <Box direction="row" justifyContent="end">
              <Box marginRight={4}>
                <Button
                  disabled={createLoading || updateLoading}
                  loading={createLoading || updateLoading}
                  text="Save changes"
                  onClick={async (): Promise<void> => {
                    if (userBio?._id) {
                      await updateUserBio({
                        id: userBio._id,
                        body: {
                          aboutMe,
                        },
                      });
                      setEditing(false);
                    } else {
                      await createUserBio({
                        aboutMe,
                        userId: displayedUser._id,
                      });
                      setEditing(false);
                    }
                    setPrevEdit(aboutMe);
                  }}
                />
              </Box>
              <Box>
                <Button
                  confirmationText="Are you sure you want to cancel? Your changes will not be saved."
                  text="Cancel changes"
                  variant="destructive"
                  withConfirmation
                  onClick={(): void => {
                    setEditing(false);
                    setAboutMe(prevEdit);
                  }}
                />
              </Box>
            </Box>
          </Box>
        ) : (
          <Box>
            <Text size="lg">{aboutMe}</Text>
          </Box>
        )}
      </Card>
    </Page>
  );
};

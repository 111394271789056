import {ActivityLogView, ClinicalView, FitbitView, UserInfoView} from "@components";
import {
  setWorkflowMappingId,
  useAppDispatch,
  useCollapseAllRightBar,
  useSetCollapseAllRightBar,
} from "@store";
import {IsMobileDevice} from "@utils";
import {BooleanField, Box, ScrollView, SegmentedControl} from "ferns-ui";
import React, {ReactElement, useState} from "react";

interface StaffRightBarProps {
  userId?: string;
}

export const StaffRightBar = ({userId}: StaffRightBarProps): ReactElement | null => {
  const dispatch = useAppDispatch();

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const collapsed = useCollapseAllRightBar();
  const setCollapsed = useSetCollapseAllRightBar();
  const tabs = ["Clinical", "User", "Fitbit", "Activity"];

  if (!userId) {
    return null;
  }

  const shouldRenderCollapsedBooleanField =
    !IsMobileDevice && (activeTabIndex === 0 || activeTabIndex === 1);

  return (
    <Box color="base" marginLeft={2} paddingY={4}>
      <Box paddingX={4}>
        <SegmentedControl
          items={tabs}
          selectedIndex={activeTabIndex}
          onChange={setActiveTabIndex}
        />

        {Boolean(shouldRenderCollapsedBooleanField) && (
          <Box alignItems="center" direction="row" paddingY={4}>
            <BooleanField
              title="Collapse / Uncollapse All Sections"
              value={collapsed}
              onChange={setCollapsed}
            />
          </Box>
        )}
      </Box>
      <ScrollView
        contentContainerStyle={{
          alignItems: "stretch",
          marginTop: 10,
          paddingHorizontal: 16,
        }}
      >
        {Boolean(tabs[activeTabIndex] === "Clinical") && (
          <ClinicalView key={userId} userId={userId} />
        )}
        {Boolean(tabs[activeTabIndex] === "User") && (
          <UserInfoView
            userId={userId}
            onRemoveUser={async (): Promise<void> => {
              dispatch(setWorkflowMappingId(undefined));
            }}
          />
        )}
        {Boolean(tabs[activeTabIndex] === "Fitbit") && <FitbitView userId={userId} />}
        {Boolean(tabs[activeTabIndex] === "Activity") && <ActivityLogView userId={userId} />}
      </ScrollView>
    </Box>
  );
};

import {
  useCurrentWorkflowMapping,
  useGetConversationForWorkflowMapping,
  useReadProfile,
} from "@hooks";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  isOutOfOffice,
  otherConversationUsers,
  useGetAlertInstancesQuery,
  useGetUsersByIdQuery,
  useGetUserSessionsQuery,
  useSelectWorkflowStaffId,
  useUpdateLastReadMutation,
} from "@store";
import {IsIos, KEYBOARD_VERTICAL_OFFSET} from "@utils";
import {Box} from "ferns-ui";
import React, {ReactElement, useMemo} from "react";
import {KeyboardAvoidingView} from "react-native";

import {AlertBanner} from "../alerts";
import {StaffOfflineBanner} from "../StaffOfflineBanner";
import {ChatBanner} from "./ChatBanner";
import {ChatBox} from "./ChatBox";
import {NoConversation} from "./ChatNoConversation";
import {ChatViewHeader} from "./ChatViewHeader";
import {CriticalEventHelpModal} from "./CriticalEventHelpModal";

interface ChatViewProps {
  disableComposer?: boolean;
  setShowHelpModal: (show: boolean) => void;
  showHelpModal: boolean;
}

export const WorkflowChatView = ({
  disableComposer,
  setShowHelpModal,
  showHelpModal,
}: ChatViewProps): ReactElement | null => {
  const currentWorkflowMapping = useCurrentWorkflowMapping();
  // Member, family member, or staff user who the workflow mapping is about
  const userId = currentWorkflowMapping?.userId;
  const {data: userSessionsData} = useGetUserSessionsQuery(userId ? {ownerId: userId} : skipToken);
  const staffId = useSelectWorkflowStaffId();
  const {conversation, isLoading: isLoadingConversation} = useGetConversationForWorkflowMapping(
    staffId ? currentWorkflowMapping : undefined
  );

  const profile = useReadProfile();
  const {data: displayedUser, isLoading: isLoadingUser} = useGetUsersByIdQuery(userId ?? skipToken);
  const {data: unresolvedAlerts} = useGetAlertInstancesQuery(
    displayedUser?._id
      ? {
          associatedUserId: displayedUser?._id,
        }
      : skipToken
  );
  const isLoading =
    isLoadingConversation ||
    !currentWorkflowMapping ||
    !userId ||
    !displayedUser ||
    !userSessionsData ||
    isLoadingUser;

  const [updateLastRead] = useUpdateLastReadMutation();

  const showSendAsSms: boolean = useMemo(() => {
    if (!conversation || !profile) {
      return false;
    }
    const otherConversationMembers = otherConversationUsers(conversation, profile._id);
    if (
      otherConversationMembers &&
      otherConversationMembers.length === 1 &&
      otherConversationMembers[0]?._id
    ) {
      const otherConversationMember = otherConversationMembers[0];
      return Boolean(
        otherConversationMember.smsEnabled &&
          otherConversationMember.smsMessaging &&
          Boolean(otherConversationMember.phoneNumber)
      );
    } else {
      return false;
    }
  }, [conversation, profile]);

  if (!profile || !displayedUser) {
    return null;
  }

  const isMyWorkflow = Boolean(staffId && staffId === profile._id);

  const markRead = async (): Promise<void> => {
    if (!conversation) {
      console.error("No conversation to mark read");
      return Promise.resolve();
    }
    await updateLastRead({
      conversationId: conversation._id,
      ownerId: profile._id,
      lastReadDateTime: new Date(),
    }).unwrap();
  };

  return (
    <KeyboardAvoidingView
      behavior={IsIos ? "padding" : "height"}
      keyboardVerticalOffset={KEYBOARD_VERTICAL_OFFSET}
      style={{flex: 1}}
    >
      <CriticalEventHelpModal
        associatedUserId={userId}
        setHelpModal={setShowHelpModal}
        showHelpModal={showHelpModal}
      />
      <Box direction="row" flex="grow" height="100%" justifyContent="center" width="100%">
        <Box direction="row" height="100%" justifyContent="center" width="100%">
          <Box color="base" justifyContent="start" rounding="md" width="100%">
            <ChatViewHeader />
            <ChatBanner />
            <StaffOfflineBanner />
            <AlertBanner
              alertInstance={unresolvedAlerts?.data ? unresolvedAlerts?.data[0] : undefined}
            />
            <NoConversation />
            {conversation && !isLoading && (
              <ChatBox
                key={conversation?._id}
                allowDelete
                // hide send button if you are not in your own workflow
                alwaysShowSend={isMyWorkflow}
                conversationId={conversation._id}
                // disable composer if you are not in your own workflow
                disableComposer={!isMyWorkflow || isOutOfOffice(profile) || disableComposer}
                showSendAsSms={showSendAsSms}
                onSend={markRead}
              />
            )}
          </Box>
        </Box>
      </Box>
    </KeyboardAvoidingView>
  );
};

import {
  flourishApi,
  getCurrentExpoToken,
  logout,
  resetAppState,
  resetFormInstanceStore,
  useOfflineToggleMutation,
  usePatchUsersByIdMutation,
} from "@store";
import {IsWeb, UserTypes} from "@utils";
import {useDispatch} from "react-redux";

import {useReadProfile} from "./useReadProfile";

interface LogoutUser {
  (): Promise<void>;
}

export function useLogoutUser(): LogoutUser {
  const user = useReadProfile();
  const [offlineToggle] = useOfflineToggleMutation();
  const [updateUser] = usePatchUsersByIdMutation();
  const dispatch = useDispatch();
  return async (): Promise<void> => {
    if (user && !IsWeb) {
      console.debug(`Clearing expoToken for user ${user._id}`);
      // we only want to remove the expo token if they are logging out of the mobile app
      const expoPushToken = await getCurrentExpoToken();
      // remove this token from the expoTokens array
      if (expoPushToken) {
        await updateUser({
          id: user._id,
          body: {
            expoTokens: user.expoTokens?.filter((t) => t !== expoPushToken.data.toString()),
          },
        });
      }
    }
    if (user?.online?.forPatients) {
      await offlineToggle({
        _id: user?._id,
        type: UserTypes.Patient,
      });
    }
    if (user?.online?.forFamilyMembers) {
      await offlineToggle({
        _id: user?._id,
        type: UserTypes.FamilyMember,
      });
    }
    // TODO: Resolve resetAppState and logout() happening asynchronously in order to purge RTK
    // simultaneously and remove the need for memoizedUser in App.tsx
    dispatch(logout());
    dispatch(resetFormInstanceStore());
    dispatch(resetAppState());
    dispatch(flourishApi.util.resetApiState());
  };
}

import {baseUrl} from "@ferns-rtk";
import {useReadProfile} from "@hooks";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  Form,
  useGetUsersByIdQuery,
  usePatchFormInstancesByIdMutation,
  useSelectFormInstance,
} from "@store";
import {Box, Button, ButtonProps, Text, TextColor} from "ferns-ui";
import {DateTime} from "luxon";
import React, {ReactElement, useCallback, useMemo, useState} from "react";

import {DownloadFileButton} from "../DownloadFileButton";

interface FormEditorButtonsProps {
  formInstanceId: string;
}

const AlertMessage = ({message, color}: {message: string; color?: TextColor}): ReactElement => (
  <Box paddingY={1} width="100%">
    <Text color={color}>{message}</Text>
  </Box>
);

export const FormEditorButtons = ({
  formInstanceId,
}: FormEditorButtonsProps): ReactElement | null => {
  const profile = useReadProfile();
  const rtkFormInstance = useSelectFormInstance(formInstanceId);
  const {
    data: formInstance,
    validationErrors,
    readOnly = false,
    linkItemToggle = false,
    isSupervisor = false,
  } = rtkFormInstance ?? {};
  const [updateFormInstance, {isLoading}] = usePatchFormInstancesByIdMutation();
  const {data: formUser} = useGetUsersByIdQuery(formInstance?.userId ?? skipToken);
  const {data: createdByUser} = useGetUsersByIdQuery(formInstance?.createdBy ?? skipToken);
  const form = formInstance?.form as Form | undefined;

  const [formWarning, setFormWarning] = useState("");
  const [success, setSuccess] = useState("");

  const currentStatus = formInstance?.status;
  const readyForSignature =
    currentStatus && ["Completed", "Requires Supervisor Action"].includes(currentStatus);

  const isNotSupervisorForSignature =
    currentStatus === "Requires Supervisor Action" && !isSupervisor;

  const isNotCreatedByUserForSignature =
    currentStatus === "Completed" && createdByUser?._id !== profile?._id;

  const formErrors = useMemo(() => {
    if (!formInstance || currentStatus === "Signed") return null;

    const errors = [];

    if (linkItemToggle && !formInstance.scheduleItemId) {
      errors.push('"Link Schedule Item" is activated. Please Select an Encounter or deactivate.');
    }

    if (validationErrors) {
      errors.push(...validationErrors);
    }

    setFormWarning(
      errors.length
        ? `Errors must be resolved before ${readyForSignature ? "signing" : "completing"}. Your updates will still save.`
        : ""
    );
    return errors.length ? errors : null;
  }, [currentStatus, linkItemToggle, formInstance, readyForSignature, validationErrors]);

  const disableSubmit = useMemo(() => {
    if (
      !form?.questions ||
      formErrors ||
      isNotSupervisorForSignature ||
      isNotCreatedByUserForSignature
    )
      return true;
    return false;
  }, [form, formErrors, isNotSupervisorForSignature, isNotCreatedByUserForSignature]);

  const handleStatusUpdate = useCallback(
    async (status: "Signed" | "Completed") => {
      if (!formInstance) {
        setFormWarning("No form instance available. Unable to sign.");
        return;
      }
      try {
        await updateFormInstance({
          id: formInstance._id,
          body: {status},
        }).unwrap();
        setSuccess(status);
      } catch (error) {
        setFormWarning(`Error submitting note. Please try again.: ${error}`);
      }
    },
    [updateFormInstance, formInstance]
  );

  const getPDFFilename = useCallback(() => {
    const name = formUser?.name?.replace(/\s+/g, "-").toLowerCase();
    const formName = form?.name?.replace(/\s+/g, "-").toLowerCase();
    const date = formInstance?.completedDate
      ? DateTime.fromISO(formInstance.completedDate).toFormat("MM-dd-yyyy")
      : "";
    return `${name}-${formName}${date ? `-${date}` : ""}`;
  }, [formUser, form, formInstance]);

  if (!formInstance || !form || !currentStatus) {
    return null;
  }

  // A form must be completed before it can be signed
  // Only the createdByUser can submit the first signature
  // A supervisor can sign a form if form requires supervisor action
  const updateButtonProps: ButtonProps = {
    // to update to Completed
    variant: "primary",
    disabled: disableSubmit || isLoading || readOnly,
    iconName: undefined,
    loading: isLoading,
    text: "✓  Complete",
    onClick: (): void => {
      const updatedStatus = readyForSignature ? "Signed" : "Completed";
      void handleStatusUpdate(updatedStatus);
    },
  };
  if (readyForSignature) {
    // to update to Signed
    updateButtonProps.text = "Sign";
    updateButtonProps.variant = "secondary";
    updateButtonProps.iconName = "pencil";
    updateButtonProps.onClick = (): void => {
      const updatedStatus = "Signed";
      void handleStatusUpdate(updatedStatus);
    };
    // if form requires supervisor action, supervisor can sign
    if (currentStatus === "Requires Supervisor Action") {
      updateButtonProps.text = "Add Supervisor Signature";
      updateButtonProps.iconName = "plus";
    }
  }

  return (
    <>
      {formWarning && <AlertMessage color="error" message={formWarning} />}
      {success && <AlertMessage message={success} />}
      <Box direction="row" width="100%">
        <Box marginLeft={2}>
          {currentStatus === "Signed" ? (
            // Signed forms indicate a form is finalized and can be printed
            <DownloadFileButton
              filename={`${getPDFFilename()}.pdf`}
              text="Print"
              url={`${baseUrl}/formInstancesPDF/${formInstance?._id}`}
            />
          ) : (
            <Button {...updateButtonProps} />
          )}
        </Box>
      </Box>
      {isNotCreatedByUserForSignature && (
        <AlertMessage
          color="error"
          message={
            form?.requireSupervisorSignature && isSupervisor
              ? "Note must be signed by creating staff prior to allowing supervisor signature."
              : "Cannot sign note because you did not create it."
          }
        />
      )}
      {formErrors &&
        formErrors.map((error, i) => (
          <Box key={`${error}-${i}`} paddingY={1}>
            <Text color="error">{error}</Text>
          </Box>
        ))}
      {!["Completed", "Requires Supervisor Action", "Signed"].includes(currentStatus) && (
        <AlertMessage message="Clicking 'Complete' indicates you are done writing. You can still make changes later." />
      )}
      {currentStatus === "Requires Supervisor Action" ? (
        <>
          <AlertMessage message="A supervisors signature is required." />
          <AlertMessage
            message={
              isSupervisor
                ? `This ${form?.type} is signed and locked by the creating staff member. As a supervisor, you can revise their answers, but they cannot, as the form is now locked for non-supervisors.`
                : "Please contact your supervisor to review and add their signature to this form."
            }
          />
        </>
      ) : currentStatus !== "Signed" ? (
        <AlertMessage
          message={`${form?.type}s can only be signed by the staff who created it. Once Signed it cannot be changed.`}
        />
      ) : null}
    </>
  );
};

import {
  Form,
  FormInstance,
  isQuestionDisabled,
  usePatchFormInstanceAnswerMutation,
  useSelectFormInstance,
  useUIError,
  useUpdateFormInstanceAnswerState,
  useUpdateFormInstanceState,
} from "@store";
import {Box, humanDateAndTime, Text} from "ferns-ui";
import React, {ReactElement, useCallback, useMemo} from "react";

import {FormQuestionComponent} from "../formInstanceQuestions";

const compileUpdatedAnswer = (
  existingAnswer: FormInstance["answers"][number] | undefined,
  value: string | string[],
  question: Form["questions"][number],
  isFollowUpResponse: boolean
): FormInstance["answers"][number] => {
  const updatedAnswer = {...(existingAnswer ?? {})} as FormInstance["answers"][number];

  if (isFollowUpResponse) {
    updatedAnswer.followUpResponse = value as string;
  } else {
    const answerArray = Array.isArray(value) ? value : [value];
    const score = answerArray.reduce((total, v) => {
      return total + (question?.options?.find((o) => o.label === v)?.score ?? 0);
    }, 0);

    const requiresNewFollowUpResponse =
      question?.followUpResponseSettings?.allowFollowUpResponse &&
      !answerArray.some((answer) =>
        question.followUpResponseSettings?.qualifyingOptions?.includes(answer)
      );

    updatedAnswer.answers = answerArray;
    updatedAnswer.score = score;
    updatedAnswer.followUpResponse = requiresNewFollowUpResponse
      ? ""
      : updatedAnswer.followUpResponse;
  }

  return updatedAnswer;
};

interface FormQuestionProps {
  formInstanceId: string;
}

export const FormQuestions = ({formInstanceId}: FormQuestionProps): ReactElement | null => {
  const updateFormInstState = useUpdateFormInstanceState(formInstanceId);
  const rtkFormInstance = useSelectFormInstance(formInstanceId);
  const {data: formInstance, readOnly = false, isSupervisor = false} = rtkFormInstance ?? {};
  const [patchFormInstanceAnswer] = usePatchFormInstanceAnswerMutation();
  const updateAnswerState = useUpdateFormInstanceAnswerState(formInstanceId);
  const form = formInstance?.form as Form | undefined;
  const answers = formInstance?.answers;
  const uiError = useUIError();
  const formQuestionsWithoutHeadings = form?.questions?.filter((q) => q.type !== "Heading");

  const duplicateAnswerCheck = useMemo(() => {
    if (!formInstance || !answers) {
      return false;
    }
    const answerMap = new Map<string, number>();
    for (const answer of answers) {
      const count = answerMap.get(answer.questionId) || 0;
      answerMap.set(answer.questionId, count + 1);
    }

    let hasDuplicates = false;
    answerMap.forEach((count, questionId) => {
      if (count > 1) {
        hasDuplicates = true;
        uiError(
          `Multiple answers found for question ${questionId} and form instance ${formInstance?._id}. Please contact support.`
        );
      }
    });

    return hasDuplicates;
  }, [answers, formInstance, uiError]);

  if (duplicateAnswerCheck) {
    console.warn("Duplicate answers found");
  }

  const patchAnswer = useCallback(
    async (answer: FormInstance["answers"][number]) => {
      if (!formInstance || readOnly || !answer) {
        return;
      }
      await patchFormInstanceAnswer({
        formInstanceId,
        answerId: answer._id!,
        answer,
      })
        .unwrap()
        .catch((error) => {
          uiError(`Error saving update to form. Please try again before continuing.`, error);
        });
    },
    [formInstance, readOnly, patchFormInstanceAnswer, uiError, formInstanceId]
  );

  if (!formInstance || !form || !answers) {
    return null;
  }

  return (
    <Box paddingY={2}>
      {form.questions?.map((q) => {
        const isDisabled = isQuestionDisabled({
          readOnly,
          isSupervisor,
          question: q,
          formInstanceStatus: formInstance?.status,
        });
        const existingAnswer = answers.find((a) => a?.questionId === q._id);
        const questionAnswers: string[] = existingAnswer?.answers ?? [];

        return (
          <FormQuestionComponent
            key={q._id}
            answers={questionAnswers}
            disabled={isDisabled}
            followUpResponse={existingAnswer?.followUpResponse}
            index={formQuestionsWithoutHeadings?.findIndex((f) => f._id === q._id) ?? 0}
            question={q}
            userId={formInstance.userId}
            onBlur={async (value: string | string[], isFollowUpResponse = false): Promise<void> => {
              const updatedAnswer = compileUpdatedAnswer(
                existingAnswer,
                value,
                q,
                isFollowUpResponse
              );
              void updateFormInstState({currentFocusedAnswer: undefined});

              void patchAnswer(updatedAnswer);
            }}
            onChange={async (
              value: string | string[],
              isFollowUpResponse = false
            ): Promise<void> => {
              const updatedAnswer = compileUpdatedAnswer(
                existingAnswer,
                value,
                q,
                isFollowUpResponse
              );

              void updateAnswerState(updatedAnswer);
            }}
            onFocus={(): void => {
              if (isDisabled) {
                return;
              }
              void updateFormInstState({
                currentFocusedAnswer: {answerId: existingAnswer!._id!, isEdited: false},
              });
            }}
          />
        );
      })}
      <Box marginBottom={4}>
        <Text>Last saved: {humanDateAndTime(formInstance.updated)}</Text>
      </Box>
    </Box>
  );
};

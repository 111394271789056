import {Day, DayTimeRange, SectionDivider, UserList} from "@components";
import {timezoneOptions} from "@constants";
import {useReadProfile} from "@hooks";
import {useNavigation} from "@react-navigation/native";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  GetUsersArgs,
  ScheduleItem,
  useDeleteScheduleItemsByIdMutation,
  useGetScheduleItemsQuery,
  useGetUsersQuery,
  usePatchScheduleItemsByIdMutation,
  usePatchUsersByIdMutation,
  usePostScheduleItemsMutation,
  User,
} from "@store";
import {StaffStackParamList, StaffStackScreenProps} from "@types";
import {Box, Button, Heading, IconButton, NumberField, Page, Text, useToast} from "ferns-ui";
import {DateTime} from "luxon";
import React, {useCallback, useEffect, useState} from "react";

import {VacationForm} from "./VacationForm";

function printDayTimeRange(scheduleItem: ScheduleItem): string {
  const start = DateTime.fromISO(scheduleItem.startDatetime, {zone: scheduleItem.timezone});
  const end = DateTime.fromISO(scheduleItem.endDatetime, {zone: scheduleItem.timezone});

  // Check if both start and end are set to 00:00:00, indicating an all-day event.
  if (scheduleItem.allDay) {
    if (start.year === end.year && start.month === end.month && start.day === end.day) {
      return start.toFormat("MM/dd/yyyy");
    } else {
      return `${start.toFormat("MM/dd/yyyy")} - ${end.toFormat("MM/dd/yyyy")}`;
    }
  } else {
    if (scheduleItem.startDatetime === scheduleItem.endDatetime) {
      return start.toFormat("MM/dd/yyyy");
    } else {
      return `${start.toFormat("MM/dd/yyyy h:mm a")} - ${end.toFormat("h:mm a")}`;
    }
  }
}

interface TeamMemberProps {
  user: User;
}

const TeamMember = ({user}: TeamMemberProps): React.ReactElement => {
  const [updateUser] = usePatchUsersByIdMutation();
  const [createScheduleItem] = usePostScheduleItemsMutation();
  const [updateScheduleItem] = usePatchScheduleItemsByIdMutation();
  const [deleteScheduleItem] = useDeleteScheduleItemsByIdMutation();
  const toast = useToast();
  const [schedule, setSchedule] = useState(user.availabilitySchedule);
  const [editVacation, setEditVacation] = useState<Partial<ScheduleItem> | undefined>(undefined);
  const [intakes, setIntakes] = useState({
    PsychiatryIntake: user.scheduleCapacity?.PsychiatryIntake,
    TherapyIntake: user.scheduleCapacity?.TherapyIntake,
    CareAdvocateIntake: user.scheduleCapacity?.CareAdvocateIntake,
    PatientGuideIntake: user.scheduleCapacity?.PatientGuideIntake,
    FamilyGuideIntake: user.scheduleCapacity?.FamilyGuideIntake,
  });
  const {data: vacationData} = useGetScheduleItemsQuery({
    "staff.userId": user._id,
    itemType: "Vacation",
  });

  const upsertScheduleItem = useCallback(
    async ({_id, ...rest}: Partial<ScheduleItem>): Promise<void> => {
      if (!user) {
        return;
      }

      const body = {
        ...rest,
        staff: [{userId: user._id}],
        itemType: "Vacation" as ScheduleItem["itemType"],
      };

      try {
        if (_id) {
          await updateScheduleItem({id: _id, body}).unwrap();
        } else {
          await createScheduleItem(body).unwrap();
        }
      } catch (error) {
        toast.catch(error);
        return;
      }
      setEditVacation(undefined);
    },
    [createScheduleItem, toast, updateScheduleItem, user]
  );

  const saveUserSchedule = useCallback((): void => {
    {
      const toUpdateSchedule = [...schedule];
      // Pick the first unscheduled day as a default.
      const day = ([
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ].find((d) => !schedule.find((s) => s.day === d)) ?? "Monday") as Day;
      const newSchedule = {
        day,
        startHour: 9,
        startMin: 0,
        endHour: 17,
        endMin: 0,
      };
      toUpdateSchedule.push(newSchedule);
      setSchedule(toUpdateSchedule);
      updateUser({id: user._id, body: {availabilitySchedule: toUpdateSchedule}}).catch(toast.catch);
    }
  }, [schedule, toast.catch, updateUser, user._id]);

  const helperText = (role: string): string =>
    `Target intake slots per week for ${role}. This is not a hard limit, but will warn if we try to schedule more than this.`;

  const timezoneText = `times in ${timezoneOptions.find((tz) => tz.value === user.timezone)?.label}`;

  const updateIntakeSlot = async (role: string, value: string): Promise<void> => {
    setIntakes({...intakes, [role]: Number(value)});
    await updateUser({id: user._id, body: {[`intakes.${role}`]: value}})
      .unwrap()
      .catch(toast.catch);
    if (value) {
      toast.show(`${user.name}'s ${role} intakes updated.`);
    }
  };

  return (
    <Box alignItems="center" color="base" maxWidth={400} paddingX={4} paddingY={2}>
      <Box gap={4}>
        <Heading size="lg">{user.name}</Heading>
        <Heading>Intake Availability ({timezoneText})</Heading>
        {schedule.map((s, i) => (
          <DayTimeRange
            key={s.day + String(s.startMin) + String(s.startHour)}
            dayTimeRange={s}
            showDelete
            onChange={(newSchedule): void => {
              const toUpdateSchedule = [...schedule];
              toUpdateSchedule[i] = newSchedule;
              setSchedule(toUpdateSchedule);
              updateUser({
                id: user._id,
                body: {availabilitySchedule: toUpdateSchedule},
              }).catch(toast.catch);
              toast.show(`${user.name}'s availability schedule updated.`);
            }}
            onDelete={(): void => {
              const toUpdateSchedule = [...schedule];
              toUpdateSchedule.splice(i, 1);
              setSchedule(toUpdateSchedule);
              updateUser({
                id: user._id,
                body: {availabilitySchedule: toUpdateSchedule},
              }).catch(toast.catch);
              toast.show(`${user.name}'s availability schedule updated.`);
            }}
          />
        ))}
        <Box width={200}>
          <Button
            iconName="plus"
            text="Add Schedule"
            variant="secondary"
            onClick={saveUserSchedule}
          />
        </Box>
        <Box marginTop={2}>
          <Heading>Vacation</Heading>
        </Box>
        {vacationData?.data?.map((p) => (
          <Box
            key={p._id}
            alignItems="center"
            border="default"
            direction="row"
            marginBottom={2}
            paddingX={4}
            paddingY={2}
            rounding="lg"
            width="100%"
          >
            <Box flex="grow" height="100%" justifyContent="center">
              <Box marginBottom={1}>
                <Text>{p.title}</Text>
              </Box>
              <Box marginBottom={1}>
                <Text size="sm">{printDayTimeRange(p)}</Text>
              </Box>
              {Boolean(p.staffNotes !== "") && (
                <Box>
                  <Text size="sm">{p.staffNotes}</Text>
                </Box>
              )}
            </Box>
            <Box alignItems="center" height="100%" justifyContent="center" marginLeft={2}>
              <IconButton
                accessibilityLabel="edit vacation"
                iconName="pencil"
                onClick={() => {
                  setEditVacation(p);
                }}
              />
            </Box>
            <Box alignItems="center" height="100%" justifyContent="center" marginLeft={2}>
              <IconButton
                accessibilityLabel="delete vacation"
                iconName="trash"
                variant="destructive"
                withConfirmation
                onClick={() => {
                  deleteScheduleItem(p._id).catch(toast.catch);
                }}
              />
            </Box>
          </Box>
        ))}
        <VacationForm
          key={editVacation?._id}
          scheduleItem={editVacation}
          staff={user}
          onDismiss={() => setEditVacation(undefined)}
          onSave={upsertScheduleItem}
        />
        <Box paddingY={2} width={200}>
          <Button
            iconName="plane"
            text="Add Vacation"
            onClick={(): void => {
              setEditVacation({staff: [{userId: user._id}]});
            }}
          />
        </Box>
        <Box gap={4} paddingY={4} width={300}>
          <Heading>Intakes</Heading>
          {Boolean(user.staffRoles.Psychiatrist) && (
            <NumberField
              helperText={helperText("psychiatry")}
              title="Psychiatry Intakes"
              type="number"
              value={String(intakes.PsychiatryIntake ?? 0)}
              onChange={(value: string) => updateIntakeSlot("PsychiatryIntake", value)}
            />
          )}
          {Boolean(user.staffRoles.Therapist) && (
            <NumberField
              helperText={helperText("therapy")}
              title="Therapy Intakes"
              type="number"
              value={String(intakes.TherapyIntake ?? 0)}
              onChange={(value: string) => updateIntakeSlot("TherapyIntake", value)}
            />
          )}
          {Boolean(user.staffRoles.CareAdvocate) && (
            <NumberField
              helperText={helperText("care advocate")}
              title="Care Advocate Intakes"
              type="number"
              value={String(intakes.CareAdvocateIntake ?? 0)}
              onChange={(value: string) => updateIntakeSlot("CareAdvocateIntake", value)}
            />
          )}
          {Boolean(user.staffRoles.PatientGuide) && (
            <NumberField
              helperText={helperText("patient guide")}
              title="Patient Guide Intakes"
              type="number"
              value={String(intakes.PatientGuideIntake ?? 0)}
              onChange={(value: string) => updateIntakeSlot("PatientGuideIntake", value)}
            />
          )}
          {Boolean(user.staffRoles.FamilyGuide) && (
            <NumberField
              helperText={helperText("family guide")}
              title="Family Guide Intakes"
              type="number"
              value={String(intakes.FamilyGuideIntake ?? 0)}
              onChange={(value: string) => updateIntakeSlot("FamilyGuideIntake", value)}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

interface MyTeamProps extends StaffStackScreenProps<"Team"> {}

export const MyTeamScreen = ({route}: MyTeamProps): React.ReactElement | null => {
  const profile = useReadProfile();
  const navigation = useNavigation<NativeStackNavigationProp<StaffStackParamList, "Team">>();

  const {allStaff, userIds} = route.params ?? {};
  // Set navigation title.
  useEffect(() => {
    if (allStaff) {
      navigation.setOptions({title: "All Staff"});
    } else {
      navigation.setOptions({title: "My Team"});
    }
  }, [allStaff, navigation]);

  let query: GetUsersArgs;
  if (allStaff) {
    query = {};
  } else if (userIds) {
    query = {_id: {$in: userIds}};
  } else {
    query = {supervisor: profile?._id};
  }

  const {data: supervisedUserData} = useGetUsersQuery(profile?._id ? query : skipToken);

  const [users, setUsers] = useState<User[]>([]);

  // Default to supervised users, but can add more users.
  useEffect(() => {
    if (users.length === 0 && supervisedUserData?.data?.length) {
      setUsers(supervisedUserData?.data!);
    }
  }, [supervisedUserData?.data, users.length]);

  return (
    <Page navigation={navigation}>
      <Box direction="column" mdDirection="row" width="100%">
        <Box marginBottom={4} marginRight={4}>
          <UserList
            buttonText="Select Users"
            staff
            title="Staff"
            userPickerTitle="Staff"
            users={users}
            onChangeUsers={setUsers}
          />
        </Box>

        <Box direction="column" flex="grow" gap={4}>
          {users.map((u) => (
            <>
              <TeamMember key={u._id} user={u} />
              <SectionDivider />
            </>
          ))}
          {users.length === 0 && (
            <Box width="100%">
              <Box paddingY={4}>
                <Text align="center" bold>
                  You are not supervising any staff.
                </Text>
              </Box>
              <Box>
                <Text align="center">
                  To add supervised staff, add them to your panel, click User Info, and edit
                  &ldquo;Supervisor&rdquo;.
                </Text>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Page>
  );
};

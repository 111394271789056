import {FieldConfig, ModelAdminScreen, UserList} from "@components";
import {
  useDeleteCarePodsByIdMutation,
  useGetCarePodsQuery,
  usePatchCarePodsByIdMutation,
  usePostCarePodsMutation,
} from "@store";
import {StaffStackScreenProps} from "@types";
import {Box} from "ferns-ui";
import cloneDeep from "lodash/cloneDeep";
import React from "react";

interface CareTeamPickerProps {
  instanceData: any;
  setInstanceData: (data: any) => void;
  staffRole: "Psychiatrist" | "Therapist" | "PatientGuide" | "FamilyGuide";
}

const CareTeamPicker = ({
  instanceData,
  setInstanceData,
  staffRole,
}: CareTeamPickerProps): React.ReactElement => {
  return (
    <Box paddingY={2}>
      <UserList
        buttonText={`Select ${staffRole}`}
        limit={1}
        staff
        title={staffRole}
        userFilter={(user) => Boolean(user.staffRoles[staffRole])}
        users={instanceData?.careTeam?.[staffRole] ? [instanceData?.careTeam?.[staffRole]] : []}
        onChangeUsers={(users) => {
          const newInstanceData = cloneDeep(instanceData);
          if (!newInstanceData.careTeam) {
            newInstanceData.careTeam = {};
          }
          newInstanceData.careTeam[staffRole] = users[0];
          setInstanceData(newInstanceData);
        }}
      />
    </Box>
  );
};

interface Props extends StaffStackScreenProps<"CarePodAdmin"> {}

export const CarePodAdminScreen = ({navigation}: Props): React.ReactElement => {
  return (
    <ModelAdminScreen
      confirmationText={
        "Are you sure you want to delete? Make sure you've removed this care pod from all users before deleting, or the app will behave weirdly."
      }
      description="Care Pods are groupings of staff that work together with a group of families"
      display={(carePod): {title: string; subtitle?: string} => ({title: carePod?.name})}
      extraFields={[
        (instanceData, setInstanceData): React.ReactElement => (
          <CareTeamPicker
            instanceData={instanceData}
            setInstanceData={setInstanceData}
            staffRole="Psychiatrist"
          />
        ),
        (instanceData, setInstanceData): React.ReactElement => (
          <CareTeamPicker
            instanceData={instanceData}
            setInstanceData={setInstanceData}
            staffRole="Therapist"
          />
        ),
        (instanceData, setInstanceData): React.ReactElement => (
          <CareTeamPicker
            instanceData={instanceData}
            setInstanceData={setInstanceData}
            staffRole="PatientGuide"
          />
        ),
        (instanceData, setInstanceData): React.ReactElement => (
          <CareTeamPicker
            instanceData={instanceData}
            setInstanceData={setInstanceData}
            staffRole="FamilyGuide"
          />
        ),
      ]}
      fields={
        [
          {fieldKey: "name", type: "text", title: "Name"},
          {fieldKey: "googleCalendarId", type: "text", title: "The calendar to sync events to."},
          {fieldKey: "url", type: "text", title: "The calendar's public URL", disabled: true},
          {
            fieldKey: "capacity",
            type: "number",
            title: "The maximum number of members in this pod.",
          },
          {fieldKey: "slackAlertChannel", type: "text", title: "Slack Alert Channel"},
          {fieldKey: "slackUrgentAlertChannel", type: "text", title: "Slack Urgent Alert Channel"},
        ] as FieldConfig[]
      }
      navigation={navigation}
      preSave={(instance) => {
        delete instance.enrolledUsers;
        delete instance.growthUsers;
        return instance;
      }}
      useCreate={usePostCarePodsMutation}
      useList={useGetCarePodsQuery}
      useRemove={useDeleteCarePodsByIdMutation}
      useUpdate={usePatchCarePodsByIdMutation}
    />
  );
};

import Constants from "expo-constants";

import {AUTH_DEBUG} from "../ferns-rtk";

export * from "./AppointmentConfig";
export * from "./ClinicalInfoTextFields";
export * from "./Colors";

// How many times to retry biometrics before showing the password screen.
export const BIOMETRICS_MAX_RETRIES = 3;
// Number of seconds before re-prompting for biometrics on mobile.
export const BIOMETRICS_TIMEOUT_SECONDS = 60 * 60;

// Logout after 60 minutes of inactivity.
// TODO: We should fetch this from the backend so it stays in sync between frontend and backend.
export const INACTIVITY_TIMEOUT_SECONDS = 60 * 60;
export const ONLINE_PRESENCE_TIMEOUT_MILLISECONDS = 1000 * 60 * 5;
// How often we update online presence via updating UserSession. When debugging auth,
// do it quicker to make testing easier.
export const ONLINE_PRESENCE_UPDATE_INTERVAL_MILLISECONDS = AUTH_DEBUG ? 1000 * 5 : 1000 * 60;

export const TIMEZONES = [
  "America/New_York",
  "America/Chicago",
  "America/Denver",
  "America/Los_Angeles",
  "America/Anchorage",
  "Pacific/Honolulu",
  "America/Phoenix",
];

export const timezoneOptions = [
  {label: "Eastern", value: "America/New_York"},
  {label: "Central", value: "America/Chicago"},
  {label: "Mountain", value: "America/Denver"},
  {label: "Pacific", value: "America/Los_Angeles"},
  {label: "Alaska", value: "America/Anchorage"},
  {label: "Hawaii", value: "Pacific/Honolulu"},
  {label: "Arizona", value: "America/Phoenix"},
];

export const GENDEROPTIONS = ["Female", "Male", "Non-binary", "Prefer not to say"];

export const BILLINGGENDEROPTIONS = ["female", "male", "other", "unknown"];

export const VAPID_PUBLIC_KEY = Constants.expoConfig?.extra?.EXPO_PUBLIC_VAPID_PUBLIC_KEY;

export const GOOGLE_MAPS_API_KEY = Constants.expoConfig?.extra?.GOOGLE_MAPS_API_KEY;

export const HEAP_ID = Constants.expoConfig?.extra?.HEAP_ID;

export const DEFAULT_USER_PASSWORD = "Strawberry123";

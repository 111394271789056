import {Box, Button} from "ferns-ui";
import React from "react";

import {useLogoutUser} from "../hooks";

export const LogoutButton: React.FC<{}> = () => {
  const logoutUser = useLogoutUser();

  return (
    <Box alignSelf="center" maxWidth={300}>
      <Button
        confirmationText="Are you sure you want to log out?"
        iconName="right-from-bracket"
        text="Log Out"
        tooltipText="Log out"
        variant="destructive"
        withConfirmation
        onClick={logoutUser}
      />
    </Box>
  );
};

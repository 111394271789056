import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  passwordHasErrors,
  useGetUsersByIdQuery,
  useResetPasswordMutation,
  useUIError,
} from "@store";
import {PatientStackScreenProps} from "@types";
import {pageOnError} from "@utils";
import {Box, Button, Heading, Page, TextField, useToast} from "ferns-ui";
import React, {ReactElement, useState} from "react";

interface PatientResetPasswordProps extends PatientStackScreenProps<"ResetPassword"> {}

export const PatientResetPasswordScreen = ({
  navigation,
  route,
}: PatientResetPasswordProps): ReactElement => {
  // TODO: Fix use password reset
  const [resetPassword, {isLoading}] = useResetPasswordMutation();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const uiError = useUIError();
  const toast = useToast();

  const {data: user} = useGetUsersByIdQuery(route.params.user?._id ?? skipToken);

  const submit = async (): Promise<void> => {
    if (!user) {
      return;
    }

    if (newPassword !== newPasswordConfirmation) {
      toast.error("New passwords must match");
      return;
    }
    const validationError = passwordHasErrors(user?.type, newPassword);

    if (validationError) {
      toast.error(validationError);
      return;
    }

    let err;
    await resetPassword({
      oldPassword,
      newPassword,
      _id: user?._id,
    })
      .unwrap()
      .catch((error: any) => {
        err = error?.data?.title;
        uiError(
          `${error}. Please email guides@flourish.health or reach out to a guide if you are unable to successfully reset your password.`
        );
        return;
      });
    if (!err) {
      navigation.pop();
    }
  };

  return (
    <Page navigation={navigation} onError={pageOnError}>
      <Box paddingY={3}>
        <Heading size="md">Reset password</Heading>
      </Box>
      {Boolean(user) && (
        <>
          <Box maxWidth={400} paddingY={2}>
            <TextField
              title="Current Password"
              type="password"
              value={oldPassword}
              onChange={(value): void => {
                setOldPassword(value);
              }}
            />
          </Box>
          <Box maxWidth={400} paddingY={2}>
            <TextField
              title="New Password"
              type="password"
              value={newPassword}
              onChange={(value): void => {
                setNewPassword(value);
              }}
            />
          </Box>
          <Box maxWidth={400} paddingY={2}>
            <TextField
              title="Confirm New Password"
              type="password"
              value={newPasswordConfirmation}
              onChange={(value): void => {
                setNewPasswordConfirmation(value);
              }}
            />
          </Box>
          <Box maxWidth={175} paddingY={2}>
            <Button
              disabled={isLoading}
              loading={isLoading}
              text="Reset Password"
              onClick={submit}
            />
          </Box>
        </>
      )}
    </Page>
  );
};

import {FieldConfig, ModelAdminScreen} from "@components";
import {
  useDeleteReferralMethodsByIdMutation,
  useGetReferralMethodsQuery,
  usePatchReferralMethodsByIdMutation,
  usePostReferralMethodsMutation,
} from "@store";
import {StaffStackScreenProps} from "@types";
import React from "react";

interface Props extends StaffStackScreenProps<"ReferralMethodAdmin"> {}

export const ReferralMethodAdminScreen = ({navigation}: Props): React.ReactElement => {
  return (
    <ModelAdminScreen
      confirmationText={
        "Are you sure you want to delete? Make sure you've removed this referral method from all users before deleting, or the app will behave weirdly."
      }
      description="Referral Methods are the generalize source of a referral."
      display={(m): {title: string; subtitle?: string} => ({title: m?.name})}
      fields={[{fieldKey: "name", type: "text", title: "Name"}] as FieldConfig[]}
      navigation={navigation}
      useCreate={usePostReferralMethodsMutation}
      useList={useGetReferralMethodsQuery}
      useRemove={useDeleteReferralMethodsByIdMutation}
      useUpdate={usePatchReferralMethodsByIdMutation}
    />
  );
};

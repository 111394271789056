import {AssessmentHistoryLineChart} from "@components";
import {Box} from "ferns-ui";
import React, {ReactElement} from "react";

interface Props {
  userId: string;
}

export const ISPAssessmentHistory = ({userId}: Props): ReactElement => {
  return (
    <Box>
      <AssessmentHistoryLineChart assessmentType="PHQ-9" userId={userId} />
      <AssessmentHistoryLineChart assessmentType="GAD-7" userId={userId} />
      <AssessmentHistoryLineChart assessmentType="Flourishing" userId={userId} />
    </Box>
  );
};

import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  FetchPopulatedRes,
  GetConversationsArgs,
  PopulatedConversation,
  useGetConversationsQuery,
  User,
} from "@store";
import {isStaff} from "@utils";
import flatten from "lodash/flatten";
import uniq from "lodash/uniq";
import {useMemo} from "react";

import {devError} from "../store/errors";
import {useGetPopulateUserLookup} from "./useGetPopulateUserLookup";
import {useReadProfile} from "./useReadProfile";

export function useFetchPopulatedConversations(
  query: GetConversationsArgs | typeof skipToken
): FetchPopulatedRes<PopulatedConversation> {
  const profile = useReadProfile();

  const isSkipToken = query === skipToken;
  if (!isSkipToken && isStaff(profile?.type) && Object.keys(query).length === 0) {
    devError(
      "useFetchPopulatedConversations: Staff users should not be fetching all conversations"
    );
  }

  const conversationsQuery = useGetConversationsQuery(query);

  const populateIdList = uniq(
    flatten(
      conversationsQuery?.data?.data?.map(
        (c) =>
          [
            ...c?.archivedUsers?.map((u) => u.userId),
            ...c?.referencedUsers?.map((u) => u.userId),
            ...c?.users?.map((u) => u.userId),
          ].filter((id) => id) as string[]
      )
    )
  );

  const userLookupTableQuery = useGetPopulateUserLookup(populateIdList);
  const userLookupTable = userLookupTableQuery.userLookup;

  const userData = useMemo(() => {
    const getUserFromId = (conversationId: string, userId: string): User | undefined => {
      if (!userLookupTable[userId]) {
        console.warn(
          `No user ${userId} found for conversation ${conversationId}`,
          Object.keys(userLookupTable).length,
          userLookupTable[userId],
          userLookupTable
        );
        return undefined;
      }
      return userLookupTable[userId];
    };
    if (!Object.keys(userLookupTable).length) {
      return [];
    }

    const populatedList: PopulatedConversation[] = [];
    for (const item of conversationsQuery.data?.data ?? []) {
      const populated = {...item} as any as PopulatedConversation;

      populated.users = item.users.map((u) => ({
        userId: getUserFromId(item._id, u?.userId),
      }));
      populated.referencedUsers = item.referencedUsers.map((u) => ({
        userId: getUserFromId(item._id, u?.userId),
      }));
      populated.archivedUsers = item.archivedUsers.map((u) => ({
        userId: getUserFromId(item._id, u?.userId),
      }));
      populatedList.push(populated);
    }
    return populatedList;
  }, [userLookupTable, conversationsQuery.data?.data]);

  return {
    data: {...conversationsQuery.data, data: userData},
    isSuccess: conversationsQuery.isSuccess && userLookupTableQuery.isSuccess,
    error: conversationsQuery.error || userLookupTableQuery.error,
    isError: conversationsQuery.isError || userLookupTableQuery.isError,
    isLoading: conversationsQuery.isLoading || userLookupTableQuery.isLoading,
    isFetching: conversationsQuery.isFetching || userLookupTableQuery.isFetching,
  };
}

// This file is the basis for openApiSdk.ts. See openapi-config.ts for configuration that is
// combined with this to generate the SDK.
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  BaseQueryApi,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  retry,
} from "@reduxjs/toolkit/query/react";
import {IsWeb} from "@utils";
import {Mutex} from "async-mutex";
import axios from "axios";
import axiosRetry from "axios-retry";
import Constants from "expo-constants";
import * as SecureStore from "expo-secure-store";
import {jwtDecode} from "jwt-decode";
import qs from "qs";

import {AUTH_DEBUG, baseUrl, LOGOUT_ACTION_TYPE} from "./constants";
import {generateProfileEndpoints, getAuthToken} from "./profileSlice";

const log = AUTH_DEBUG ? (s: string): void => console.debug(`[auth] ${s}`) : (): void => {};

axiosRetry(axios, {retries: 3, retryDelay: axiosRetry.exponentialDelay});

const mutex = new Mutex();

interface TokenPayload {
  exp: number;
}

export async function getTokenExpirationTimes(): Promise<{
  refreshRemainingSecs?: number;
  authRemainingSecs?: number;
}> {
  let refreshToken: string | null, authToken: string | null;
  if (!IsWeb) {
    refreshToken = await SecureStore.getItemAsync("REFRESH_TOKEN");
    authToken = await SecureStore.getItemAsync("AUTH_TOKEN");
  } else {
    refreshToken = await AsyncStorage.getItem("REFRESH_TOKEN");
    authToken = await AsyncStorage.getItem("AUTH_TOKEN");
  }

  if (!refreshToken || !authToken) {
    return {refreshRemainingSecs: undefined, authRemainingSecs: undefined};
  }

  const currentTime = Math.floor(Date.now() / 1000);

  const refreshDecoded = jwtDecode<TokenPayload>(refreshToken);
  const authDecoded = jwtDecode<TokenPayload>(authToken);

  const refreshExpirationTime = refreshDecoded.exp;
  const authExpirationTime = authDecoded.exp;

  const refreshTimeRemaining = refreshExpirationTime - currentTime;
  const authTimeRemaining = authExpirationTime - currentTime;

  if (AUTH_DEBUG) {
    log(`Refresh expires in ${refreshTimeRemaining}s, Auth expires in ${authTimeRemaining}s`);
  }

  return {refreshRemainingSecs: refreshTimeRemaining, authRemainingSecs: authTimeRemaining};
}

// Give an extra 5 seconds to make sure if they hit the modal with 1 minute left,
// they have time to click the button
export const shouldShowStillThereModal = async (): Promise<boolean> => {
  const {refreshRemainingSecs} = await getTokenExpirationTimes();
  if (refreshRemainingSecs === undefined) {
    return false;
  }
  return refreshRemainingSecs <= 65;
};

export const refreshAuthToken = async (): Promise<void> => {
  let refreshToken;
  if (!IsWeb) {
    refreshToken = await SecureStore.getItemAsync("REFRESH_TOKEN");
  } else {
    refreshToken = await AsyncStorage.getItem("REFRESH_TOKEN");
  }
  console.debug("Refreshing token, current token:", refreshToken);
  if (refreshToken) {
    const refreshResult = await axios.post(`${baseUrl}/auth/refresh_token`, {
      refreshToken,
    });
    console.debug("Refresh token result", refreshResult?.data?.data);
    if (refreshResult?.data?.data) {
      const data = refreshResult.data.data;
      if (!data.token || !data.refreshToken) {
        console.warn("refresh token API request didn't return data");
        throw new Error("refresh token API request didn't return data");
      }
      if (!IsWeb) {
        await SecureStore.setItemAsync("AUTH_TOKEN", data.token);
        await SecureStore.setItemAsync("REFRESH_TOKEN", data.refreshToken);
      } else {
        await AsyncStorage.setItem("AUTH_TOKEN", data.token);
        await AsyncStorage.setItem("REFRESH_TOKEN", data.refreshToken);
      }
      axios.defaults.headers.common.Authorization = `Bearer ${data.token}`;
      console.debug("New token stored");
    } else {
      console.warn("refresh token API request failed or didn't return data");
      throw new Error("refresh token API request failed or didn't return data");
    }
  } else {
    console.warn("no refresh token found");
    throw new Error("no refresh token found");
  }
};

const getBaseQuery = (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {},
  token: string | null
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
) => {
  const version = Constants.expoConfig?.version ?? "Unknown";

  return fetchBaseQuery({
    baseUrl: `${baseUrl}`,
    prepareHeaders: async (headers) => {
      headers.set("authorization", `Bearer ${token}`);
      // Send version in case the API needs to respond differently based on version.
      headers.set("App-Version", version);
      return headers;
    },
    // We need to use qs.stringify here because fetchBaseQuery uses the qs library which doesn't
    // support nested objects, such as our $in, $lt/$gte, etc queries.
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
    // We need to slightly change the format of the data coming from the API to match the format
    // that the SDK generates. In the old flourishApi, we used a transformResponse,
    // but we can't inject that to the new SDK.
    responseHandler: async (response) => {
      if (response.status === 204) {
        return null;
      }
      const result = await response.json();
      if ("more" in result) {
        // For list responses, return the whole result
        return result;
      } else if (result.data) {
        // For read, update, and create responses, return the data. We used to use a transformer,
        // but
        return result.data;
      } else {
        return result;
      }
    },
  })(args, api, extraOptions);
};

const staggeredBaseQuery = retry(
  async (args: string | FetchArgs, api, extraOptions) => {
    // wait until the mutex is available without locking it
    await mutex.waitForUnlock();
    let token = await getAuthToken();

    if (!token && api.endpoint === "emailLogin") {
      // just pass thru the request without a token if it is a login request
      return getBaseQuery(args, api, extraOptions, token);
    } else if (!token) {
      console.debug(`No token found and the endpoint is ${api.endpoint}`);
      // assume the token was removed because the user logged out and dispatch logout
      api.dispatch({type: LOGOUT_ACTION_TYPE});
      return {error: {status: "FETCH_ERROR", error: `No token found for ${api.endpoint}`}};
    }

    const {refreshRemainingSecs, authRemainingSecs} = await getTokenExpirationTimes();
    // if both auth and refresh tokens exist but are expired, log the user out
    if (
      authRemainingSecs &&
      authRemainingSecs < 0 &&
      refreshRemainingSecs &&
      refreshRemainingSecs < 0
    ) {
      console.warn(
        `[auth] Both tokens are expired, logging out: authRemainingSecs: ${authRemainingSecs}, refreshRemainingSecs: ${refreshRemainingSecs}`
      );
      api.dispatch({type: LOGOUT_ACTION_TYPE});
      return {error: {status: "FETCH_ERROR", error: "Auth and refresh tokens are expired"}};
    }

    // if the auth token is within about 2 minute of expiring, refresh it automatically
    if (authRemainingSecs && authRemainingSecs < 130) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        try {
          log(`Refreshing token: authRemainingSecs: ${authRemainingSecs}`);
          await refreshAuthToken();
          token = await getAuthToken();
          log(`Token refreshed: ${token}`);
        } catch (error: any) {
          // if it is of type AxiosError
          if (axios.isAxiosError(error)) {
            // if it is a Network Error, don't auto log out and just let the next request go
            // through
            console.warn(`[auth] Network error refreshing token: ${error.code} ${error.message}`);
            if (error.code === "ERR_NETWORK") {
              return getBaseQuery(args, api, extraOptions, token);
            } else if (error.status === 401) {
              api.dispatch({type: LOGOUT_ACTION_TYPE});
              return {error: {status: "FETCH_ERROR", error: "Token refresh failed with 401"}};
            }
          }
          console.warn(`[auth] Error refreshing token: ${error.message}`);
          api.dispatch({type: LOGOUT_ACTION_TYPE});
          return {
            error: {status: "FETCH_ERROR", error: `Failed to refresh token: ${error.message}`},
          };
        } finally {
          release();
        }
      }
    } else {
      // wait until the mutex is available
      log(`Waiting for mutex to get token: authRemainingSecs: ${authRemainingSecs}`);
      await mutex.waitForUnlock();
      token = await getAuthToken();
    }

    let baseQuery = getBaseQuery(args, api, extraOptions, token);
    let result = await baseQuery;

    if (result.error?.status === 401) {
      if (!mutex.isLocked()) {
        console.warn("[auth] 401 error, refreshing token and retrying, waiting for mutex");
        const release = await mutex.acquire();
        log("401 error, refreshing token and retrying, got mutex");
        try {
          await refreshAuthToken();
          token = await getAuthToken();
          log(`401 error, refreshing token and retrying, got new token: ${token}`);
          baseQuery = getBaseQuery(args, api, extraOptions, token);
          // retry once with the new token before failing and logging out
          result = await baseQuery;
        } catch (error: any) {
          console.error("Error refreshing auth token", error);
          api.dispatch({type: LOGOUT_ACTION_TYPE});
        } finally {
          release();
        }
      } else {
        // wait until the mutex is available without locking it then try again since got 401 on
        // first try
        console.warn(
          "401 error and mutex locked, refreshing token and retrying, waiting for mutex"
        );
        await mutex.waitForUnlock();
        token = await getAuthToken();
        log(`401 error and mutex locked, refreshing token and retrying, got new token: ${token}`);
        baseQuery = getBaseQuery(args, api, extraOptions, token);
        result = await baseQuery;
      }
      // if any other type of error, don't retry if it is a mutation to prevent potential duplicates
    } else if (result.error && api.type === "mutation") {
      log(`Error on mutation, not retrying, ${authRemainingSecs}`);
      retry.fail(result.error);
    }
    return result;
  },
  {
    maxRetries: 3,
  }
);

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySplitApi = createApi({
  reducerPath: "flourishApi",
  // TODO: Not sure why the staggeredBaseQuery type is not being inferred correctly here
  baseQuery: staggeredBaseQuery as any,
  endpoints: (builder) => ({
    ...generateProfileEndpoints(builder, "users"), // using 'users' here since it is highly intertwined with Users
  }),
});

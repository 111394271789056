import FontAwesome6 from "@expo/vector-icons/build/FontAwesome6";
import {User, userIsLocked, userName, useSelectWorkflowMappingId, WorkflowMapping} from "@store";
import {isFamilyMember, isGuideOnly, IsMobileDevice, isPatient, isStaff, isTestUser} from "@utils";
import {Badge, Box, Heading, humanDate, IconName, Text, useTheme} from "ferns-ui";
import React, {ReactElement, useMemo} from "react";
import {View} from "react-native";

import {WorkflowColors} from "../constants";

export interface WorkflowItemData {
  // Used for split page
  id: string;
  _id: string;
  workflowMapping: WorkflowMapping;
  hasUnread: boolean;
  workflowMappingUser: User;
  snoozedUntil?: string;
  sortKey: Date;
  lastMessageSentDate?: string;
  status?: "pinned" | "snoozed" | "completed";
}

interface WorkflowItemProps {
  itemInfo: {item: WorkflowItemData};
}

export const WorkflowMappingItem = ({itemInfo}: WorkflowItemProps): ReactElement | null => {
  const selectedWorkflowMappingId = useSelectWorkflowMappingId();
  const {
    workflowMapping,
    hasUnread,
    lastMessageSentDate,
    workflowMappingUser,
    status,
    snoozedUntil,
  } = itemInfo.item;
  const {theme} = useTheme();

  // Set the color of the item based on its status
  const [itemColor, iconColor, statusText] = useMemo(() => {
    let wmColor = theme.primitives.neutral300;
    let wmIconColor = theme.primitives.neutral600;
    let wmText = "";

    // For pinned and just a new message, we want to show the last message sent date
    if (status === "pinned") {
      wmColor = WorkflowColors.orange;
      wmIconColor = WorkflowColors.orange;
      wmText = `Pinned`;
      if (hasUnread) {
        wmText += `, New Message${lastMessageSentDate ? ` - ${humanDate(lastMessageSentDate)}` : ""}`;
      }
    } else if (hasUnread) {
      wmColor = theme.primitives.primary400;
      wmIconColor = theme.primitives.primary400;
      wmText = `New Message${lastMessageSentDate ? ` - ${humanDate(lastMessageSentDate)}` : ""}`;
    } else if (status === "snoozed") {
      wmColor = WorkflowColors.springGreen;
      wmIconColor = WorkflowColors.springGreen;
      wmText = `Snoozed until ${snoozedUntil}`;
    } else if (status === "completed") {
      wmColor = theme.primitives.neutral300;
      wmIconColor = theme.primitives.neutral600;
      wmText = "Completed";
    }

    return [wmColor, wmIconColor, wmText];
  }, [
    theme.primitives.neutral300,
    theme.primitives.neutral600,
    theme.primitives.primary400,
    status,
    hasUnread,
    lastMessageSentDate,
    snoozedUntil,
  ]);

  if (!workflowMappingUser) {
    return null;
  }

  let iconName: IconName = "question";
  if (isPatient(workflowMappingUser.type)) {
    // TODO: Update to custom patient Icon from the design system
    iconName = "child";
  } else if (isFamilyMember(workflowMappingUser.type)) {
    iconName = "user-group";
  } else if (isStaff(workflowMappingUser.type)) {
    iconName = "user-doctor";
  }

  function userBadge(
    title: string,
    badgeStatus: "info" | "error" | "warning" | "success" | "neutral"
  ): ReactElement {
    return (
      <Box alignItems="center" direction="row">
        <Badge status={badgeStatus} value={title} />
      </Box>
    );
  }

  const isSelected = workflowMapping._id === selectedWorkflowMappingId;
  return (
    <Box
      alignItems="start"
      alignSelf="stretch"
      border={isSelected && !IsMobileDevice ? "activeAccent" : undefined}
      color="base"
      dangerouslySetInlineStyle={{__style: {borderWidth: isSelected && !IsMobileDevice ? 2 : 0}}}
      direction="column"
      justifyContent="center"
      marginBottom={2}
      paddingX={3}
      paddingY={4}
      rounding="md"
    >
      <Box direction="row">
        <View
          style={{
            width: 3,
            backgroundColor: itemColor,
            marginRight: 8,
            borderRadius: 2,
          }}
        />
        <Box alignItems="center" justifyContent="center" marginRight={2} width={32}>
          <FontAwesome6 color={iconColor} name={iconName} size={24} solid />
        </Box>
        <Box direction="column" justifyContent="center">
          <Heading size="sm">{userName(workflowMappingUser)}</Heading>
          <Box marginTop={1}>
            {Boolean(statusText) && (
              <Text color="secondaryLight" size="sm">
                {statusText}
              </Text>
            )}
          </Box>
          <Box alignItems="center" direction="row" marginLeft={-1} marginTop={1} width="100%">
            {isGuideOnly(workflowMappingUser) && userBadge("Guide-Only", "info")}
            {isTestUser(workflowMappingUser) && userBadge("Test User", "neutral")}
            {userIsLocked(workflowMappingUser) && userBadge("Locked", "warning")}
            {userIsLocked(workflowMappingUser) && userBadge("Disabled", "error")}
            {workflowMappingUser.outOfOffice && userBadge("Out of Office", "error")}
          </Box>
          {/* Unclear what we should show here or how these should be organized, outside of "new message workflow mappings at the top" */}
          {/* This probably gets more useful when we have User Updates */}
          {/* <Box marginTop={1}> */}
          {/*  <Text color="secondaryLight" size="sm"> */}
          {/*    {humanDate(workflowItem.created!)} */}
          {/*  </Text> */}
          {/* </Box> */}
        </Box>
      </Box>
    </Box>
  );
};

import {useCurrentWorkflowMapping} from "@hooks";
import {
  setUserSettings,
  useAppDispatch,
  usePatchUserSettingsByIdMutation,
  useSelectUserSettings,
} from "@store";
import {Box, DateTimeField, Modal, SelectField, useToast} from "ferns-ui";
import {DateTime} from "luxon";
import React, {ReactElement, useState} from "react";
import {Dimensions} from "react-native";

interface SnoozeWorkflowModalProps {
  hideModal: () => void;
}

export const SnoozeWorkflowModal = ({hideModal}: SnoozeWorkflowModalProps): ReactElement | null => {
  const currentWorkflowMapping = useCurrentWorkflowMapping();
  const [updateUserSettings] = usePatchUserSettingsByIdMutation();
  const userSettings = useSelectUserSettings();
  const dispatch = useAppDispatch();
  const [snoozeOption, setSnoozeOption] = useState("60");
  const [snoozeUntil, setSnoozeUntil] = useState(
    DateTime.now()
      .plus({minutes: parseInt(snoozeOption)})
      .toISO()
  );
  const toast = useToast();
  const windowHeight = Dimensions.get("window").height;

  const snoozeWorkflow = async (): Promise<void> => {
    const userSettingsMutation = await updateUserSettings({
      id: userSettings._id,
      body: {
        panelConfig: {
          reorganizedWorkflowMappings: [
            ...(userSettings?.panelConfig?.reorganizedWorkflowMappings?.filter(
              (rwm) => rwm.workflowMappingId !== currentWorkflowMapping?._id
            ) || []),
            {
              workflowMappingId: currentWorkflowMapping?._id,
              snoozedUntil: snoozeUntil,
              pinned: false,
              completed: false,
            },
          ],
        },
      },
    }).unwrap();

    dispatch(setUserSettings(userSettingsMutation));
    hideModal();
  };

  return (
    <Modal
      primaryButtonOnClick={snoozeWorkflow}
      primaryButtonText="Save"
      title="Settings"
      visible
      onDismiss={(): void => {
        hideModal();
      }}
    >
      <Box
        alignSelf="center"
        maxHeight={windowHeight * (2 / 3)}
        maxWidth="100%"
        scroll
        width="100%"
      >
        <Box>
          <Box marginBottom={2}>
            <SelectField
              options={[
                {label: "1 hour", value: "60"},
                {label: "2 hours", value: "120"},
                {label: "3 hours", value: "180"},
                {label: "4 hours", value: "240"},
                {label: "5 hours", value: "300"},
                {label: "6 hours", value: "360"},
              ]}
              requireValue
              title="Snooze this workflow for:"
              value={snoozeOption}
              onChange={(value): void => {
                setSnoozeOption(value);
                const minutes = parseInt(value);
                setSnoozeUntil(DateTime.now().plus({minutes}).toISO());
              }}
            />
          </Box>
          <DateTimeField
            title="Snooze until:"
            type="datetime"
            value={snoozeUntil}
            onChange={(value?: string): void => {
              if (!value) {
                toast.error("Cannot clear date");
                return;
              }
              // schedule online for a minimum of 5 minutes in the future
              if (DateTime.fromISO(value).diff(DateTime.now()).minutes >= 4) {
                setSnoozeUntil(value);
              } else {
                toast.error(
                  "Scheduled offline toggle time must be at least 5 minutes in the future"
                );
              }
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
};

import {useGetPatientWeeklyUpdatesQuery, useGetUsersByIdQuery} from "@store";
import {PatientWeeklyUpdate} from "@types";
import {isStaff} from "@utils";
import {Box, Pagination, printOnlyDate, Text} from "ferns-ui";
import React, {ReactElement, useState} from "react";

const WeeklyUpdateItem = ({item}: {item: PatientWeeklyUpdate}): ReactElement => {
  return (
    <Box>
      <Box marginTop={2} paddingY={1}>
        {item.weekOfDate && <Text>{`Week of ${printOnlyDate(item.weekOfDate!)}:`}</Text>}
      </Box>
      <Box paddingY={4}>
        <Text>{item.updateText}</Text>
      </Box>
    </Box>
  );
};

export const PreviousPatientWeeklyUpdates = ({
  userId,
}: {
  userId: string;
}): React.ReactElement | null => {
  const {data: user} = useGetUsersByIdQuery(userId);
  const [page, setPage] = useState(1);
  const {data: weeklyUpdatesData} = useGetPatientWeeklyUpdatesQuery({patientId: userId, page});
  const totalPages = Math.ceil((weeklyUpdatesData?.total ?? 0) / (weeklyUpdatesData?.limit ?? 1));

  if (!user || !weeklyUpdatesData?.data || isStaff(user?.type)) {
    return null;
  }

  let weeklyUpdatesList =
    page === totalPages
      ? [...weeklyUpdatesData?.data, {updateText: user.weeklyUpdates ?? ""}]
      : weeklyUpdatesData?.data;
  if (page === 1 && weeklyUpdatesData?.data.length > 0) {
    // Remove the first weekly update, as it's the current weekly update.
    weeklyUpdatesList = weeklyUpdatesList.slice(1);
  }

  return (
    <Box>
      {weeklyUpdatesList.map((item: PatientWeeklyUpdate) => (
        <WeeklyUpdateItem key={item._id ?? "update"} item={item} />
      ))}
      <Pagination page={page} setPage={setPage} totalPages={totalPages} />
    </Box>
  );
};

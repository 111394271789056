import {ChatBoxComponent} from "@components";
import {useFetchPopulatedMessages} from "@hooks";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {Message, PopulatedMessage, useGetCarePodsQuery, useGetUsersQuery} from "@store";
import {StaffStackScreenProps} from "@types";
import {IsMobileDevice, UserTypes} from "@utils";
import {
  Box,
  DateTimeField,
  humanDateAndTime,
  IconButton,
  SelectField,
  SplitPage,
  Text,
  useToast,
} from "ferns-ui";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import {DateTime} from "luxon";
import React, {useEffect, useMemo, useState} from "react";
import {ListRenderItemInfo} from "react-native";
interface ItemInfoItem {
  id: string;
  name: string;
  count: number;
  created: string;
}

interface Props extends StaffStackScreenProps<"MessageExplorer"> {}

export const MessageExplorerScreen = ({navigation}: Props): React.ReactElement => {
  const toast = useToast();
  // We want to show messages from 5pm the previous night forward
  const lastNightDate = DateTime.now().minus({days: 1}).set({hour: 17, minute: 0, second: 0});
  // On Sunday and Monday, we want to show messages from Friday night forward
  if (DateTime.now().toFormat("cccc") === "Sunday") {
    lastNightDate.minus({day: 1});
  } else if (DateTime.now().toFormat("cccc") === "Monday") {
    lastNightDate.minus({day: 2});
  }

  const [startDate, setStartDate] = useState<string>(lastNightDate.toISO());
  const [endDate, setEndDate] = useState<string>(DateTime.now().toISO());
  const [carePodId, setCarePodId] = useState<string | undefined>();
  const [conversationId, setConversationId] = useState<string | undefined>();

  const {data: carePodData} = useGetCarePodsQuery({});
  const {data: userData} = useGetUsersQuery(
    carePodId
      ? {carePod: carePodId, type: {$in: [UserTypes.Patient, UserTypes.FamilyMember]}}
      : skipToken
  );
  const {data: sentMessages} = useFetchPopulatedMessages(
    Boolean(userData?.data?.length)
      ? {
          created: {$gte: startDate, $lt: endDate} as any,
          from: {$in: userData?.data?.map((user) => user._id)} as any,
        }
      : skipToken
  );
  // All messages in the select conversation so we can easily see if the messages were responded to
  const {data: conversationMessages} = useFetchPopulatedMessages(
    conversationId
      ? {
          created: {$gte: startDate, $lt: endDate} as any,
          conversationId,
        }
      : skipToken
  );

  const groupedMessageFn = (message: Message): string => message.conversationId;
  const groupedMessageData = groupBy(sentMessages?.data ?? [], groupedMessageFn);

  // Set the care pod to the first one in the list once we get the data.
  useEffect(() => {
    if (!carePodData?.data || carePodData?.data.length === 0) {
      return;
    }
    setCarePodId(carePodData?.data[0]._id);
  }, [carePodData?.data]);

  const messages = useMemo(
    () => sortBy(conversationMessages?.data ?? [], "created"),
    [conversationMessages?.data]
  );

  const listViewData = Object.entries(groupedMessageData).map(([cId, msgs]) => ({
    id: cId,
    name: (msgs[0] as PopulatedMessage).from?.name,
    count: msgs.length,
    created: (msgs[0] as PopulatedMessage).created,
  }));
  const [showMobileItemList, setShowMobileItemList] = useState<boolean>(true);

  const renderListViewHeader = (): React.ReactElement => (
    <Box marginBottom={4} padding={1}>
      <Box>
        <DateTimeField
          title="Start"
          type="datetime"
          value={startDate}
          onChange={(date?: string): void => {
            if (!date) {
              toast.error("Start time is required");
              return;
            }
            setStartDate(date);
          }}
        />
      </Box>
      <Box>
        <DateTimeField
          title="End"
          type="datetime"
          value={endDate}
          onChange={(date?: string): void => {
            if (!date) {
              toast.error("End time is required");
              return;
            }
            setEndDate(date);
          }}
        />
      </Box>
      <Box>
        <SelectField
          helperText="You must select a care pod to see messages"
          options={
            carePodData?.data?.map((carePod) => ({label: carePod.name, value: carePod._id})) ?? []
          }
          placeholder="---"
          requireValue={false}
          title="Care Pod"
          value={carePodId}
          onChange={(id: string | undefined): void => {
            setCarePodId(id);
          }}
        />
      </Box>
    </Box>
  );

  const renderListViewItem = (itemInfo: ListRenderItemInfo<ItemInfoItem>): React.ReactElement => (
    <Box
      key={itemInfo.item.id}
      border={conversationId === itemInfo.item.id ? "default" : undefined}
      color="base"
      padding={2}
    >
      <Text bold>{itemInfo.item.name}</Text>
      <Text>{itemInfo.item.count} new messages</Text>
      <Text>{humanDateAndTime(itemInfo.item.created)}</Text>
    </Box>
  );

  return (
    <SplitPage
      bottomNavBarHeight={0}
      listViewData={listViewData}
      renderListViewHeader={renderListViewHeader}
      renderListViewItem={renderListViewItem}
      showItemList={showMobileItemList}
      onSelectionChange={(itemInfo: {item: ItemInfoItem}): void => {
        if (!itemInfo?.item?.id) {
          return;
        }
        setConversationId(itemInfo.item.id);
        if (IsMobileDevice) {
          setShowMobileItemList(false);
          navigation.setOptions({
            headerLeft: () => {
              return (
                <IconButton
                  accessibilityLabel="deselect mobile item"
                  iconName="arrow-left"
                  onClick={(): void => {
                    setShowMobileItemList(true);
                    navigation.setOptions({
                      headerLeft: undefined,
                      headerTitle: "Message Explorer",
                    });
                  }}
                />
              );
            },
            headerTitle: () => {
              return (
                <Box>
                  <Text align="center">{itemInfo?.item?.name}</Text>
                </Box>
              );
            },
          });
        }
      }}
    >
      <Box color="base" flex="grow" height="100%" justifyContent="center" width="100%">
        {Boolean(conversationId) && (
          <ChatBoxComponent
            allowDelete={false}
            alwaysShowSend={false}
            conversationId={conversationId!}
            disableComposer
            doLoadEarlier={(): void => {}}
            isLoadingEarlier={false}
            messages={messages}
            renderUsernameOnMessage
            showLoadEarlier={false}
            showSendAsSms={false}
            onSend={(): void => {}}
          />
        )}
        {Boolean(!conversationId && conversationMessages?.data?.length) && (
          <Box alignItems="center" flex="grow" justifyContent="center">
            <Text bold size="lg">
              Select a patient on the left
            </Text>
          </Box>
        )}
        {Boolean(!conversationId && conversationMessages?.data?.length === 0) && (
          <Box alignItems="center" flex="grow" justifyContent="center">
            <Text bold size="lg">
              No messages for this time period
            </Text>
          </Box>
        )}
        {Boolean(conversationId && sentMessages?.data?.length === 0) && (
          <Box alignItems="center" flex="grow" justifyContent="center">
            <Text bold size="lg">
              No messages
            </Text>
          </Box>
        )}
      </Box>
    </SplitPage>
  );
};

import {FieldConfig, ModelAdminScreen} from "@components";
import {
  useDeleteExternalCliniciansByIdMutation,
  useGetExternalCliniciansQuery,
  usePatchExternalCliniciansByIdMutation,
  usePostExternalCliniciansMutation,
} from "@store";
import {StaffStackScreenProps} from "@types";
import React from "react";

interface Props extends StaffStackScreenProps<"ExternalClinicianAdmin"> {}

export const ExternalClinicianAdminScreen = ({navigation}: Props): React.ReactElement => {
  return (
    <ModelAdminScreen
      confirmationText={
        "Are you sure you want to delete? Make sure you've removed this clinician from all users before deleting, or the app will behave weirdly."
      }
      description="External Clinicians are clinicians that refer families to us."
      display={(clinician): {title: string; subtitle?: string} => ({title: clinician?.name})}
      fields={[{fieldKey: "name", type: "text", title: "Name"}] as FieldConfig[]}
      navigation={navigation}
      useCreate={usePostExternalCliniciansMutation}
      useList={useGetExternalCliniciansQuery}
      useRemove={useDeleteExternalCliniciansByIdMutation}
      useUpdate={usePatchExternalCliniciansByIdMutation}
    />
  );
};

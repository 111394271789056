import {useReadProfile} from "@hooks";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  GetCriticalEventsByIdRes,
  useGetUsersQuery,
  usePostCriticalEventsMutation,
  useUIError,
} from "@store";
import {Box, Button, Modal, Text} from "ferns-ui";
import React, {ReactElement, useCallback, useMemo, useState} from "react";
interface CriticalEventHelpModalProps {
  associatedUserId: string;
  showHelpModal: boolean;
  setHelpModal: (showHelpModal: boolean) => void;
}

// TODO once Natalia is ready to relinquish responsiblity,
// replace her name with the current clinical director.
const CONSULT_CONFIRMATION_MESSAGE =
  "Please text Natalia if you have not heard from your manager in 5 minutes. Her cell is 314-941-7950 for text messages.";

const CRISIS_CONFIRMATION_MESSAGE =
  " Please text Natalia at \n314-941-7950 if you haven't heard from your manager in 1 minute.";

export const CriticalEventHelpModal = ({
  associatedUserId,
  showHelpModal,
  setHelpModal,
}: CriticalEventHelpModalProps): ReactElement | null => {
  const profile = useReadProfile();
  const uiError = useUIError();
  const [createCriticalEvent] = usePostCriticalEventsMutation();

  const [showResponse, setShowResponse] = useState(false);
  const [eventType, setEventType] = useState<GetCriticalEventsByIdRes["type"]>(undefined);
  const [consultedSupervisors, setConsultedSupervisors] = useState<string[] | null>(null);
  const [createdCriticalEventForm, setCreatedCriticalEventForm] = useState<boolean>(false);
  const [errorMsgs, setErrorMsgs] = useState<string[] | null>(null);

  const {data: users} = useGetUsersQuery(
    consultedSupervisors ? {_id: {$in: consultedSupervisors}} : skipToken
  );

  const onClose = useCallback((): void => {
    setHelpModal(false);
    setErrorMsgs(null);
    setShowResponse(false);
    setConsultedSupervisors(null);
    setCreatedCriticalEventForm(false);
    setEventType(undefined);
  }, [setHelpModal]);

  const createHelpRequest = useCallback(
    async (type: GetCriticalEventsByIdRes["type"]) => {
      try {
        const response = await createCriticalEvent({
          associatedUserId,
          type,
        }).unwrap();
        setErrorMsgs(null);
        if (response?.consultedSupervisors?.length) {
          const superIds = response.consultedSupervisors
            .filter((sup) => sup.userId !== undefined)
            .map((s) => s.userId as string);
          setConsultedSupervisors(superIds);
        }
        if (response?.formIds?.length && eventType === "Crisis Escalation") {
          setCreatedCriticalEventForm(true);
        }
        setEventType(type);
        setShowResponse(true);
      } catch (error: any) {
        setErrorMsgs([
          `An Error occurred trying to contact your Supervisor.`,
          `If this is an emergency please call your supervisor immediately.`,
        ]);
        uiError(error?.data?.title ?? "Error Trying to contact your Supervisor.", error);
      }
    },
    [associatedUserId, createCriticalEvent, eventType, uiError]
  );

  const successMessages = useMemo(() => {
    const resMessage: string[] = [
      `${eventType === "Consult" ? "Your request for a Consult has been sent." : "Your request for this Critical Event has been sent. Your supervisor will be texted immediately."}`,
    ];
    const supervisorsNames = users?.data?.map((u) => u?.name).join(", ");

    if (supervisorsNames?.length) {
      resMessage.push(
        `Your supervisor, ${supervisorsNames}, has been notified.` +
          `${eventType === "Crisis Escalation" ? CRISIS_CONFIRMATION_MESSAGE : CONSULT_CONFIRMATION_MESSAGE}`
      );
    }
    if (createdCriticalEventForm) {
      resMessage.push(
        "The Critical Event note has been created for this patient. Please work with your supervisor to fill it out after the event."
      );
    }
    return resMessage;
  }, [users, createdCriticalEventForm, eventType]);

  if (!showHelpModal || !profile) return null;

  return (
    <Box alignSelf="end" direction="row" marginTop={1}>
      <Modal
        secondaryButtonOnClick={onClose}
        secondaryButtonText={!showResponse ? "Cancel" : "Close"}
        size="md"
        title={!showResponse ? "Get Help" : "Request Sent"}
        visible={showHelpModal}
        onDismiss={onClose}
      >
        <Box alignContent="center" justifyContent="center" width="100%">
          {!showResponse ? (
            <>
              <Text align="center">We&apos;ll notify your supervisor that you need help.</Text>
              <Text align="center">What kind of help do you need?</Text>
              <Box
                direction="row"
                gap={4}
                justifyContent="center"
                marginTop={2}
                paddingY={3}
                width="100%"
              >
                <Box width="50%">
                  <Button
                    text="Request A Consult"
                    variant="outline"
                    onClick={() => createHelpRequest("Consult")}
                  />
                </Box>
                <Box width="50%">
                  <Button
                    text="Crisis Escalation"
                    variant="destructive"
                    onClick={() => createHelpRequest("Crisis Escalation")}
                  />
                </Box>
              </Box>
              {errorMsgs &&
                errorMsgs.map((msg, i) => (
                  <Box
                    key={`help-err-${i}`}
                    direction="column"
                    justifyContent="center"
                    marginTop={2}
                  >
                    <Text align="center" color="error">
                      {msg}
                    </Text>
                  </Box>
                ))}
            </>
          ) : (
            <>
              {successMessages.map((msg, i) => (
                <Box key={`help-msg-${i}`} direction="column" justifyContent="center" marginTop={3}>
                  <Text align="center" bold>
                    {msg}
                  </Text>
                </Box>
              ))}
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

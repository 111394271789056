import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  useGetFitbitHrvQuery,
  useGetFitbitSleepQuery,
  useGetFitbitStepsQuery,
  useGetUsersByIdQuery,
  usePatchUsersByIdMutation,
  userIsLocked,
} from "@store";
import {Box, Button, ScrollView, SegmentedControl, SelectField, Text} from "ferns-ui";
import React, {useState} from "react";

import {ClinicalInfo} from "./ClinicalInfo";
import {FitbitStatusCard} from "./FitbitStatus";
import {OpenNotesPane} from "./OpenNotesPane";
import {UserErrorCard} from "./UserErrorCard";
import {UserHeartRateVariabilityChart} from "./UserHrvChart";
import {UserInfo} from "./UserInfo";
import {UserInfoCard} from "./UserInfoCard";
import {UserSleepChart} from "./UserSleepChart";
import {UserStepsChart} from "./UserStepsChart";
import {UserTextField} from "./UserTextField";

const fitbitTimeOptions = [
  {label: "1 day", value: "1d"},
  {label: "1 week", value: "7d"},
  {label: "2 weeks", value: "14d"},
  {label: "1 month", value: "1mo"},
  {label: "3 months", value: "3mo"},
];

interface ManageFormsRightBarProps {
  userId?: string;
}

export const ManageFormsRightBar = ({
  userId,
}: ManageFormsRightBarProps): React.ReactElement | null => {
  const [selectedTimeframe, setSelectedTimeframe] = useState("7d");
  const [tab, setTab] = useState<number>(0);

  const [updateUser, {isLoading}] = usePatchUsersByIdMutation();

  const {data: user} = useGetUsersByIdQuery(userId ?? skipToken);
  const {data: fitbitSleepData} = useGetFitbitSleepQuery({period: selectedTimeframe});
  const {data: fitbitStepData} = useGetFitbitStepsQuery({period: selectedTimeframe});
  const {data: fitbitHrvData} = useGetFitbitHrvQuery({period: selectedTimeframe});

  const fitbitSleep = fitbitSleepData?.data ?? [];
  const fitbitSteps = fitbitStepData?.data ?? [];
  const fitbitHrvs = fitbitHrvData?.data ?? [];

  const sleep = fitbitSleep.filter((s) => s.userId === user?._id);
  const steps = fitbitSteps.filter((s) => s.userId === user?._id);
  const hrvs = fitbitHrvs.filter((s) => s.userId === user?._id);

  if (!user) {
    return null;
  }

  function renderLockout(): React.ReactElement | null {
    if (!user || !userIsLocked(user)) {
      return null;
    } else {
      return (
        <UserInfoCard title="User is locked">
          <Box paddingY={4}>
            <Text>The user tried an incorrect password {user.attempts} times.</Text>
          </Box>
          <Box paddingY={4}>
            <Button
              disabled={isLoading}
              loading={isLoading}
              text="Unlock User"
              onClick={async (): Promise<void> => {
                await updateUser({id: user._id, body: {attempts: 0}});
              }}
            />
          </Box>
        </UserInfoCard>
      );
    }
  }

  function renderClinical(): React.ReactElement | null {
    if (!user) {
      return null;
    }
    return (
      <>
        {renderLockout()}
        <UserErrorCard user={user} />
        <UserInfoCard collapsable title="One Liner">
          <UserTextField field="oneLiner" userId={user._id} />
        </UserInfoCard>
        <UserInfoCard collapsable title="Weekly Updates">
          <UserTextField field="weeklyUpdates" userId={user._id} />
        </UserInfoCard>
        <UserInfoCard collapsable title="Engagement Details">
          <UserTextField field="engagementDetails" userId={user._id} />
        </UserInfoCard>
        <UserInfoCard collapsable title="Open Notes">
          <OpenNotesPane userId={user._id} />
        </UserInfoCard>
        <ClinicalInfo userId={user._id} />
        {/* <UserInfoCard collapsable title="Family Unit - Displayed in Patient App">
          <FamilyUnitPane userId={user._id} />
        </UserInfoCard> */}
        <UserInfoCard collapsable title="Care Plan - Displayed in Patient App">
          <UserTextField field="carePlan" userId={user._id} />
        </UserInfoCard>
        <UserInfoCard collapsable title="Safety Plan - Displayed in Patient App">
          <UserTextField field="safetyPlan" userId={user._id} />
        </UserInfoCard>
        <UserInfoCard collapsable title="Thrive Plan - Displayed in Patient App">
          <UserTextField field="thrivePlan" userId={user._id} />
        </UserInfoCard>
        {/* <UserInfoCard collapsable title="Templated Notes">
          <FormsPane userId={user._id} />
        </UserInfoCard>
        <UserInfoCard collapsable title="Schedule">
          <UserSchedule type="patient" userId={user._id} />
        </UserInfoCard> */}
      </>
    );
  }

  function renderUserInfo(): React.ReactElement | null {
    if (!user) {
      return null;
    }

    return (
      <UserInfo key={user._id} userId={user._id} />
      // <> {Boolean(profile && isSuperUser(profile)) && ( <UserInfoCard title="Delete User"> <Box
      // paddingY={2}> <Text> Make sure to cancel any scheduled items for the patient (so they are
      // not notified). </Text> </Box> <Box paddingY={2}> <Text> <Text color="error" bold>
      //             Warning&#58; {/* eslint-disable-next-line react-native/no-raw-text */}
      // </Text> To undo this, you will need to message in the #product channel to have an engineer
      // undelete the user. </Text> </Box> <Box paddingY={2}> <Button variant="destructive"
      // confirmationHeading={`Are you sure you want to delete ${user.name}?`} confirmationText={
      // `To undelete the user, you will need to message in the #product channel to have` + ` an
      // engineer undelete the user.` } text="Delete User"  withConfirmation onClick={async ():
      // Promise<void> => { await removeUser(user._id); toast.show("User deleted.");
      // }} /> </Box> </UserInfoCard> )} </>
    );
  }

  function renderFitbit(): React.ReactElement | null {
    if (!user) {
      return null;
    }

    return (
      <UserInfoCard title="Fitbit Data">
        {user.fitbitId ? (
          <>
            <FitbitStatusCard userId={user._id} />
            <SelectField
              options={fitbitTimeOptions}
              requireValue
              title="Show previous:"
              value={selectedTimeframe}
              onChange={setSelectedTimeframe}
            />
            <UserStepsChart fitbitSteps={steps} user={user} />
            <UserSleepChart sleep={sleep} user={user} />
            <UserHeartRateVariabilityChart fitbitHrvs={hrvs} user={user} />
          </>
        ) : (
          <Box direction="column">
            <Text color="error" size="lg">
              Fitbit not yet connected by user
            </Text>
            <Text color="secondaryLight" size="md">
              Please guide patient to connect their Fitbit account from their app
            </Text>
          </Box>
        )}
      </UserInfoCard>
    );
  }

  return (
    <Box color="base" flex="grow" gap={2} height="100%" paddingY={4} rounding="md">
      <Box paddingX={4}>
        <SegmentedControl
          items={["User", "Clinical", "Fitbit"]}
          selectedIndex={tab}
          onChange={(activeIndex): void => setTab(activeIndex as number)}
        />
      </Box>
      <ScrollView>
        <Box paddingX={4}>
          {Boolean(tab === 0) && renderUserInfo()}
          {Boolean(tab === 1) && renderClinical()}
          {Boolean(tab === 2) && renderFitbit()}
        </Box>
      </ScrollView>
    </Box>
  );
};

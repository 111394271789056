import {ChatBox} from "@components";
import {Box} from "ferns-ui";
import React, {ReactElement} from "react";

interface ConversationViewerProps {
  conversationId: string;
}

export const ConversationViewer = ({
  conversationId,
}: ConversationViewerProps): ReactElement | null => {
  return (
    <Box
      color="base"
      direction="row"
      flex="grow"
      height="100%"
      justifyContent="center"
      width="100%"
    >
      <ChatBox conversationId={conversationId} disableComposer />
    </Box>
  );
};

import {getAuthToken} from "@ferns-rtk";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import {useUIError} from "@store";
import React, {ReactElement, useEffect, useRef, useState} from "react";

interface ChartProps {
  dashboardId: string;
  height: number;
  width: number;
}
export const MongoDashboard = ({dashboardId, height, width}: ChartProps): ReactElement => {
  const sdk = new ChartsEmbedSDK({
    baseUrl: "https://charts.mongodb.com/charts-production-pkztx",
    getUserToken: async (): Promise<string> => {
      const token = await getAuthToken();
      return token || "";
    },
  });
  const chartDiv = useRef<HTMLDivElement>(null);
  // by using useState, we can ensure that the chart is only created once and we can reference it
  // no matter how many times the component is re-rendered
  const [chart] = useState(sdk.createDashboard({dashboardId, height, width, widthMode: "scale"}));
  const uiError = useUIError();

  // when the component mounts, render the chart into the ref we created
  useEffect(() => {
    chart
      .render(chartDiv.current!)
      .catch((error: any) => uiError("Error during Charts rendering.", error));
  }, [chart, uiError]);

  return <div ref={chartDiv} className="chart" />;
};

import {useAnalytics, useGetPopulateUserLookup} from "@hooks";
import {useNavigation} from "@react-navigation/native";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  FamilyUnit,
  isUserInFamilyUnit,
  useDeleteFamilyUnitsByIdMutation,
  useGetFamilyUnitsQuery,
  useGetUsersByIdQuery,
} from "@store";
import {StaffStackParamList} from "@types";
import {IsMobileDevice, isStaff, UserTypes} from "@utils";
import {Box, Button, Icon, Text, Tooltip} from "ferns-ui";
import flatten from "lodash/flatten";
import React, {useEffect, useState} from "react";

import {FilterItem} from "./FilterItems";
interface CarePlanPaneProps {
  userId: string;
}

export const FamilyUnitPane = ({userId}: CarePlanPaneProps): React.ReactElement | null => {
  const logEvent = useAnalytics();

  const {data: user} = useGetUsersByIdQuery(userId);
  const {data: familyUnitData} = useGetFamilyUnitsQuery(
    userId ? {familyUsers: userId as any} : skipToken
  );
  const [familyUnits, setFamilyUnits] = useState<FamilyUnit[]>([]);
  const navigation =
    useNavigation<NativeStackNavigationProp<StaffStackParamList, UserTypes.Staff>>();
  const [removeFamilyUnit] = useDeleteFamilyUnitsByIdMutation();

  const {userLookup} = useGetPopulateUserLookup(
    flatten((familyUnitData?.data ?? []).map((u) => u.familyUsers))
  );

  // Set the family units for the user once the data is loaded.
  useEffect(() => {
    if (user && familyUnitData?.data) {
      const filteredFamilyUnits = familyUnitData.data.filter((f) => isUserInFamilyUnit(user, f));
      setFamilyUnits(filteredFamilyUnits);
    }
  }, [familyUnitData, user]);

  if (!user || isStaff(user?.type)) {
    return null;
  }

  return (
    <Box gap={4}>
      <Box>{!Boolean(familyUnits?.length) && <Text>No family unit set.</Text>}</Box>
      {familyUnits.map((f) => {
        return (
          <FilterItem
            key={f._id}
            confirmationText="Are you sure you want to remove this family unit?"
            dismissable
            editable
            showDelete
            withConfirmation
            onDismiss={async () => {
              await removeFamilyUnit(f._id)
                .unwrap()
                .catch((error: any) => console.error("Error deleting family unit", error));
              await logEvent({
                name: "DeleteFamilyUnit",
                userType: user.type,
                collectionModel: "familyUnits",
                isActivityLogEvent: true,
                appliedUserId: user._id,
                docId: user._id,
              });
            }}
            onEdit={() => {
              navigation.navigate("CreateFamilyUnit", {familyUnitId: f._id});
            }}
          >
            <Box flex="grow">
              <Box>
                <Text bold size="lg">
                  {f.name || "Untitled"}
                </Text>
              </Box>
              {!Boolean(f.primaryCaregiver) && (
                <Box paddingY={1} width="100%" wrap>
                  <Text color="error">
                    No primary caregiver set, please edit the family and choose a primary for
                    engagement tracking to work.
                  </Text>
                </Box>
              )}
              <Box>
                {f.familyUsers.map((u: string) => {
                  const familyUser = userLookup[u];
                  if (!familyUser || familyUser._id === userId) {
                    return;
                  }
                  const relationship = f.relationships?.find((r) => r.familyUserId === u);
                  return (
                    <Box
                      key={familyUser._id}
                      accessibilityHint="View user details"
                      accessibilityLabel="View"
                      direction="row"
                      padding={1}
                      onClick={(): void => {
                        navigation.navigate("User", {userId: familyUser._id});
                      }}
                    >
                      <Box marginRight={2} width={16}>
                        {Boolean(f.primaryCaregiver === familyUser._id) && (
                          <Tooltip text="Primary Caregiver">
                            <Icon iconName="star" />
                          </Tooltip>
                        )}
                      </Box>

                      <Box direction="column" flex="grow">
                        <Text underline>
                          {familyUser.name}
                          {relationship ? ` (${relationship.relationship})` : ""}
                        </Text>
                        <Text>{familyUser.phoneNumber}</Text>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </FilterItem>
        );
      })}
      {Boolean(!IsMobileDevice) && (
        <Box direction="row" paddingY={2} width={180}>
          <Button
            text="Create Family Unit"
            onClick={(): void => {
              navigation.navigate("CreateFamilyUnit", {selectedUserId: userId});
            }}
          />
        </Box>
      )}
    </Box>
  );
};

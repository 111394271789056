import {useSelectCurrentUserId} from "@ferns-rtk";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {useGetUsersByIdQuery, User} from "@store";

export function useReadProfile(): User | undefined {
  const currentUserId = useSelectCurrentUserId();
  const {data: userData} = useGetUsersByIdQuery(currentUserId ?? skipToken);
  if (!currentUserId) {
    return undefined;
  }
  return userData as User;
}

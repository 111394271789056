type AlertDocumentationContent = {
  title: string;
  text: string;
};

type AlertDocumentationContents = {
  alertRecipients: AlertDocumentationContent;
  triggeringEvents: AlertDocumentationContent;
  centerVsBanner: AlertDocumentationContent;
  textMergeFields: AlertDocumentationContent;
};

export const AlertDocumentationContents: AlertDocumentationContents = {
  alertRecipients: {
    title: "Alert Recipients",
    text: [
      "Alerts are specifically designed for staff members. The system identifies the relevant staff members based on their roles and the specific requirements of the alert and will appear in the alert center if the patient is in their workflow.",
      "",
      "**Super User** - Receives all alerts.",
      "**Enrollment Coordinator** - Receives alerts related to all enrollment coordinator, not workflow dependant.",
      "**Therapist** - Receives alerts related to their role if patient is in their workflow or if they are part of a patient's care team.",
      "**Psychiatrist** - Receives alerts related to their role if patient is in their workflow or if they are part of a patient's care team.",
      "**Patient Guide** - Receives alerts related to their role if patient is in their workflow or if they are part of a patient's care team.",
      "**Family Guide** - Receives alerts related to their role if patient is in their workflow or if they are part of a patient's care team.",
      "**Therapist Supervisor** - Receives alerts related to their role if patient is in their workflow or if they are part of a patient's care team.",
      "**Patient Guide Supervisor** - Receives alerts related to their role if patient is in their workflow or if they are part of a patient's care team.",
      "**Family Guide Supervisor** - Receives alerts related to their role if patient is in their workflow or if they are part of a patient's care team.",
    ].join("\n"),
  },
  triggeringEvents: {
    title: "Triggering Events",
    text: [
      "Alerts are triggered by specific events in the system. A Triggering Event is used to define the specific condition or event that triggers the alert. These events are defined in the system and are used to identify when an alert should be created. For example, an alert can be triggered when a patient has not completed a form within a specific time frame, missing patient information, a status change, or any other significant event. It essentially acts as a criterion for when an alert should be generated.",
      "",
      "#### Supported Triggering Events",
      "**Missing Patient Information:** ",
      "Generated before or after a scheduled item when essential patient information is missing in the system. Supported fields are: Address, Phone Number, Email, Billing Information, Care Pod, Care Team, and Preferred Pharmacy",
      "**Attendance Status:** ",
      "Generated immediately after a users attendance to an event is updated. Related to patient attendance, such as missed appointments or changes in scheduled visits.",
      "**Reminders:** ",
      "Generated when a reminder is set for a specific amount of time relative to a given date like enrolledDate.",
      "**Missing Family Unit:** ",
      "Generated before or after a scheduled item when a patient is not associated with a family unit.",
      "**Assign Todo:** ",
      "Generated upon the creation of a todo if the todo is marked 'Assign as User Alert'.",
    ].join("\n"),
  },
  centerVsBanner: {
    title: "Alert Center vs Alert Banner",
    text: [
      "The Alert Center is a dedicated area in the system where staff members can view and manage alerts. It is designed to provide a comprehensive view of based on their roles and the specific requirements of the alert. and allows staff members to take action on alerts, such as resolving or archiving them.",
      "",
      "An Alert Banner will appear above the conversation when the selected patient has an open unresolved and not archived alert. It is not dependant on Staff Role. This is to ensure that staff are aware the patient has an alert when they are communicating with them or bring attention to appropriate party members . The Alert Banner will disappear when the alert is resolved or archived.",
    ].join("\n"),
  },
  textMergeFields: {
    title: "Text Merge Fields And Definitions",
    text: [
      "Text Merge Fields are used for dynamically inserting specific information into the alert text. These fields act as placeholders within the alert message template. When an alert is triggered, these placeholders are replaced with actual data relevant to the alert context. For instance, in an alert about missing patient information, the AlertTextMergeFields could include placeholders for the patients name, missing data fields (like address or phone number), or other pertinent information. This dynamic insertion allows for the creation of customized and informative alert messages based on the specific situation and data involved.",
      "",
      "#### Supported Text Merge Fields",
      "**${missingPatientInfoOptions}** ",
      "If the triggering event is Missing Patient Information, this will be replaced with a list of the missing patient information options.",
      "Supported fields are: Address, Phone Number, Email, Billing Information, Care Pod, Care Team, and Preferred Pharmacy",
      "If the missing option is **Care Team**, the alert text will also include the name of the staff role who is missing from the care team.",
      "If the missing option is **Billing Information**, the alert text will include the missing data needed for billing information.",
    ].join("\n"),
  },
};

import {useFetchPopulatedScheduleItems, useReadProfile} from "@hooks";
import {useNavigation} from "@react-navigation/native";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import * as Sentry from "@sentry/react-native";
import {useGetUsersByIdQuery} from "@store";
import {StaffStackParamList} from "@types";
import {hasFeatureFlag, isStaff, IsWeb, UserTypes} from "@utils";
import {Box, Button} from "ferns-ui";
import React, {ReactElement} from "react";

import {FallbackScheduleItem, ScheduleItem} from "./UserScheduleItem";

interface UserScheduleProps {
  userId: string;
  type: UserTypes.Patient | UserTypes.Staff;
}

export const SchedulePane = ({userId, type}: UserScheduleProps): ReactElement | null => {
  const navigation = useNavigation<NativeStackScreenProps<StaffStackParamList>["navigation"]>();
  const profile = useReadProfile();
  const {data: selectedUser} = useGetUsersByIdQuery(userId);
  const {data: scheduleItems} = useFetchPopulatedScheduleItems({
    ...(isStaff(type) ? {"staff.userId": userId} : {"users.userId": userId}),
  });

  if (!profile) {
    return null;
  }

  return (
    <>
      <Box alignItems="center" direction="row" paddingY={4}>
        <Box width={200}>
          <Button
            iconName="plus"
            text="Add Schedule Item"
            onClick={(): void => {
              navigation.navigate("CreateScheduleItem", {
                selectedUserId: !isStaff(selectedUser?.type) ? selectedUser?._id : undefined,
                selectedStaffId: profile?._id,
              });
            }}
          />
        </Box>

        {IsWeb && hasFeatureFlag(profile, "appointments") && (
          <Box marginLeft={4} width={200}>
            <Button
              iconName="plus"
              text="Schedule Intake"
              onClick={(): void => {
                navigation.navigate("IntakeScheduling", {
                  selectedUserId: !isStaff(selectedUser?.type) ? selectedUser?._id : undefined,
                });
              }}
            />
          </Box>
        )}
      </Box>
      <Box>
        {(scheduleItems?.data ?? []).map((s) => (
          <Sentry.ErrorBoundary key={s._id} fallback={<FallbackScheduleItem />}>
            <ScheduleItem scheduleItem={s} type={type} userId={userId} />
          </Sentry.ErrorBoundary>
        ))}
      </Box>
    </>
  );
};

import {useGetUsersByIdQuery, usePatchUsersByIdMutation} from "@store";
import {isStaff} from "@utils";
import {Box, Button, Text, TextArea} from "ferns-ui";
import React, {useState} from "react";

interface UserBioPaneProps {
  userId: string;
}

export const UserBioPane = ({userId}: UserBioPaneProps): React.ReactElement | null => {
  const [updateUser, {isLoading}] = usePatchUsersByIdMutation();
  const {data: user} = useGetUsersByIdQuery(userId);
  const [editUserBio, setEditUserBio] = useState(false);
  const [userBio, setUserBio] = useState(user?.bio);

  if (!user || isStaff(user?.type)) {
    return null;
  }

  if (editUserBio) {
    return (
      <Box>
        <TextArea
          blurOnSubmit={false}
          grow
          placeholder={`This is a space to tell us about yourself! What do you like to do for fun? What's important to you? We would also love to hear what your purpose for being at Flourish is, if you are comfortable sharing.\n\n`}
          value={userBio}
          onChange={(result): void => setUserBio(result)}
        />
        <Box direction="row" gap={2} paddingY={2}>
          <Button
            confirmationText="Are you sure you want to save your changes and update the About Me section of your profile?"
            disabled={isLoading}
            loading={isLoading}
            modalTitle="Save Changes"
            text="Save"
            withConfirmation
            onClick={async (): Promise<void> => {
              await updateUser({id: user._id, body: {bio: userBio}});
              setEditUserBio(false);
            }}
          />
          <Button
            confirmationText="Are you sure you're done editing? Your changes will not be saved."
            text="Cancel"
            withConfirmation={user?.bio !== userBio}
            onClick={(): void => {
              setEditUserBio(false);
              setUserBio(user.bio);
            }}
          />
        </Box>
      </Box>
    );
  } else {
    return (
      <Box>
        <Box paddingY={2}>
          <Text>{user.bio || "Not set."}</Text>
        </Box>

        <Box direction="row" paddingY={2}>
          <Button
            text="Edit"
            onClick={(): void => {
              setEditUserBio(true);
              setUserBio(user.bio);
            }}
          />
        </Box>
      </Box>
    );
  }
};

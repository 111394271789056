// A tool button that runs a mutation and shows a toast on success or error,
// as well as the output of the command.
import {Box, Button, IconName, Text, Tooltip, useToast} from "ferns-ui";
import {isAPIError, printAPIError} from "ferns-ui/dist/Utilities";
import React, {ReactElement, useEffect} from "react";

interface MutationToolButtonProps {
  mutation: any;
  sideEffect?: () => Promise<void> | void;
  mutationParams?: any;
  name: string;
  icon: IconName;
  tooltip?: string;
}

export const MutationToolButton = ({
  mutation,
  sideEffect = (): void => {},
  mutationParams,
  name,
  icon,
  tooltip,
}: MutationToolButtonProps): ReactElement => {
  const toasts = useToast();
  const [doMutation, {error, isSuccess}] = mutation();

  // Show the error message if there is one, only once.
  useEffect(() => {
    if (error) {
      toasts.error(`${name} had an error`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  // Show the success message if there is one, only once.
  useEffect(() => {
    if (isSuccess) {
      toasts.show(`${name} was successful`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const errorMessage = isAPIError(error?.data)
    ? printAPIError(error.data)
    : error?.data?.message ?? "Unknown error";

  return (
    //   Expanded to show the error message
    <Box width={error ? "100%" : undefined}>
      <Tooltip text={tooltip || ""}>
        <Box marginRight={5} paddingY={2} width={300}>
          <Button
            iconName={icon}
            text={name}
            onClick={async (): Promise<void> => {
              await doMutation({...mutationParams});
              await sideEffect();
            }}
          />
        </Box>
      </Tooltip>
      {Boolean(error) && (
        <Box paddingY={2}>
          <Text color="error">
            {name} Error: {errorMessage}
          </Text>
        </Box>
      )}
    </Box>
  );
};

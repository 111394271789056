import {usePrevious} from "@hooks";
import {Accordion, Box, SignedUpTo12} from "ferns-ui";
import React, {ReactElement, useEffect, useState} from "react";
import {Dimensions, ScrollView} from "react-native";

import {ClinicalInfoModalText} from "../constants";

const InfoModalChildren: React.FC<{infoKey: keyof typeof ClinicalInfoModalText}> = ({infoKey}) => {
  const windowHeight = Dimensions.get("window").height;

  return (
    <Box maxHeight={windowHeight * 0.6}>
      <ScrollView>{ClinicalInfoModalText[infoKey]?.textComponent() ?? null}</ScrollView>
    </Box>
  );
};

interface UserInfoCardProps {
  title: string;
  subtitle?: string;
  collapsable?: boolean;
  collapseExternal?: boolean;
  children: ReactElement | null | (ReactElement | null)[];
  enableEditButton?: boolean;
  isEditing?: boolean;
  infoModal?: string;
  initialCollapsed?: boolean;
  setIsEditing?: (isEditing: boolean) => void;
  onSave?: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
  gap?: SignedUpTo12;
}

export const UserInfoCard = ({
  title,
  subtitle,
  collapseExternal = false,
  children,
  enableEditButton = false,
  isEditing,
  infoModal,
  initialCollapsed = false,
  setIsEditing,
  onSave,
  onCancel,
  gap,
}: UserInfoCardProps): ReactElement => {
  if (enableEditButton) {
    if (!setIsEditing) {
      throw new Error("setIsEditing is required if enableEditButton is true");
    }
    if (isEditing === undefined) {
      throw new Error("isEditing is required if enableEditButton is true");
    }
    if (!onSave) {
      throw new Error("onSave is required if enableEditButton is true");
    }
    if (!onCancel) {
      throw new Error("onCancel is required if enableEditButton is true");
    }
  }
  const [isCollapsed, setIsCollapsed] = useState(initialCollapsed);
  const previousCollapseExternal = usePrevious(collapseExternal);
  const info = infoModal ? ClinicalInfoModalText[infoModal as string] : undefined;

  // The external collapse state should override the internal collapse state.
  useEffect(() => {
    // Only override the initial state if the external state has changed after the initial render.
    if (previousCollapseExternal !== undefined && previousCollapseExternal !== collapseExternal) {
      setIsCollapsed(collapseExternal);
    }
  }, [collapseExternal, previousCollapseExternal]);

  return (
    <Accordion
      includeInfoModal={Boolean(info)}
      infoModalChildren={
        infoModal ? <InfoModalChildren infoKey={infoModal as string} /> : undefined
      }
      infoModalSubtitle={info?.subtitle}
      infoModalTitle={info?.title}
      isCollapsed={isCollapsed}
      subtitle={subtitle}
      title={title}
    >
      <Box gap={gap ?? 0}>{children}</Box>
    </Accordion>
  );
};

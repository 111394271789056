import {useSelectCurrentUserId} from "@ferns-rtk";
import {useGetPopulateUserLookup} from "@hooks";
import {
  setUnreadUserUpdates,
  useAppDispatch,
  useGetUserUpdatesQuery,
  usePatchUserUpdatesByIdMutation,
  User,
  UserUpdate,
} from "@store";
import {
  Box,
  Button,
  Card,
  Heading,
  Icon,
  IconButton,
  MultiselectField,
  Pagination,
  printDateAndTime,
  Text,
} from "ferns-ui";
import uniqBy from "lodash/uniqBy";
import React, {useCallback, useEffect, useRef, useState} from "react";

interface UserUpdatesParams {
  items: UserUpdate[];
}

interface UserUpdateCardParams {
  item: UserUpdate;
}

export const UserUpdateCard = ({item}: UserUpdateCardParams): React.ReactElement => {
  const [updateUserUpdate] = usePatchUserUpdatesByIdMutation();

  const markRead = async (): Promise<void> => {
    await updateUserUpdate({id: item._id, body: {markedRead: true}});
  };

  const markUnread = async (): Promise<void> => {
    await updateUserUpdate({id: item._id, body: {markedRead: false}});
  };

  const archiveUserUpdate = async (): Promise<void> => {
    await updateUserUpdate({id: item._id, body: {archived: true}});
  };

  const renderChanges = (): React.ReactElement | null => {
    if (item.prevValue || item.newValue) {
      return (
        <>
          {Boolean(item.prevValue) && (
            <Box marginBottom={2}>
              <Text bold>Previous value:</Text>
              <Text>{item?.prevValue || ""}</Text>
            </Box>
          )}
          <Box>
            <Text bold>New value: </Text>
            <Text>{item?.newValue || ""}</Text>
          </Box>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <Card margin={1} paddingX={3} paddingY={2}>
      <Box alignItems="center" direction="row" justifyContent="between" paddingY={1}>
        <Box>{!Boolean(item.markedRead) && <Icon iconName="circle" />}</Box>
        <Box alignItems="center" direction="row" justifyContent="between">
          <Box marginRight={1}>
            <IconButton
              accessibilityLabel="Archive"
              iconName="box-archive"
              variant="secondary"
              onClick={archiveUserUpdate}
            />
          </Box>
          <Box>
            <IconButton
              accessibilityLabel={item.markedRead ? "Mark Read" : "Mark Unread"}
              iconName={item.markedRead ? "envelope" : "envelope-open"}
              variant="secondary"
              onClick={item.markedRead ? markUnread : markRead}
            />
          </Box>
        </Box>
      </Box>
      <Box direction="column" justifyContent="between" padding={2}>
        <Text bold>{item?.description}</Text>
        {!Boolean(item.formName) && (
          <Box alignContent="center" justifyContent="center" padding={2} width="100%">
            {renderChanges()}
          </Box>
        )}
      </Box>
      <Text align="right" bold>
        {printDateAndTime(item.created)}
      </Text>
    </Card>
  );
};

const UserUpdates = ({items}: UserUpdatesParams): React.ReactElement | null => {
  return (
    <Box>
      {items.map((item) => (
        <UserUpdateCard key={item._id} item={item} />
      ))}
    </Box>
  );
};

export const UserUpdatesView = (): React.ReactElement | null => {
  const currentUserId = useSelectCurrentUserId();
  const [updateUserUpdate] = usePatchUserUpdatesByIdMutation();
  const dispatch = useAppDispatch();

  const [page, setPage] = useState(1);

  const [showFilters, setShowFilters] = React.useState<boolean>(false);
  const [userUpdatesFilter, setUserUpdatesFilter] = React.useState<string[]>([]);

  const {data: userUpdates} = useGetUserUpdatesQuery({
    ownerId: currentUserId,
    appliedUserId: {$in: userUpdatesFilter},
    page,
  });

  const {userLookup} = useGetPopulateUserLookup(
    (userUpdates?.data ?? []).map((u) => u.appliedUserId as string)
  );

  // TODO Load applied user list in from a query (probably workflow mappings?) rather than from the
  // user updates.
  const appliedUserList = useRef<User[]>([]);
  appliedUserList.current = uniqBy(
    [...appliedUserList.current, ...(Object.values(userLookup ?? {}) ?? [])],
    "_id"
  );

  const markAllRead = (): void => {
    userUpdates?.data?.forEach(async (u) => {
      await updateUserUpdate({id: u._id, body: {markedRead: true}});
    });
  };

  const areAllRead = useCallback((): boolean => {
    return userUpdates?.data?.every((u) => u.markedRead) ?? false;
  }, [userUpdates]);

  // Set the unread user updates flag when the user updates change
  useEffect(() => {
    dispatch(setUnreadUserUpdates(!areAllRead()));
  }, [userUpdates, areAllRead, dispatch]);

  if (userUpdates?.data?.length === 0) {
    return (
      <Box alignItems="center" justifyContent="center" padding={4}>
        <Text bold>You&apos;re all caught up!</Text>
      </Box>
    );
  }

  function resetData(): void {
    setPage(1);
  }

  return (
    <Box color="neutral" margin={1} padding={2}>
      <Box color="base" padding={3} rounding="lg">
        <Box alignItems="center" borderBottom="default" direction="row" justifyContent="between">
          <Heading size="sm">Filters</Heading>
          <IconButton
            accessibilityLabel="collapse toggle"
            iconName={showFilters ? "chevron-up" : "chevron-down"}
            onClick={(): void => setShowFilters(!showFilters)}
          />
        </Box>
        {showFilters && (
          <Box direction="column" paddingX={3} paddingY={1} width="50%">
            <MultiselectField
              options={appliedUserList.current.map((u) => {
                return {label: u.name, value: u._id};
              })}
              title="Filter by user"
              value={userUpdatesFilter}
              onChange={(filters: string[]): void => {
                resetData();
                setUserUpdatesFilter(filters);
              }}
            />
          </Box>
        )}
      </Box>

      <Box alignSelf="center" justifyContent="center" padding={2} width="50%">
        <Button disabled={areAllRead()} text="Mark All Read On Page" onClick={markAllRead} />
      </Box>

      <Box maxHeight="100%" scroll>
        <UserUpdates items={userUpdates?.data ?? []} />
      </Box>

      <Box direction="row" paddingY={2} width="100%">
        <Pagination
          page={page}
          setPage={setPage}
          totalPages={Math.ceil((userUpdates?.total ?? 0) / (userUpdates?.limit ?? 1))}
        />
      </Box>
    </Box>
  );
};

import {StaffPhoneNumber} from "@components";
import {GENDEROPTIONS} from "@constants";
import {usePatchUsersByIdMutation, User, userName} from "@store";
import {
  getMissingProfileFields,
  isPersonalPhoneNumberRequired,
  isStaff,
  isVideoPlatformLinkRequired,
} from "@utils";
import {Box, printOnlyDate, TapToEdit, Text, useToast} from "ferns-ui";
import parsePhoneNumberFromString from "libphonenumber-js";
import React, {useEffect, useState} from "react";

import {useReadProfile} from "../hooks";

export const ProfileFields: React.FC<{}> = () => {
  const user = useReadProfile();
  const toast = useToast();

  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);
  const [updateUser] = usePatchUsersByIdMutation();

  // Once the user is loaded, set the current user.
  useEffect(() => {
    if (user) {
      setCurrentUser(user);
    }
  }, [user]);

  if (!user || !currentUser) {
    return null;
  }

  return (
    <Box gap={2} width="100%">
      <TapToEdit editable={false} title="Name" type="text" value={userName(user)} />

      <TapToEdit
        confirmationText="Are you sure you want to save your changes and update the Name Pronunciation section of your profile?"
        confirmationTitle="Save Changes"
        placeholder="How to say your name"
        setValue={(value: string): void => {
          setCurrentUser({...currentUser, namePronunciation: value});
        }}
        title="Pronunciation"
        transform={(value: string): string => {
          return value ? value : "Not set";
        }}
        type="text"
        value={currentUser.namePronunciation}
        withConfirmation
        onSave={async (namePronunciation: string): Promise<void> => {
          await updateUser({
            id: currentUser._id,
            body: {namePronunciation},
          });
        }}
      />

      <TapToEdit editable={false} title="Email" type="email" value={user.email} />

      <TapToEdit
        editable={isStaff(user.type)}
        setValue={(value: string): void => {
          setCurrentUser({...currentUser, birthday: value});
        }}
        title="Birthday"
        transform={(value) => printOnlyDate(value, {defaultValue: "---"})}
        type="date"
        value={currentUser.birthday}
      />

      <TapToEdit
        confirmationText="Are you sure you want to save your changes and update the Gender section of your profile?"
        confirmationTitle="Save Changes"
        options={GENDEROPTIONS.map((gender) => ({label: gender, value: gender}))}
        placeholder="None Selected"
        setValue={(value): void => {
          if (GENDEROPTIONS.includes(value)) {
            setCurrentUser({...currentUser, genderSelfDescribe: "", gender: value});
          } else {
            setCurrentUser({
              ...currentUser,
              gender: "Prefer to self-describe",
              genderSelfDescribe: value,
            });
          }
        }}
        title="Gender"
        transform={(gender): string => {
          // We don't want to surface "Prefer not to say" in their profile direct for their
          // own comfort per clinical
          if (!gender || gender === "Prefer not to say") {
            return "Not set";
          } else if (GENDEROPTIONS.includes(gender)) {
            return currentUser.gender!;
          } else {
            return currentUser.genderSelfDescribe ?? "";
          }
        }}
        type="customSelect"
        value={
          currentUser.gender === "Prefer to self-describe"
            ? currentUser.genderSelfDescribe
            : currentUser.gender
        }
        withConfirmation
        onSave={async (gender): Promise<void> => {
          if (GENDEROPTIONS.includes(gender)) {
            await updateUser({
              id: currentUser._id,
              body: {genderSelfDescribe: "", gender},
            });
          } else {
            await updateUser({
              id: currentUser._id,
              body: {genderSelfDescribe: gender, gender: "Prefer to self-describe"},
            });
          }
        }}
      />
      <TapToEdit
        confirmationText="Are you sure you want to save your changes and update the Pronouns section of your profile?"
        confirmationTitle="Save Changes"
        options={[
          {label: "she/her/hers", value: "she/her/hers"},
          {label: "he/him/his", value: "he/him/his"},
          {label: "they/them/theirs", value: "they/them/theirs"},
          {label: "Prefer not to say", value: "Not set"},
        ]}
        placeholder="None Selected"
        setValue={(value: string): void => {
          setCurrentUser({...currentUser, pronouns: value});
        }}
        title="Pronouns"
        transform={(value: string): string => {
          return value ? value : "Not set";
        }}
        type="customSelect"
        value={currentUser.pronouns || ""}
        withConfirmation
        onSave={async (pronouns: string): Promise<void> => {
          await updateUser({
            id: currentUser._id,
            body: {pronouns},
          });
        }}
      />

      {isStaff(user.type) && (
        <>
          <TapToEdit
            setValue={(value: string): void => {
              setCurrentUser({...currentUser, phoneNumber: value});
            }}
            title={`Personal Phone Number (Hidden to Patients)${!currentUser.phoneNumber && isPersonalPhoneNumberRequired(currentUser) ? "*" : ""}`}
            transform={(phone): string => {
              const phoneNumber = parsePhoneNumberFromString(phone, "US");
              return phoneNumber ? phoneNumber.formatNational() : phone;
            }}
            type="phoneNumber"
            value={currentUser.phoneNumber || ""}
            onSave={async (phoneNumber: string): Promise<void> => {
              await updateUser({
                id: currentUser._id,
                body: {phoneNumber},
              })
                .unwrap()
                .catch((error: any) => {
                  console.error("Error updating user phone number", error);
                  toast.catch(
                    error,
                    error?.data?.title ?? "Unknown error updating user phone number"
                  );
                  setCurrentUser({...currentUser, phoneNumber: currentUser?.phoneNumber});
                });
            }}
          />
          <StaffPhoneNumber userId={currentUser._id} />
        </>
      )}
      {isStaff(user.type) && isVideoPlatformLinkRequired(currentUser) && (
        <TapToEdit
          setValue={(value: string): void => {
            setCurrentUser({...currentUser, videoChatLink: value});
          }}
          title={`${currentUser?.videoChatLink ? "Video Platform Link" : "Video Platform Link*"}`}
          type="url"
          value={currentUser.videoChatLink || ""}
          onSave={async (videoChatLink: string): Promise<void> => {
            await updateUser({
              id: currentUser._id,
              body: {videoChatLink},
            })
              .unwrap()
              .catch((error: any) => {
                console.error("Error updating user video platform link", error);
                toast.catch(
                  error,
                  error?.data?.title ?? "Unknown error updating user video platform link"
                );
                setCurrentUser({...currentUser, videoChatLink: currentUser?.videoChatLink});
              });
          }}
        />
      )}
      {getMissingProfileFields(currentUser).length > 0 && (
        <Box marginTop={2} paddingX={2} width="100%">
          <Text color="error" italic size="sm">
            {`*Missing Profile Information: ${getMissingProfileFields(currentUser).join(", ")}.`}
          </Text>
        </Box>
      )}
    </Box>
  );
};

import Constants from "expo-constants";

export type RootState = any;
export const LOGOUT_ACTION_TYPE = "auth/logout";

export const AUTH_DEBUG = Constants.expoConfig?.extra?.AUTH_DEBUG === "true";
if (AUTH_DEBUG) {
  console.info("AUTH_DEBUG is enabled");
}

// When we use "expo publish", we want to point the API at the prod API. In the future,
// we'll want to point at the staging API, and probably have a development release channel.
if (Constants.expoGoConfig?.debuggerHost?.includes("exp.direct")) {
  console.error(
    "Expo Tunnel is not currently supported for connecting to the API, please use LAN or Local mode."
  );
}

export let baseUrl: string;
export let baseWebsocketsUrl: string;

if (Constants.expoConfig?.extra?.PULL_REQUEST && Constants.expoConfig?.extra?.REVIEW_ID) {
  // For pull request previews
  baseUrl = `https://pr-${Constants.expoConfig.extra.REVIEW_ID}---flourish-stg-3k7lhfrgka-ue.a.run.app`;
  baseWebsocketsUrl = baseUrl;
  console.info(
    `Base URL set to staging preview apiUrl ${baseUrl} for env ${
      Constants.expoConfig?.extra?.APP_ENV ?? "unknown"
    }, websocket to ${baseWebsocketsUrl}`
  );
} else if (Constants.expoConfig?.extra?.BASE_URL) {
  // For prod/staging
  baseUrl = Constants.expoConfig?.extra?.BASE_URL;
  baseWebsocketsUrl = Constants.expoConfig?.extra?.BASE_URL;
  console.info(
    `Base URL set to apiUrl ${baseUrl} for env ${
      Constants.expoConfig?.extra?.APP_ENV ?? "unknown"
    }, websocket to ${baseWebsocketsUrl}`
  );
} else if ((Constants.expoConfig as any)?.hostUri) {
  // For dev simulator/device
  baseUrl = `http://${(Constants.expoConfig as any)?.hostUri?.split(`:`).shift()?.concat(":3000")}`;
  baseWebsocketsUrl = `ws://${(Constants.expoConfig as any)?.hostUri
    ?.split(`:`)
    .shift()
    ?.concat(":3000")}`;
  console.info(
    `Base URL set to hostUri ${baseUrl}, websocket to ${baseWebsocketsUrl}`,
    (Constants.expoConfig as any)?.hostUri
  );
} else if (Constants.experienceUrl && !Constants.experienceUrl.includes("app.flourish.health")) {
  // For dev web
  baseUrl = `http:${Constants.experienceUrl?.split(`:`)[1]?.concat(":3000")}`;
  baseWebsocketsUrl = `ws:${Constants.experienceUrl?.split(`:`)[1]?.concat(":3000")}`;
  console.info(
    `Base URL set to experienceUrl ${baseUrl}, websocket to ${baseWebsocketsUrl}`,
    (Constants.expoConfig as any)?.hostUri
  );
} else {
  // If all else fails...
  baseUrl = "https://api.flourish.health";
  baseWebsocketsUrl = "https://api.flourish.health";
  console.info(`Base URL set to default ${baseUrl}, websocket to ${baseWebsocketsUrl}`);
  console.debug(`Constants.expoConfig: ${JSON.stringify(Constants.expoConfig)}`);
}

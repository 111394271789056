import {useSelectFormInstance} from "@store";
import {Banner} from "ferns-ui";
import React, {FC} from "react";

interface FormInstanceBannersProps {
  formInstanceId: string;
}
export const FormInstanceBanners: FC<FormInstanceBannersProps> = ({formInstanceId}) => {
  const formInstance = useSelectFormInstance(formInstanceId);
  if (!formInstance) {
    return null;
  }
  const {data: formInst} = formInstance;
  const form = formInstance.data?.form;
  return (
    <>
      {Boolean(formInst?.status === "Sent To User") && (
        <Banner
          dismissible={false}
          id="sentToUserWarning"
          status="warning"
          text={`This ${form?.type} has been sent to the patient or family member and is currently visible to them. You can still complete it on their behalf.`}
        />
      )}
      {Boolean(formInst?.userSignatures && formInst.userSignatures.length > 0) && (
        <Banner
          dismissible={false}
          id="signedByUser"
          status="info"
          text={`This ${form?.type} has been signed by a user.`}
        />
      )}
      {Boolean(formInst?.status === "Requires Supervisor Action") && (
        <Banner
          dismissible={false}
          id="requiresSupervisorAction"
          status="warning"
          text={`This ${form?.type} requires a supervisor's review and signature.`}
        />
      )}
    </>
  );
};

import {useNavigation} from "@react-navigation/native";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {
  PopulatedAlertInstance,
  setWorkflowMappingId,
  setWorkflowStaffId,
  useAppDispatch,
  usePatchAlertInstancesByIdMutation,
  WorkflowMappings,
} from "@store";
import {StaffStackParamList} from "@types";
import {IsWeb} from "@utils";
import {Badge, Box, Button, Card, printDate, Text} from "ferns-ui";
import React from "react";

import {LinkButton} from "../LinkButton";

interface AlertUpdateCardParams {
  alert: PopulatedAlertInstance;
  profileUserId: string;
  profileUsersWorkFlow: WorkflowMappings | undefined;
  setShowSideDrawer: (show: boolean) => void;
}

export const AlertCard = ({
  alert,
  profileUserId,
  profileUsersWorkFlow,
  setShowSideDrawer,
}: AlertUpdateCardParams): React.ReactElement => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation<NativeStackScreenProps<StaffStackParamList>["navigation"]>();
  const isArchived = alert.archivedByUsers.includes(profileUserId);
  const [updateAlert] = usePatchAlertInstancesByIdMutation();

  let matchedWorkflowId: string | undefined;
  if (profileUsersWorkFlow?.data) {
    const matchedWorkflows = profileUsersWorkFlow.data.filter(
      (workflow) => workflow.userId === alert.associatedUserId._id
    );
    if (matchedWorkflows.length === 1) {
      matchedWorkflowId = matchedWorkflows[0]._id;
    }
  }

  const updateAlertResolution = async (): Promise<void> => {
    await updateAlert({id: alert._id, body: {resolved: !alert.resolved}});
  };

  const unarchiveAlert = async (): Promise<void> => {
    const updatedAlert = alert.archivedByUsers.filter((user) => user !== profileUserId);
    await updateAlert({id: alert._id, body: {archivedByUsers: updatedAlert}});
  };

  const archiveAlert = async (): Promise<void> => {
    const updatedAlert = [...alert.archivedByUsers, profileUserId];
    await updateAlert({id: alert._id, body: {archivedByUsers: updatedAlert}});
  };

  const redirectToAssociatedUser = (): void => {
    if (!matchedWorkflowId || !IsWeb) {
      navigation.navigate("User", {userId: alert.associatedUserId._id});
    } else {
      dispatch(setWorkflowMappingId(matchedWorkflowId));
      dispatch(setWorkflowStaffId(profileUserId));
      navigation.navigate("Staff", {screen: "Workflows"});
      setShowSideDrawer(false);
    }
  };

  return (
    <Card margin={1} paddingX={3} paddingY={2}>
      <Box alignItems="center" direction="row" justifyContent="between" marginTop={1}>
        <Box marginRight={1}>
          {alert.resolved ? (
            <Badge status="success" value="Resolved" />
          ) : (
            <Badge status="warning" value="Unresolved" />
          )}
        </Box>
        <Box alignItems="center" direction="row" justifyContent="between">
          {alert.resolved && (
            <Box marginRight={2}>
              <Button
                text={isArchived ? "Move to Inbox" : "Archive"}
                variant="muted"
                onClick={isArchived ? unarchiveAlert : archiveAlert}
              />
            </Box>
          )}
          {!isArchived && (
            <Button
              disabled={isArchived}
              iconName={alert.resolved ? undefined : "check"}
              text={alert.resolved ? "Unresolve" : "Resolve"}
              variant={alert.resolved ? "outline" : "primary"}
              onClick={updateAlertResolution}
            />
          )}
        </Box>
      </Box>
      <Box direction="column" justifyContent="between" paddingX={2}>
        <Box alignItems="center" direction="row">
          <Text bold>Patient:{"  "}</Text>
          <LinkButton
            text={
              !matchedWorkflowId
                ? `*${alert?.associatedUserId?.name}`
                : alert?.associatedUserId?.name
            }
            onClick={redirectToAssociatedUser}
          />
        </Box>
        <Box marginBottom={1} marginTop={-1}>
          <Text>
            <Text bold>{alert.alertId.title}: </Text>
            {alert?.alertText}
          </Text>
        </Box>
        {alert.dueDateText && (
          <Text>
            <Text bold>Due: </Text>
            {alert?.dueDateText}
          </Text>
        )}
      </Box>
      <Box
        direction="row"
        justifyContent={alert.resolvedBy?.name ? "between" : "end"}
        paddingX={2}
        wrap
      >
        {alert.resolvedBy?.name && alert.resolved && (
          <Text align="left" bold color="secondaryLight">
            Resolved By: {alert.resolvedBy.name}
          </Text>
        )}
        <Text align="right" bold size={IsWeb ? undefined : "sm"}>
          {printDate(alert.created)}
        </Text>
      </Box>
    </Card>
  );
};

import {useReadProfile} from "@hooks";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  StaffHomeScreenViewProps,
  useCollapseAllRightBar,
  useGetUsersByIdQuery,
  usePatchUsersByIdMutation,
  userIsLocked,
} from "@store";
import {hasFeatureFlag, UserTypes} from "@utils";
import {Box, Button, Text} from "ferns-ui";
import React from "react";

import {ClinicalInfo} from "./ClinicalInfo";
import {ClinicalInfoSelectField} from "./ClinicalInfoSelectField";
import {CurrentPatientWeeklyUpdates} from "./CurrentPatientWeeklyUpdates";
import {FamilyUnitPane} from "./FamilyUnitPane";
import {FormsPane} from "./FormsPane";
import {HealthEvents} from "./HealthEvents";
import {OpenNotesPane} from "./OpenNotesPane";
import {PatientDiagnosis} from "./PatientDiagnosis";
import {PatientSafetyConcerns} from "./PatientSafetyConcerns";
import {PreviousPatientWeeklyUpdates} from "./PreviousPatientWeeklyUpdates";
import {SchedulePane} from "./SchedulePane";
import {ToDoList} from "./ToDoList";
import {UserErrorCard} from "./UserErrorCard";
import {UserInfoCard} from "./UserInfoCard";
import {UserTextField} from "./UserTextField";

export const ClinicalView = ({userId}: StaffHomeScreenViewProps): React.ReactElement | null => {
  const authUser = useReadProfile();
  const {data: user} = useGetUsersByIdQuery(userId ?? skipToken);
  const [updateUser, {isLoading}] = usePatchUsersByIdMutation();
  const collapseAll = useCollapseAllRightBar();
  if (!user) {
    return null;
  }

  function renderLockout(): React.ReactElement | null {
    if (!user || !userIsLocked(user)) {
      return null;
    } else {
      return (
        <UserInfoCard title="User is locked">
          <Box paddingY={4}>
            <Text>The user tried an incorrect password {user.attempts} times.</Text>
          </Box>
          <Box paddingY={4}>
            <Button
              disabled={isLoading}
              loading={isLoading}
              text="Unlock User"
              onClick={async (): Promise<void> => {
                await updateUser({id: user._id, body: {attempts: 0}});
              }}
            />
          </Box>
        </UserInfoCard>
      );
    }
  }

  return (
    <>
      {renderLockout()}
      <UserErrorCard user={user} />

      <UserInfoCard
        collapsable
        collapseExternal={collapseAll}
        infoModal="oneLiner"
        title="One Liner"
      >
        <UserTextField field="oneLiner" userId={user._id} />
      </UserInfoCard>
      <UserInfoCard
        collapsable
        collapseExternal={collapseAll}
        infoModal="clinicalStatus"
        title="Clinical Status"
      >
        <ClinicalInfoSelectField field="clinicalStatus" showUpdateDate userId={user._id} />
      </UserInfoCard>
      {authUser && hasFeatureFlag(authUser, "enableTherapyCadenceIndicator") && (
        <UserInfoCard collapsable collapseExternal={collapseAll} title="Therapy Cadence">
          <ClinicalInfoSelectField field="therapyCadence" showUpdateDate userId={user._id} />
        </UserInfoCard>
      )}
      <UserInfoCard
        collapsable
        collapseExternal={collapseAll}
        infoModal="diagnosis"
        title="Current Diagnosis"
      >
        <PatientDiagnosis userId={user._id} />
      </UserInfoCard>
      <UserInfoCard
        collapsable
        collapseExternal={collapseAll}
        infoModal="safetyConcerns"
        title="Safety Concerns"
      >
        <PatientSafetyConcerns userId={user._id} />
      </UserInfoCard>
      <UserInfoCard
        collapsable
        collapseExternal={collapseAll}
        infoModal="weeklyUpdates"
        title="Current Weekly Updates"
      >
        <CurrentPatientWeeklyUpdates userId={user._id} />
      </UserInfoCard>
      <UserInfoCard
        collapsable
        collapseExternal={collapseAll}
        infoModal="weeklyUpdates"
        initialCollapsed
        title="Previous Weekly Updates"
      >
        <PreviousPatientWeeklyUpdates userId={user._id} />
      </UserInfoCard>
      <UserInfoCard
        collapsable
        collapseExternal={collapseAll}
        infoModal="engagementDetails"
        title="Engagement Details"
      >
        <UserTextField field="engagementDetails" userId={user._id} />
      </UserInfoCard>
      <UserInfoCard collapsable collapseExternal={collapseAll} title="To Do List">
        <ToDoList userId={user._id} />
      </UserInfoCard>
      <UserInfoCard collapsable collapseExternal={collapseAll} title="Health Events">
        <HealthEvents userId={user._id} />
      </UserInfoCard>
      <UserInfoCard collapsable collapseExternal={collapseAll} title="Open Notes">
        <OpenNotesPane userId={user._id} />
      </UserInfoCard>
      <ClinicalInfo collapseAll={collapseAll} userId={user._id} />
      <UserInfoCard
        collapsable
        collapseExternal={collapseAll}
        subtitle="Displayed in Patient App"
        title="Family Unit"
      >
        <FamilyUnitPane userId={user._id} />
      </UserInfoCard>
      <UserInfoCard
        collapsable
        collapseExternal={collapseAll}
        infoModal="carePlan"
        subtitle="Displayed in Patient App"
        title="Care Plan"
      >
        <UserTextField field="carePlan" userId={user._id} />
      </UserInfoCard>
      <UserInfoCard
        collapsable
        collapseExternal={collapseAll}
        infoModal="safetyPlan"
        subtitle="Displayed in Patient App"
        title="Safety Plan"
      >
        <UserTextField field="safetyPlan" userId={user._id} />
      </UserInfoCard>
      <UserInfoCard
        collapsable
        collapseExternal={collapseAll}
        infoModal="thrivePlan"
        subtitle="Displayed in Patient App"
        title="Thrive Plan"
      >
        <UserTextField field="thrivePlan" userId={user._id} />
      </UserInfoCard>
      <UserInfoCard collapsable collapseExternal={collapseAll} title="Templated Notes">
        <FormsPane userId={user._id} />
      </UserInfoCard>
      <UserInfoCard collapsable collapseExternal={collapseAll} title="Schedule">
        <SchedulePane type={UserTypes.Patient} userId={user._id} />
      </UserInfoCard>
    </>
  );
};

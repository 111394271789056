import {skipToken} from "@reduxjs/toolkit/query/react";
import {FetchPopulatedByIdRes, PopulatedScheduleItem, useGetScheduleItemsByIdQuery} from "@store";
import flatten from "lodash/flatten";
import uniq from "lodash/uniq";
import {useMemo} from "react";

import {useGetPopulateUserLookup} from "./useGetPopulateUserLookup";

export function useGetPopulatedScheduleItemById(
  id: string | typeof skipToken
): FetchPopulatedByIdRes<PopulatedScheduleItem> {
  const queryRes = useGetScheduleItemsByIdQuery(id);
  const populateIdList = uniq(
    flatten([
      ...(queryRes?.data?.users ?? []).map((u) => u?.userId),
      ...(queryRes.data?.staff ?? []).map((u) => u?.userId),
    ] as string[])
  );

  const userLookupTableQuery = useGetPopulateUserLookup(populateIdList);
  const userLookupTable = userLookupTableQuery.userLookup;

  const userData = useMemo(() => {
    const item = queryRes.data;

    if (!Object.keys(userLookupTable).length || !item) {
      return undefined;
    }

    const populated = {...queryRes.data} as any as PopulatedScheduleItem;

    populated.users = item.users.map((u) => ({
      userId: userLookupTable?.[u?.userId],
    }));
    populated.staff = item.staff.map((u) => ({
      userId: userLookupTable?.[u?.userId],
    }));

    return populated;
  }, [queryRes.data, userLookupTable]);

  return {
    data: userData,
    isSuccess: queryRes.isSuccess && userLookupTableQuery.isSuccess,
    error: queryRes.error || userLookupTableQuery.error,
    isError: queryRes.isError || userLookupTableQuery.isError,
    isLoading: queryRes.isLoading || userLookupTableQuery.isLoading,
    isFetching: queryRes.isFetching || userLookupTableQuery.isFetching,
  };
}

import {
  ChatBox,
  ClinicalView,
  FitbitView,
  InternalChatConversationItem,
  InternalChatConversationSettingsMenu,
  StaffRightBar,
  UserBadge,
  UserInfoView,
} from "@components";
import {useFetchPopulatedConversations, useReadProfile} from "@hooks";
import {useBottomTabBarHeight} from "@react-navigation/bottom-tabs";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  ConversationSplitPageItem,
  otherConversationUsers,
  useGetAvatarsQuery,
  useGetUserSessionsQuery,
  User,
  userName,
  useUpdateLastReadMutation,
} from "@store";
import {StaffTabScreenProps} from "@types";
import {IsIos, IsMobileDevice, KEYBOARD_VERTICAL_OFFSET, UserTypes} from "@utils";
import {
  Box,
  Heading,
  IconButton,
  ScrollView,
  SelectField,
  Spinner,
  SplitPage,
  Text,
} from "ferns-ui";
import {DateTime} from "luxon";
import React, {ReactElement, useCallback, useEffect, useMemo, useState} from "react";
import {KeyboardAvoidingView, ListRenderItemInfo} from "react-native";

interface InternalChatScreenProps extends StaffTabScreenProps<"InternalChatScreen"> {}

export const InternalChatScreen = ({navigation}: InternalChatScreenProps): ReactElement => {
  const user = useReadProfile();

  // TODO: Fetch the user sessions for the users currently displayed on the screen instead of the
  // first 100. UserSessions is sorted by update, so this is the last 100 users online, but we
  // may still end up with incorrect data.
  const {data: userSessionsData} = useGetUserSessionsQuery({page: 1});

  const [updateLastRead] = useUpdateLastReadMutation();

  // Get all the users non-patient facing conversations
  const {data: conversationsData} = useFetchPopulatedConversations(
    user
      ? {
          "users.userId": user._id,
          type: {$in: ["Staff", "AllStaff", "Multi"]} as any,
        }
      : skipToken
  );

  const conversations = useMemo(() => conversationsData?.data ?? [], [conversationsData?.data]);

  const [selectedConversationId, setSelectedConversationId] = useState<string | undefined>(
    undefined
  );

  const {data: avatarListResponse} = useGetAvatarsQuery({});

  const [filter, setFilter] = useState<string | undefined>(undefined);

  const [showSettingsMenu, setShowSettingsMenu] = useState<boolean>(false);

  // When the screen is blurred, clear the selected conversation so that badge counts come in
  // correctly
  useEffect(() => {
    // clear the selected conversation when switching tabs so that badge counts come in correctly
    // Return the function to unsubscribe from the event so it gets removed on unmount
    return navigation.addListener("blur", () => {
      setSelectedConversationId(undefined);
    });
  }, [navigation]);

  const selectedConversation = conversations?.find((c) => c._id === selectedConversationId);

  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);

  const splitPageTabs = Boolean(selectedUser)
    ? ["Staff Conversations", "Clinical", "User"]
    : ["Staff Conversations"];

  const [showMobileItemList, setShowMobileItemList] = useState<boolean>(true);
  const bottomTabBarHeight = useBottomTabBarHeight();

  // If new messages come in while the conversation is open, update the conversation read map
  useEffect(() => {
    if (
      selectedConversation?._id &&
      selectedConversationId === selectedConversation?._id &&
      user?._id
    ) {
      void updateLastRead({
        conversationId: selectedConversation?._id,
        ownerId: user._id,
        lastReadDateTime: new Date(),
      });
    }
  }, [
    selectedConversation?._id,
    selectedConversation?.lastMessageSentDate,
    user?._id,
    selectedConversationId,
    updateLastRead,
  ]);

  // Set the header options for mobile devices
  useEffect(() => {
    if (IsMobileDevice) {
      navigation.setOptions({
        headerLeft: () => {
          return (
            <IconButton
              accessibilityLabel="deselect mobile item"
              iconName="arrow-left"
              onClick={(): void => {
                setShowMobileItemList(true);
                setSelectedConversationId(undefined);
                // reset navigation to default options
                navigation.reset({stale: true, routes: [{name: "InternalChatScreen"}]});
              }}
            />
          );
        },
        headerTitle: () => {
          return (
            <Box>
              <Text>{selectedConversation?.name}</Text>
            </Box>
          );
        },
      });
    }
  }, [selectedConversation, navigation]);

  const renderListViewItem = useCallback(
    (itemInfo: ListRenderItemInfo<ConversationSplitPageItem>): ReactElement => (
      <InternalChatConversationItem
        itemInfo={itemInfo}
        selectedConversation={selectedConversationId}
      />
    ),
    [selectedConversationId]
  );

  const listViewConversationData: ConversationSplitPageItem[] = useMemo(() => {
    if (!user?._id || !conversations?.length || !userSessionsData) {
      return [];
    }

    const allConversations = conversations?.filter((c) =>
      c.users.find((conversationUser) => conversationUser.userId?._id !== user?._id)
    );

    const filteredConversations = filter
      ? allConversations.filter((c) => {
          if (filter === UserTypes.Patient) {
            return c.type === "Multi" && c.referencedUsers.length > 0;
          } else if (filter === "Multi") {
            return c.type === "Multi" && c.referencedUsers.length === 0;
          } else {
            return c.type === filter;
          }
        })
      : allConversations;

    // Map over all the logged in user's 1:1 conversations with other staff members and return
    // expected format for split page
    const convoItems = filteredConversations?.map((c) => {
      let showOnlineWeb = false;
      let showOnlineMobile = false;
      let doNotDisturb = false;
      let avatar;
      if (c.type === "Staff") {
        const otherUser = otherConversationUsers(c, user._id)?.[0];

        avatar = avatarListResponse?.data?.find((a) => otherUser?._id === a.ownerId);

        const userSession = userSessionsData.data?.find((us) => otherUser?._id === us.ownerId);

        if (
          userSession?.lastOnlineWeb &&
          DateTime.now().diff(DateTime.fromISO(userSession.lastOnlineWeb), "minutes").minutes < 1
        ) {
          showOnlineWeb = true;
        }
        if (
          userSession?.lastOnlineMobile &&
          DateTime.now().diff(DateTime.fromISO(userSession.lastOnlineMobile), "minutes").minutes < 1
        ) {
          showOnlineMobile = true;
        }
        doNotDisturb = Boolean(userSession?.doNotDisturb);
      }
      return {
        item: {
          conversation: c,
          webPresence: {showOnlineWeb, showOnlineMobile, doNotDisturb},
          avatar,
        },
        id: c._id,
      };
    });

    // Sort by lastMessageSentDate so conversations with new messages move to top of list
    convoItems?.sort((a, b) => {
      if (!a.item.conversation.lastMessageSentDate) {
        return 1;
      }
      if (!b.item.conversation.lastMessageSentDate) {
        return -1;
      }
      return (
        new Date(b.item.conversation.lastMessageSentDate).getTime() -
        new Date(a.item.conversation.lastMessageSentDate).getTime()
      );
    });

    return convoItems;
  }, [user?._id, conversations, userSessionsData, filter, avatarListResponse?.data]);

  if (!listViewConversationData || !user) {
    return (
      <Box alignItems="center" height="100%" justifyContent="center" width="100%">
        <Spinner />
      </Box>
    );
  }

  const renderContent = (): ReactElement => {
    const conversationParticipants = selectedConversation?.users?.map((u) =>
      u.userId ? userName(u?.userId) : ""
    );

    // Regex to join users with "X, Y, and Z" format
    const conversationParticipantsJoinedString = conversationParticipants
      ?.join(", ")
      .replace(/, ([^,]*)$/, " and $1");

    const referencedUsers = selectedConversation?.referencedUsers?.map((u) => userName(u?.userId));
    // Regex to join users with "X, Y, and Z" format
    const referencedUsersJoinedString = referencedUsers
      ?.join(", ")
      .replace(/, ([^,]*)$/, " and $1");

    let doNotDisturb = false;
    if (selectedConversation?.type === "Staff") {
      const otherUser = otherConversationUsers(selectedConversation, user._id)?.[0];
      const userSession = userSessionsData?.data?.find((us) => otherUser?._id === us.ownerId);
      doNotDisturb = Boolean(userSession?.doNotDisturb);
    }

    return selectedConversationId ? (
      <KeyboardAvoidingView
        behavior={IsIos ? "padding" : "height"}
        keyboardVerticalOffset={KEYBOARD_VERTICAL_OFFSET}
        style={{flex: 1}}
      >
        <Box color="base" height="100%" padding={2} width="100%">
          {/* zIndex of settings menu must be higher than the messages container or chat messages cover the menu options; requires the parent of the settings menu to be higher as well due to inheriting hierarchy */}
          <Box borderBottom="default" zIndex={10000}>
            <Box alignItems="center" direction="row" style={{position: "relative"}} zIndex={10000}>
              <Box padding={2} width="75%">
                <Heading size="sm">
                  {selectedConversation?.name ?? conversationParticipantsJoinedString}
                </Heading>
                {doNotDisturb && <UserBadge status="warning" title="Do Not Disturb" />}
              </Box>
              <Box flex="grow" />
              <Box
                alignItems="center"
                direction="row"
                gap={2}
                height="100%"
                justifyContent="end"
                marginRight={2}
                paddingY={3}
              >
                {/** Only show edit button for group conversations */}
                {Boolean(selectedConversation?.type === "Multi") && (
                  <IconButton
                    accessibilityLabel="Edit Conversation"
                    iconName="pencil"
                    tooltipText="Edit Conversation"
                    variant="secondary"
                    onClick={(): void => {
                      navigation.navigate("ManageConversationsScreen", {
                        conversationId: selectedConversationId,
                        currentUser: user,
                      });
                    }}
                  />
                )}
                <IconButton
                  accessibilityLabel="Conversation Settings Menu"
                  iconName="gear"
                  tooltipText="Settings"
                  variant="secondary"
                  onClick={() => {
                    setShowSettingsMenu(!showSettingsMenu);
                  }}
                />
                <InternalChatConversationSettingsMenu
                  conversation={selectedConversation}
                  setShowSettingsMenu={setShowSettingsMenu}
                  showSettingsMenu={showSettingsMenu}
                  onLeaveConversation={() => setSelectedConversationId(undefined)}
                />
              </Box>
            </Box>
            {Boolean(referencedUsersJoinedString) && (
              <Box direction="row" padding={2}>
                <Text bold>Referenced Users: </Text>
                <Text truncate>{referencedUsersJoinedString}</Text>
              </Box>
            )}
          </Box>

          {selectedConversationId && (
            <ChatBox
              key={selectedConversationId}
              allowDelete
              allowMentions
              conversationId={selectedConversationId}
              renderUsernameOnMessage
            />
          )}
        </Box>
      </KeyboardAvoidingView>
    ) : (
      // if null is returned the tabs for the split page will not work
      <Box />
    );
  };

  const renderListViewHeader = (): ReactElement => {
    return (
      <Box
        alignItems="center"
        color="base"
        direction="row"
        justifyContent="between"
        marginBottom={2}
        padding={2}
        width="100%"
      >
        <Box direction="row" maxWidth="100%" width="100%">
          <Box flex="grow" marginRight={2}>
            <SelectField
              options={[
                {label: "Direct Messages", value: "Staff"},
                {label: "Group", value: "Multi"},
                {label: "Patient", value: "Patient"},
                {label: "All Team", value: "AllStaff"},
              ]}
              placeholder="All"
              requireValue={false}
              title="Filter Conversations by Type"
              value={filter}
              onChange={setFilter}
            />
          </Box>
          <Box justifyContent="center" marginTop={5}>
            <IconButton
              accessibilityLabel="new conversation"
              iconName="plus"
              tooltipText="Create a new conversation"
              variant="muted"
              onClick={(): void => {
                navigation.navigate("ManageConversationsScreen", {
                  conversationId: undefined,
                  currentUser: user,
                });
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <SplitPage
      bottomNavBarHeight={bottomTabBarHeight || 0}
      listViewData={listViewConversationData}
      renderListViewHeader={renderListViewHeader}
      renderListViewItem={renderListViewItem}
      selectLimit={2}
      showItemList={showMobileItemList}
      tabs={splitPageTabs}
      onSelectionChange={async (
        itemInfo: ListRenderItemInfo<ConversationSplitPageItem>
      ): Promise<void> => {
        if (itemInfo) {
          if (IsMobileDevice) {
            setShowMobileItemList(false);
          }
          const conversationData = itemInfo?.item;
          setSelectedConversationId(conversationData.id);
          setShowSettingsMenu(false);

          if (itemInfo.item.item?.conversation.referencedUsers?.[0]?.userId) {
            setSelectedUser(itemInfo.item.item.conversation.referencedUsers[0].userId);
          } else {
            setSelectedUser(undefined);
          }

          await updateLastRead({
            conversationId: conversationData.id,
            ownerId: user!._id,
            lastReadDateTime: new Date(),
          });
        }
      }}
    >
      {renderContent()}
      {Boolean(IsMobileDevice) && Boolean(selectedUser) && (
        <>
          <ScrollView>
            <SelectedUserCard
              navigation={navigation}
              referencedUserOptions={selectedConversation?.referencedUsers ?? []}
              setSelectedUser={setSelectedUser}
              user={selectedUser}
            />
            <ClinicalView userId={selectedUser?._id} />
          </ScrollView>
          <ScrollView>
            <SelectedUserCard
              navigation={navigation}
              referencedUserOptions={selectedConversation?.referencedUsers ?? []}
              setSelectedUser={setSelectedUser}
              user={selectedUser}
            />
            <UserInfoView userId={selectedUser?._id} onRemoveUser={(): void => {}} />
          </ScrollView>
          <ScrollView>
            <SelectedUserCard
              navigation={navigation}
              referencedUserOptions={selectedConversation?.referencedUsers ?? []}
              setSelectedUser={setSelectedUser}
              user={selectedUser}
            />
            <FitbitView userId={selectedUser?._id} />
          </ScrollView>
        </>
      )}
      {Boolean(!IsMobileDevice) && Boolean(selectedUser) && (
        <>
          <Box marginLeft={2}>
            <SelectedUserCard
              navigation={navigation}
              referencedUserOptions={selectedConversation?.referencedUsers ?? []}
              setSelectedUser={setSelectedUser}
              user={selectedUser}
            />
          </Box>
          <StaffRightBar userId={selectedUser?._id} />
        </>
      )}
    </SplitPage>
  );
};

const SelectedUserCard = ({
  user,
  referencedUserOptions,
  setSelectedUser,
}: {
  user?: User;
  navigation: any;
  referencedUserOptions: {userId?: User}[];
  setSelectedUser: React.Dispatch<React.SetStateAction<User | undefined>>;
}): ReactElement | null => {
  if (!user) {
    return null;
  }
  return (
    <Box color="base" direction="column" marginBottom={3} padding={2} rounding="md" width="100%">
      <Heading size="sm">Select A User</Heading>
      <Box direction="row" maxWidth="100%" padding={2} width="100%">
        <Box flex="grow">
          <SelectField
            options={
              (referencedUserOptions?.map((u) => ({
                label: `${userName(u?.userId)} (${u?.userId?.type}${
                  u?.userId?.testUser ? "- Test User" : ""
                })`,
                value: u?.userId?._id,
              })) ?? []) as {label: string; value: string}[]
            }
            requireValue
            value={user?._id}
            onChange={(value: string): void => {
              const selectedU =
                referencedUserOptions?.find((u) => u?.userId?._id === value) || null;
              if (selectedU) setSelectedUser(selectedU?.userId);
            }}
          />
        </Box>
      </Box>

      <Box alignItems="center" direction="row" justifyContent="between" marginBottom={2} />
    </Box>
  );
};

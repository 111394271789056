import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {FormInstanceWithForm, FormValidator, isReadOnly, RootState, useAppSelector} from "@store";
import {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

export interface FormInstanceState {
  data: FormInstanceWithForm | undefined;
  validationErrors: string[] | null;
  readOnly: boolean;
  linkItemToggle: boolean;
  isSupervisor: boolean;
  currentFocusedAnswer:
    | {
        answerId: string;
        isEdited: boolean;
      }
    | undefined;
}

interface FormInstanceStore {
  [instanceId: string]: FormInstanceState;
}

const initialState: FormInstanceStore = {};

const formInstanceSlice = createSlice({
  name: "formInstanceStore",
  initialState,
  reducers: {
    updateFormInstanceState: (
      state,
      action: PayloadAction<{instanceId: string; data: Partial<FormInstanceStore[string]>}>
    ) => {
      state[action.payload.instanceId] = {
        ...state[action.payload.instanceId],
        ...action.payload.data,
      };
    },
    clearFormInstanceData: (state, action: PayloadAction<string>) => {
      delete state[action.payload];
    },
    resetFormInstanceStore: () => initialState,
  },
});

export const {clearFormInstanceData, updateFormInstanceState, resetFormInstanceStore} =
  formInstanceSlice.actions;

export const formInstanceReducer = formInstanceSlice.reducer;

export const useUpdateFormInstanceState = (
  instanceId: string
): ((data: Partial<FormInstanceState>) => void) => {
  const dispatch = useDispatch();
  const currentFormInstanceState = useSelector(
    (state: RootState) => state.formInstanceStore[instanceId]
  );

  return useCallback(
    (data: Partial<FormInstanceState>) => {
      if (!currentFormInstanceState || currentFormInstanceState.readOnly) return;
      const readOnly =
        data.readOnly || // if the call includes explicit update to readOnly
        isReadOnly({
          formInstance: data?.data,
          isSupervisor: data.isSupervisor ?? currentFormInstanceState.isSupervisor ?? false,
          currentFormInstanceState,
        });

      let validationErrors = currentFormInstanceState.validationErrors;
      // if the call includes update of form instance data then re validate the form
      if (data.data) {
        validationErrors = FormValidator.validateFormInstance(
          data.data,
          data.isSupervisor ?? currentFormInstanceState.isSupervisor ?? false
        );
      }

      const linkItemToggle = data.linkItemToggle ?? Boolean(data.data?.scheduleItemId);

      const updatedData: Partial<FormInstanceState> = {
        ...data,
        readOnly,
        validationErrors,
        linkItemToggle,
      };

      dispatch(updateFormInstanceState({instanceId, data: updatedData}));
    },
    [dispatch, instanceId, currentFormInstanceState]
  );
};

interface UpdateFocusedAnswerParams {
  currentFocusedAnswer:
    | {
        answerId: string;
        isEdited: boolean;
      }
    | undefined;
  currentStateAnswers: FormInstanceWithForm["answers"];
  incomingAnswers: FormInstanceWithForm["answers"];
}

interface UpdateFocusedAnswerResult {
  updatedAnswers: FormInstanceWithForm["answers"];
  updatedFocusedAnswer?: {
    answerId: string;
    isEdited: boolean;
  };
}

export const updateFocusedAnswer = ({
  currentFocusedAnswer,
  currentStateAnswers,
  incomingAnswers,
}: UpdateFocusedAnswerParams): UpdateFocusedAnswerResult => {
  const updatedAnswers = [...incomingAnswers];
  // Check if there is a currentFocusedAnswer (a answer that is currently being edited)
  // Find the index of the current focused answer in both incoming answers and state answers
  // Replace the incoming answer with the one from the current state if found
  // Ensure that the incoming answer is replaced only if the current answer is non-empty
  if (currentFocusedAnswer?.answerId && currentFocusedAnswer.isEdited) {
    const incomingAnswerIndex = updatedAnswers.findIndex(
      (answer) => answer._id === currentFocusedAnswer.answerId
    );
    const currentAnswer = currentStateAnswers.find(
      (answer) => answer._id === currentFocusedAnswer.answerId
    );

    if (incomingAnswerIndex !== -1 && currentAnswer) {
      const incomingAnswer = updatedAnswers[incomingAnswerIndex];
      if (
        incomingAnswer &&
        incomingAnswer._id === currentAnswer._id &&
        currentAnswer.answers[0] !== ""
      ) {
        updatedAnswers[incomingAnswerIndex] = currentAnswer;
      } else {
        // If the current state is empty,then allow the incoming answer to override it and reset
        // the isEdited flag
        return {
          updatedAnswers,
          updatedFocusedAnswer: {
            ...currentFocusedAnswer,
            isEdited: false,
          },
        };
      }
    }
  }

  return {updatedAnswers};
};

interface UseSetFormInstanceStateParams {
  formInstanceData: FormInstanceWithForm | undefined;
  routeReadOnly?: boolean;
  isSupervisor?: boolean;
}

export const useSetFormInstanceState = ({
  formInstanceData,
  routeReadOnly,
  isSupervisor = false,
}: UseSetFormInstanceStateParams): void => {
  const dispatch = useDispatch();
  const currentFormInstanceState = useSelector(
    (state: RootState) => state.formInstanceStore[formInstanceData?._id ?? ""]
  );
  const copiedFormInstanceData = JSON.parse(JSON.stringify(formInstanceData ?? {}));

  // only update the state if the formInstanceData is defined
  //  preserve the current state if the formInstanceData is undefined
  // and alert the user their work is not saved
  useEffect(() => {
    if (!formInstanceData) return;
    const formData: Partial<FormInstanceState> = {};

    // Check if there is a currentFocusedAnswer (a answer that is currently being edited)
    const {currentFocusedAnswer} = currentFormInstanceState || {};
    const {updatedAnswers, updatedFocusedAnswer} = updateFocusedAnswer({
      currentFocusedAnswer,
      currentStateAnswers: currentFormInstanceState?.data?.answers || [],
      incomingAnswers: formInstanceData?.answers || [],
    });
    copiedFormInstanceData.answers = updatedAnswers;

    formData.currentFocusedAnswer = updatedFocusedAnswer || currentFocusedAnswer;
    formData.data = copiedFormInstanceData;
    formData.isSupervisor = isSupervisor;
    formData.readOnly = isReadOnly({
      formInstance: formInstanceData,
      routeReadOnly,
      isSupervisor,
      currentFormInstanceState,
    });
    formData.validationErrors = FormValidator.validateFormInstance(formInstanceData, isSupervisor);
    formData.linkItemToggle = Boolean(
      formInstanceData.scheduleItemId || currentFormInstanceState?.linkItemToggle
    );

    dispatch(
      updateFormInstanceState({
        instanceId: formInstanceData._id,
        data: formData,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, routeReadOnly, isSupervisor, formInstanceData]);
};

export const useUpdateFormInstanceAnswerState = (
  instanceId: string
): ((updatedAnswer: Partial<FormInstanceWithForm["answers"][number]>) => void) => {
  const dispatch = useDispatch();
  const formInstance = useSelector((state: RootState) => state.formInstanceStore[instanceId]);

  return useCallback(
    (updatedAnswer: Partial<FormInstanceWithForm["answers"][number]>) => {
      if (!formInstance || !formInstance.data || formInstance.readOnly) return;

      const updatedAnswers = formInstance.data.answers.map((answer: any) => {
        if (answer._id === updatedAnswer._id) {
          return {
            ...answer,
            ...updatedAnswer,
          };
        }
        return answer;
      });
      const updatedData = {
        ...formInstance.data,
        answers: updatedAnswers,
      };
      const validationErrors = FormValidator.validateFormInstance(
        updatedData,
        formInstance.isSupervisor
      );

      dispatch(
        updateFormInstanceState({
          instanceId,
          data: {
            ...formInstance,
            validationErrors,
            data: updatedData,
            currentFocusedAnswer: {
              answerId: updatedAnswer._id!,
              isEdited: true,
            },
          },
        })
      );
    },
    [dispatch, formInstance, instanceId]
  );
};

export const useSelectFormInstance = (instanceId: string): FormInstanceState | undefined => {
  return useAppSelector((state: RootState) => state.formInstanceStore[instanceId]);
};

import {useGetPopulatedScheduleItemById, useReadProfile} from "@hooks";
import {skipToken} from "@reduxjs/toolkit/query";
import {
  Form,
  PatchFormInstancesByIdArgs,
  useGetFormInstancesQuery,
  usePatchFormInstancesByIdMutation,
} from "@store";
import {Box, Heading, Modal, printDate, printTime, Text} from "ferns-ui";
import React, {useEffect, useState} from "react";
import {Dimensions} from "react-native";

interface SurveyCheckBoxProps {
  onChange: (value: {value: boolean}) => void;
  checked?: boolean;
  label?: string;
}

export const SurveyCheckBox = ({
  checked,
  label,
  onChange,
}: SurveyCheckBoxProps): React.ReactElement => {
  return (
    <Box
      accessibilityHint="Survey checkbox"
      accessibilityLabel="Checkbox"
      alignItems="center"
      display="flex"
      justifyContent="center"
      maxHeight={60}
      width="100%"
      onClick={() => onChange({value: !checked})}
    >
      <Box
        alignItems="center"
        border="dark"
        color="base"
        height={16}
        justifyContent="center"
        rounding="circle"
        width={16}
      >
        {Boolean(checked) && (
          <Box
            alignItems="center"
            direction="column"
            display="flex"
            height={14}
            justifyContent="center"
            rounding="circle"
            width={14}
          />
        )}
      </Box>
      <Box direction="column" display="flex" marginTop={1}>
        <Text align="center">{label}</Text>
      </Box>
    </Box>
  );
};

interface SessionFeedbackModalProps {}

interface SurveyAnswers {
  prompt: string;
  questionId: string;
  answers: string[];
  score: number;
}

export const SessionFeedbackModal = ({}: SessionFeedbackModalProps): React.ReactElement | null => {
  const profile = useReadProfile();
  const [updateFormInstance, {isLoading}] = usePatchFormInstancesByIdMutation();

  const {data: feedbackSurveys} = useGetFormInstancesQuery(
    profile ? {userId: profile._id, type: "Survey"} : skipToken
  );
  const survey = feedbackSurveys?.data?.[0];

  const form = survey?.form as Form | undefined;

  // Pull all schedule items for the logged in staff member and selected user that are within +/- 7
  // days with unknown attendance status
  const {data: scheduleItem} = useGetPopulatedScheduleItemById(survey?.scheduleItemId ?? skipToken);

  const [answers, setAnswers] = useState<SurveyAnswers[]>([]);

  // set answers in a use effect after the form loads
  useEffect(() => {
    setAnswers(
      form?.questions?.map((q) => {
        return {
          prompt: q.prompt,
          questionId: q._id!,
          answers: [""],
          score: 0,
        };
      }) ?? []
    );
  }, [form?.questions]);

  const windowHeight = Dimensions.get("window").height;

  const renderSurvey = (): React.ReactElement | null => (
    <Box>
      {form?.questions?.map((q, i) => {
        return (
          <Box key={q._id} paddingY={1}>
            <Text bold>
              {`${i + 1}) `}
              {q?.prompt}
            </Text>

            <Box direction="row" flex="grow" justifyContent="between" paddingY={1} width="100%">
              {q?.options?.map((o, index) => (
                <Box key={o.label} direction="column" width="20%">
                  <SurveyCheckBox
                    checked={String(answers[i].answers[0]) === String(o.score)}
                    label={o.label}
                    onChange={async (): Promise<void> => {
                      const newAnswers = [...answers];
                      newAnswers[i].answers = [String(index)];
                      newAnswers[i].score = index;
                      setAnswers(newAnswers);
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        );
      })}
    </Box>
  );

  const saveForm = async (): Promise<void> => {
    if (!survey) {
      console.warn("No survey found to save");
      return;
    }

    const body: PatchFormInstancesByIdArgs = {
      id: survey?._id,
      body: {
        answers,
        status: "Completed",
      },
    };
    await updateFormInstance(body);
  };

  if (!scheduleItem || !survey) {
    return null;
  }

  return (
    <Modal
      primaryButtonDisabled={isLoading}
      primaryButtonOnClick={async (): Promise<void> => {
        await saveForm();
      }}
      primaryButtonText="Submit"
      secondaryButtonOnClick={async (): Promise<void> => {
        await updateFormInstance({id: survey?._id, body: {status: "Dismissed"}});
      }}
      secondaryButtonText="Decline"
      size="lg"
      visible={Boolean(feedbackSurveys?.data?.length)}
      onDismiss={async (): Promise<void> => {}}
    >
      <Box alignSelf="center" maxHeight={windowHeight * (3 / 4)} maxWidth="100%" width="100%">
        <Box>
          <Heading align="center" size="sm">
            Feedback Survey
          </Heading>
          <Box marginTop={1} paddingY={1}>
            <Text bold>
              {scheduleItem?.title} with {scheduleItem?.staff?.[0]?.userId?.name} on{" "}
              {printDate(scheduleItem.startDatetime, {
                timezone: profile?.timezone,
                showTimezone: false,
              })}{" "}
              at{" "}
              {printTime(scheduleItem.startDatetime, {
                timezone: profile?.timezone ?? "America/New_York",
                showTimezone: true,
              })}
            </Text>
          </Box>
        </Box>
        <Box paddingY={1}>
          <Text>
            Your participation in this survey is entirely optional. Your responses will be shared
            with your clinical team to help ensure your care is meeting your needs.
          </Text>
        </Box>
        <Box paddingY={1} scroll>
          {renderSurvey()}
        </Box>
      </Box>
    </Modal>
  );
};

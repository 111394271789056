import type {PopulatedUser, User, UserType} from "@store";

export enum UserTypes {
  FamilyMember = "FamilyMember",
  Patient = "Patient",
  Staff = "Staff",
}

export const UserTypeLabels: Record<UserType, {value: UserType; label: string}> = {
  [UserTypes.FamilyMember]: {value: UserTypes.FamilyMember, label: "Family Member"},
  Member: {value: UserTypes.Patient, label: "Patient"},
  [UserTypes.Patient]: {value: UserTypes.Patient, label: "Patient"},
  [UserTypes.Staff]: {value: UserTypes.Staff, label: "Staff"},
};

export function isFamilyMember(type?: UserType): type is UserTypes.FamilyMember {
  if (!type) {
    return false;
  }
  return type === UserTypes.FamilyMember;
}

export function isGuideOnly(user: User | PopulatedUser): boolean {
  return Boolean(user?.guideOnly);
}

export function isPatient(type?: UserType): type is UserTypes.Patient {
  if (!type) {
    return false;
  }
  return type === UserTypes.Patient;
}

export function isPatientOrFamilyMember(
  type?: UserType
): type is UserTypes.Patient | UserTypes.FamilyMember {
  if (!type) {
    return false;
  }
  return type === UserTypes.Patient || type === UserTypes.FamilyMember;
}

export function isStaff(type?: UserType): type is UserTypes.Staff {
  if (!type) {
    return false;
  }
  return type === UserTypes.Staff;
}

export function isSuperUser(user?: User | PopulatedUser): boolean {
  return Boolean(user?.staffRoles?.SuperUser);
}

export function isTestUser(user?: User | PopulatedUser): boolean {
  return Boolean(user?.testUser);
}

export function isSupervisor(user?: User | PopulatedUser): boolean {
  if (!user) {
    return false;
  }
  return Boolean(
    user?.staffRoles?.FamilyGuideSupervisor ||
      user?.staffRoles?.PatientGuideSupervisor ||
      user?.staffRoles?.TherapistSupervisor ||
      user?.staffRoles?.MedicalDirector
  );
}

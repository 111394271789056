import {ConsentFormType} from "@store";

export interface ConsentForm {
  consentFormId: number;
  title: string;
  text: string;
  consentFormType: ConsentFormType;
}

// The forms will be hard coded here for now and someday will be loaded from the database if
// needed. The user will see the highest numbered consentFormId to agree to.
// The lower numbers are considered superseded.
export const ConsentFormContents: ConsentForm[] = [
  {
    consentFormId: 25,
    title: "Patient Consent, HIPAA, and Privacy Form",
    consentFormType: "consent",
    text: [
      "## Early access program:",
      "* Guide support only",
      "* 3 months duration",
      "* Free to participants",
      "",
      "# Informed Consent Form",
      "",
      "## Invitation to be part of our early access program",
      "You are invited to participate in our early access program. This form has information to help you decide whether or not you wish to participate—please review it carefully. Your participation is completely voluntary and you can stop at any time.",
      "Please ask the Flourish Health staff any questions you have about the program or about this form before deciding to participate.",
      "",
      "## Introduction and Purpose of the Program",
      "The purpose of this program is to find out how participants engage with guide support and how participants use our app.",
      "",
      "## Eligibility to Participate",
      "You are eligible to participate in this program if:",
      "1. You live in the U.S.",
      "2. You are between 13-26 years old",
      "3. You have been diagnosed with early psychosis, schizophrenia, schizoaffective disorder or bipolar type I",
      "",
      "To determine if you are eligible, we will do a phone screening call with you or with your family if you prefer. This will give us a chance to answer any questions you have and to make sure this is a good fit.",
      "",
      "## Description of Program Expectations",
      "If you agree to participate, you will be asked to:",
      "1. Download our app on your phone",
      "2. Offered the chance to text or call with your one-on-one guide through the app",
      "3. Offered a free Fitbit",
      "",
      "## Expected Time or Duration of Participation: ",
      "Your participation will last for three (3) months. Unfortunately we cannot offer this program longer.",
      "",
      "## Risks or Discomforts",
      "While participating in this program you may experience feelings of awkwardness, embarrassment or emotional discomfort. We hope that our Guides can follow your lead on what you would like to talk about and will not ask you to talk about things you don’t want to talk about.",
      "",
      "There may be risks or discomforts that are currently unforeseeable at this time. We will tell you about any significant new information we learn that may relate to your willingness to continue participating in this program.",
      "",
      "## Benefits to You and to Others",
      "We hope that this three months support program can help you achieve some of your goals. We also hope that your participation in our program can help us improve how we support future participants.",
      "",
      "## Costs and Compensation",
      "You will not have any costs from participating in this program.",
      "",
      "You will not be compensated for participating in this program aside from free mentorship and support.",
      "",
      "## Your Rights as a Participant",
      "Participating in this program is completely voluntary. You may choose not to take part in the program or to stop participating at any time, for any reason, without penalty or negative consequences.",
      "",
      "## Questions",
      "If you have any questions, please contact info@flourish.health or you can text or call (612) 868-7253.",
      "",
      "## Your Consent",
      "",
      "By signing this document, you are agreeing to participate in this program.",
      "I am 18 years of age or over and agree to take part in this program.",
      "",
      "# Notice of Privacy Practices",
      "",
      "Your Information. Your Rights. Our Responsibilities.",
      "",
      'Flourish Health, P.C., Flourish Health, L.L.C, and Flourish Health Inc.’s wholly owned subsidiaries (collectively “Flourish Health", “we”, “us”, “our”) make up an organized health care arrangement.  Flourish Health is a clinically integrated care setting in which our patients receive health care from more than one health care provider. In addition, we are an organized health care system that jointly participates in numerous activities including quality assessment and improvement activities.  The list of entities making up such organized health care arrangements may be updated from time to time.  Flourish Health respects and is committed to protecting the privacy of your medical information. In performing its services, Flourish Health will receive, create, and disclose your protected health information (‘PHI’). Flourish Health is required by law to maintain the privacy and security of your PHI and to provide you with notice of our legal duties and privacy practices with respect to your PHI. For information about our collection, use, and disclosure of personal information other than PHI, please see our privacy policy at [[link to Flourish Privacy Policy](https://www.notion.so/Privacy-Policy-Draft-d948050bdab24d2eb1909d905802e525)] and the privacy policies posted on the websites of the affiliates listed above.  This notice describes how medical information about you may be used and disclosed and how you can get access to this information.  Please review it carefully.',
      "",
      "## **YOUR RIGHTS**",
      "",
      "When it comes to your health information, you have certain rights.",
      "",
      "This section explains your rights and some of our responsibilities to help you.",
      "",
      "Get an electronic or paper copy of your medical record:",
      "",
      "* You can ask to see or get an electronic or paper copy of your medical record and other health information we have about you. Ask us how to do this.",
      "",
      "* We will provide a copy or a summary of your health information, usually within 30 days of your request. We may charge a reasonable, cost-based fee.",
      "",
      "* Ask us to correct your medical record",
      "",
      "* You can ask us to correct health information about you that you think is incorrect or incomplete. Ask us how to do this.",
      "",
      "* We may say “no” to your request, but we’ll tell you why in writing within 60 days.",
      "",
      "Request confidential communications:",
      "",
      "* You can ask us to contact you in a specific way (for example, home or office phone) or to send mail to a different address.",
      "",
      "* We will say “yes” to all reasonable requests.",

      "",
      "Ask us to limit what we use or share:",
      "",
      "* You can ask us not to use or share certain health information for treatment or our operations.",
      "",
      "* We are not required to agree to your request, and we may say “no” if it would affect your care.",
      "",
      "Get a list of those with whom we’ve shared information:",
      "",
      "* You can ask for a list (accounting) of the times we’ve shared your health information for six years prior to the date you ask, who we shared it with, and why.",
      "",
      "* We will include all the disclosures except for those about treatment, health care operations, and certain other disclosures (such as any you asked us to make). We’ll provide one accounting a year for free but will charge a reasonable, cost-based fee if you ask for another one within 12 months.",
      "",
      "Get a copy of this privacy notice:",
      "",
      "* You can ask for a paper copy of this notice at any time, even if you have agreed to receive the notice electronically. We will provide you with a paper copy promptly.",
      "",
      "Choose someone to act for you:",
      "",
      "* If you have given someone medical power of attorney or if someone is your legal guardian, that person can exercise your rights and make choices about your health information.",
      "",
      "* We will make sure the person has this authority and can act for you before we take any action.",
      "",
      "File a complaint if you feel your rights are violated:",
      "",
      "* You can complain if you feel we have violated your rights by contacting us using the information on the back page.",
      "",
      "* You can file a complaint with the U.S. Department of Health and Human Services Office for Civil Rights by sending a letter to 200 Independence Avenue, S.W., Washington, D.C. 20201, calling 1-877-696- 6775, or visiting [https://www.hhs.gov/hipaa/filing-a-complaint/what-to-expect/index.html](https://www.hhs.gov/hipaa/filing-a-complaint/what-to-expect/index.html).",
      "",
      "* We will not retaliate against you for filing a complaint.",
      "",
      "## **YOUR CHOICES**",
      "",
      "For certain health information, you can tell us your choices about what we share. If you have a clear preference for how we share your information in the situations described below, talk to us. Tell us what you want us to do, and we will follow your instructions.",

      "",
      "In these cases, you have both the right and choice to tell us to:",
      "",
      "* Share information with your family, close friends, or others involved in your care",
      "",
      "* Share information in a disaster relief situation",
      "",
      "* Include your information in a hospital directory",
      "",
      "If you are not able to tell us your preference, for example if you are unconscious, we may go ahead and share your information if we believe it is in your best interest. We may also share your information when needed to lessen a serious and imminent threat to health or safety.",
      "",
      "In these cases, we never share your information unless you give us written permission:",
      "",
      "* Marketing purposes",
      "",
      "* Sale of your information",
      "",
      "* Most sharing of psychotherapy notes",
      "",
      "In the case of fundraising:",
      "",
      "* We may contact you for fundraising efforts, but you can tell us not to contact you again.",
      "",
      "## **OUR USES AND DISCLOSURES**",
      "",
      "How do we typically use or share your health information?",
      "",
      "We typically use or share your health information in the following ways.",
      "",
      "Treat you:",
      "",
      "* We can use your health information and share it with other professionals who are treating you.",
      "",
      "*Example: A doctor treating you for an injury asks another doctor about your overall health condition.*",
      "",
      "Run our organization:",
      "",
      "* We can use and share your health information to run our practice, improve your care, and contact you when necessary.",
      "",
      "*Example: We use health information about you to manage your treatment and services.*",
      "",
      "Bill for your services:",
      "",
      "* We can use and share your health information to bill and get payment from health plans or other entities.",
      "",
      "*Example: We give information about you to your health insurance plan so it will pay for your services.*",
      "",
      "How else can we use or share your health information?",
      "",
      "We are allowed or required to share your information in other ways – usually in ways that contribute to the public good, such as public health and research. We have to meet many conditions in the law before we can share your information for these purposes. For more information see: www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/index.html.",
      "",
      "Help with public health and safety issues:",
      "",
      "* We can share health information about you for certain situations such as:",
      "",
      "* Preventing disease",
      "",
      "* Helping with product recalls",
      "",
      "* Reporting adverse reactions to medications",
      "",
      "* Reporting suspected abuse, neglect, or domestic violence",
      "",
      "* Preventing or reducing a serious threat to anyone’s health or safety",
      "",
      "Do research:",
      "",
      "* We can use or share your information for health research.",
      "",
      "Comply with the law:",
      "",
      "* We will share information about you if state or federal laws require it, including with the Department of Health and Human Services if it wants to see that we’re complying with federal privacy law.",
      "",
      "Respond to organ and tissue donation requests:",
      "",
      "* We can share health information about you with organ procurement organizations.",
      "",
      "Work with a medical examiner or funeral director:",
      "",
      "* We can share health information with a coroner, medical examiner, or funeral director when an individual dies.",
      "",
      "Address workers’ compensation, law enforcement, and other government requests:",
      "",
      "* We can use or share health information about you:",
      "",
      "* For workers’ compensation claims",
      "",
      "* For law enforcement purposes or with a law enforcement official",
      "",
      "* With health oversight agencies for activities authorized by law",
      "",
      "* For special government functions such as military, national security, and presidential protective services",
      "",
      "Respond to lawsuits and legal actions:",
      "",
      "* We can share health information about you in response to a court or administrative order, or in response to a subpoena.",
      "",
      "Flourish Health shall grant access to records to residents who request them in compliance with established standards and state laws and when such access is in the best interest of the resident.",
      "",
      "In order to request access to files:",
      "",
      "1. Submit a written request to info@flourish.health.",
      "",
      "2. The request will be reviewed by the appropriate staff and the Clinical Director.",
      "",
      "3. An appointment will be made with a counselor to review the record, whenever possible.",
      "",
      "4. If appropriate and in compliance with established standards and state laws, a copy of the clinical record will be provided within the state required time frames (usually between 14 and 30 days) of the date of the approval of the written request.",
      "",
      "5. This procedure may be subject to a small fee if copies are requested.  You will be informed of the exact fee prior to being charged.",
      "",
      "## **OUR RESPONSIBILITIES**",
      "",
      "* We are required by law to maintain the privacy and security of your protected health information.",
      "",
      "* We will let you know promptly if a breach occurs that may have compromised the privacy or security of your information.",
      "",
      "* We must follow the duties and privacy practices described in this notice and give you a copy of it.",
      "",
      "* We will not use or share your information other than as described here unless you tell us we can in writing. If you tell us we can, you may change your mind at any time. Let us know in writing if you change your mind.",
      "",
      "For more information see:",
      "",
      "[https://www.hhs.gov/hipaa/for-individuals/notice-privacy-practices/index.html](https://www.hhs.gov/hipaa/for-individuals/notice-privacy-practices/index.html).",
      "",
      "Changes to the Terms of This Notice: We can change the terms of this notice, and the changes will apply to all information we have about you. The new notice will be available upon request, in our office, and on our website.",
    ].join("\n"),
  },
  {
    consentFormId: 26,
    title: "Patient Consent, HIPAA, and Privacy Form",
    consentFormType: "consent",
    text: [
      `**Definitions:**

All clinical services are provided by Flourish Health Medical Practice, LLC or other professional service entities to which Flourish Health, Inc. provides administrative services. Flourish Health Medical Practice LLC and such  professional service entities are collectively referred to herein as, "Flourish Health," and together with Flourish Health, Inc. are hereinafter to as  "Flourish". Adolescents and young adults receiving mental health care from Flourish Health shall be referred to herein as, "Patient."

**Introduction:**

This agreement ("Agreement") is intended to provide you with important information regarding the practices, policies and procedures of Flourish. Please read the entire document carefully and ask any questions before signing the document. Please initial each section to indicate that you have read and understood that particular section.

**Consent for Treatment/Care:**

I consent to treatment and care provided by Flourish Health. I also consent to care by physicians, therapists and other team members authorized by Flourish Health. I understand that my treatment and care may include multiple services such as supportive text-based conversation, therapy sessions, medication management, laboratory testing, advocacy at school, exercise, and other services. I understand that there are no guarantees about the results of my treatment and care.

**Right to refuse treatment:**

I understand I have the right to refuse any procedure or treatment while in Flourish Health's care. I understand I have the right to discuss all medical and behavioral treatments with my care team.

**Consent for Telehealth:**

I consent to telehealth visits as part of my care. Flourish Health and their providers may contact me by telephone or electronic mail to communicate with me.

**Consent for In-Person Visits:**

I consent to in person visits as part of my care. Flourish Health and their providers may visit my residence address and coordinate to meet with me at other locations as part of my care.

**Consent for Remote Vital Sign Monitoring:**

I consent to collect my blood pressure, heart rate and other vital signs as directed by my care team. I agree to communicate with them honestly about the findings. I understand that Flourish Health is not treating me for general medicine conditions and will not be able to provide emergency services or general medicine guidance if my vital signs are abnormal.

**Consent for Devices:**

I understand that I may be offered a fitbit as part of my treatment. I agree to take care of the fitbit. I understand I am expected to return the fitbit at the end of my participation in Flourish Health's program.

**Consent for School Advocacy:**

I understand that part of my treatment and care may be support or accommodations in school. I understand that Flourish Health might help me and my family advocate for appropriate support accommodations in school. I consent to my treatment team contacting my school and communicating about my needs.

**Consent for Outpatient Care:**

I understand that Flourish Health's program is not a substitute for emergency medical services or hospitalization. I understand that if I have a medical or psychiatric emergency, that I should call 911 or go to my nearest emergency room.

**Consent for Use Within Flourish Health:**

I understand that information I tell one patient of my treatment team will be shared with the rest of my treatment team. I give permission to Flourish Health providers to share information about me with each other. I understand this is usually done so that my care team can collaborate and provide me with the best treatment and care possible.

**Social Security Number:**

I have given my social security number voluntarily. Flourish Health may use it for accurate identification, filing insurance claims and compliance with federal and state laws.

**Exceptions to Confidentiality**:

I understand that my care team may be required or permitted to break confidentiality when they have determined that I am in serious risk of danger either to myself, or to another person. I understand that my care team may also have to break confidentiality if someone is hurting me.

**Notice of Privacy Practices:**

Flourish Health is HIPAA compliant. Please indicate that you have received and signed the document named "Notice of Privacy Practices." This document further explains how your medical information will be used by this practice.

**Consent for Use and Release of Information:**

I give permission for Flourish Health and their providers to release information about me, my health, my treatment, or payment of my treatment as permitted by law.

**Termination of Treatment:**

I understand that this program is designed to be 12 months. I understand that I am only eligible for as long as I have my current health insurance. I understand that Flourish Health reserves the right to end my treatment early.

Questions or Complaints: If you have any questions about this informed consent form, please contact Flourish by emailing info@flourish.health.

**By signing this form, I understand the following:**

1\\.  I understand that Flourish Health will use telemedicine in the course of my diagnosis and treatment.

2\\.  I understand that the laws that protect the privacy and confidentiality of medical information also apply to telemedicine, and that no information obtained in the use of telemedicine which identifies me will be disclosed to researchers or other entities without my consent.

3\\.  I understand that upon my consent, my telemedicine interactions may be recorded and used for quality and training purposes, and to advance and optimize the telemedicine program.

4\\.  I understand that I have the right to withhold or withdraw my consent to the use of telemedicine in the course of my care at any time, without affecting my right to future care or treatment. However, given that Flourish Health is a telemedicine treatment program, I understand that by withholding or withdrawing my consent, I also thereby terminate my Flourish Health treatment. In order to withdraw my consent, I must notify info@flourish.health of my desire to withdraw my consent. However, I understand that any action already taken in reliance on this consent prior to my revocation cannot be reversed.

5\\.  I understand that I have the right to inspect all information obtained and recorded in the course of a telemedicine interaction subject to applicable law, and may receive copies of this information for a reasonable fee.

6\\.  I understand that a variety of alternative methods of medical care may be available to me, and that I may choose one or more of these at any time. My care team has explained the alternatives to my satisfaction.

7\\.  I understand that telemedicine may involve electronic communication of my personal medical information to other medical practitioners who may be located in other areas, including out of state.

8\\.  I understand that it is my duty to inform my care team of interactions regarding my care that I may have with other healthcare providers.

9\\.  I understand that I may expect the anticipated benefits from the use of Flourish Health's services in my care, but that no results can be guaranteed or assured.

1.  I understand that Flourish Health is not acting as my primary medical provider. I agree that I will seek medical advice, care and treatment from my primary physician or other qualified health care provider if I have any questions or concerns, or if my health condition warrants. I further agree that I will not disregard any professional medical advice or delay seeking medical treatment from my primary physician or other qualified health care provider as a result of any advice or treatment received from Flourish Health.

2.  I acknowledge that I have been informed with respect to the purpose, possible benefits, risks and limitations in connection with therapy treatment and I have received a copy of this consent form. I acknowledge that I have been given the opportunity to ask questions regarding my therapy treatment, and my questions have been answered to my satisfaction.`,
    ].join("\n"),
  },
  {
    consentFormId: 1,
    title: "Transportation Release",
    consentFormType: "transportation",
    text: `Intensive In-Home Services may require the transportation of a patient in the Guide's vehicle. Patients are never transported without prior communication and approval from the parent or guardian.

Additionally, siblings of the patient and other immediate family members may also benefit from transport by the Guide. Events in which this may be needed or beneficial include, medical appointments related to patient care. As previously stated, adolescents are never transported without prior communication and approval from the parent or guardian.

By signing below, I authorize Flourish Health, LLC to transport *immediate family members* for therapeutic and/or case-management related activities. All persons are required to wear safety belts at all times. Youth may require booster seats or child seats as the law designates in the State of Virginia.

I acknowledge that I will be assuming the risk and legal liability; waiving and releasing all claims for injuries, damages or loss, including but, not limited to vehicle operations, boarding and exiting of the vehicle for any patient or immediate family member involved. I do hereby fully release and discharge Flourish Health, LLC from any and all claims for injuries, damages or loss.

By signing below, I have read and fully understand the above waiver and release of all claims. I authorize Flourish Health, LLC to transport the patient when appropriate and/or as needed.
        `,
  },
  {
    consentFormId: 1,
    title: "Research Consent",
    consentFormType: "research",
    text: "I understand Flourish is interested in learning how young people like me do with this kind of treatment model. I understand that Flourish will put together information on how I did in the program with how other young people did and see if there are any trends. I understand Flourish will publish research findings on this data. I understand that when my data is used for research publications, it will always be de-identified and presented as part of a large data set. I understand that I will receive the best care possible from the Flourish Health team even if I do not consent to research. By initialing below, I consent to such research.",
  },
  {
    consentFormId: 1,
    title: "Privacy Policy",
    consentFormType: "privacy",
    text:
      "Last Updated: September 19, 2022\n" +
      "\n" +
      "\n" +
      'Flourish Health, Inc., and our affiliates, subsidiaries, and co-branding partners, (collectively, "**Flourish Health**," "**we**," "**our**," and "**us**") provides personalized mental health treatment for teens, young adults & families. This Privacy Policy is designed to help you understand how we collect, use, and share your personal information and to help you understand and exercise your privacy rights.\n' +
      "\n" +
      "1.  SCOPE\n" +
      "\n" +
      "2.  PERSONAL INFORMATION WE COLLECT\n" +
      "\n" +
      "3.  HOW WE USE YOUR INFORMATION\n" +
      "\n" +
      "4.  HOW WE DISCLOSE YOUR INFORMATION\n" +
      "\n" +
      "5.  YOUR PRIVACY CHOICES AND RIGHTS\n" +
      "\n" +
      "6.  SECURITY OF YOUR INFORMATION\n" +
      "\n" +
      "7.  INTERNATIONAL DATA TRANSFERS\n" +
      "\n" +
      "8.  RETENTION OF PERSONAL INFORMATION\n" +
      "\n" +
      "9.  SUPPLEMENTAL NOTICE FOR CALIFORNIA RESIDENTS\n" +
      "\n" +
      "10. SUPPLEMENTAL NOTICE FOR NEVADA RESIDENTS\n" +
      "\n" +
      "11. CHILDREN'S INFORMATION\n" +
      "\n" +
      "12. OTHER PROVISIONS\n" +
      "\n" +
      "13. CONTACT US\n" +
      "\n" +
      "\n" +
      "**1.** **SCOPE**\n" +
      "----------------\n" +
      "\n" +
      "\n" +
      'This Privacy Policy applies to personal information processed by us, including on our websites, mobile applications , and other online or offline offerings. To make this Privacy Policy easier to read, our websites, mobile applications, and other offerings are collectively called "**Services**." Flourish Health may provide additional privacy notices and authorizations to individuals at the time we collect their personal information.\n' +
      "\n" +
      "\n" +
      '**HIPAA**  **Notice of Privacy Practices.** This Privacy Notice does not apply to our processing of "Protected Health Information" as a "Covered Entity" under the Health Insurance Portability and Accountability Act of 1996 ("**HIPAA**"). Where we or related patients of our organized health care arrangement act as a "Covered Entity", we provide a specific privacy notice and patient consent form to participants that describes our privacy practices in connection with collecting medical information when you sign up for a clinical consultation or other session or meeting with a healthcare provider.  Such separate [HIPAA Notice of Privacy Practices](https://www.charliehealth.com/hipaa-notice-of-privacy-practices)  will govern our use of your personal information related to the therapy, including any "Protected Health Information."\n' +
      "\n" +
      "\n" +
      "**2\\. PERSONAL INFORMATION WE COLLECT**\n" +
      "---------------------------------------\n" +
      "\n" +
      "\n" +
      "The categories of personal information we collect, with your consent, depend on how you interact with us, our Services, and the requirements of applicable law. For example, we may collect different information depending on whether you are a Flourish Health patient or visitor to our website. We collect information that you provide to us, information we obtain automatically when you use our Services, and information from other sources such as third-party services and organizations, as described below.\n" +
      "\n" +
      "\n" +
      "### **A. Information You Provide to Us Directly**\n" +
      "\n" +
      "\n" +
      "We may collect the following personal information that you provide to us.\n" +
      "\n" +
      "\n" +
      "- **Account Creation**. We may collect information when you create an account on our site, such as your name, date of birth, guardian name (if applicable), email, and phone number.\n" +
      "\n" +
      "\n" +
      '- **Contact Us**. If you use one of our "Contact Us", "Get Help Now" or similar features or subscribe or register for one of our products or services (or create an account for any such product or service), we may collect your email address, first and last name, telephone number, address, and, if applicable, organization information.\n' +
      "\n" +
      "\n" +
      "- **Filling in Forms on our Websites**. If you request to receive information, subscribe to a mailing list, or register to volunteer, we will collect information such as your first and last name, email address, and telephone number.\n" +
      "\n" +
      "\n" +
      "- **Service Access**. We may collect information when you access our Services through our website or related online portals.\n" +
      "\n" +
      "\n" +
      "- **Healthcare Coverage**. We may collect personal information and details associated with your healthcare coverage.\n" +
      "\n" +
      "\n" +
      "- **Your Communications with Us**. We may collect personal information, such as email address, phone number, or mailing address when you request information about our Services, register for our newsletter or loyalty program, request customer or technical support, apply for a job, or otherwise communicate with us.\n" +
      "\n" +
      "\n" +
      "- **Surveys**. We may contact you to participate in surveys. If you decide to participate, you may be asked to provide certain information which may include personal information.\n" +
      "\n" +
      "\n" +
      "- **Business Development and Strategic Partnerships.** We may collect personal information from individuals and third parties to assess and pursue potential business opportunities.\n" +
      "\n" +
      "\n" +
      "- **Job Applications.** We may post job openings and opportunities on our Services. If you reply to one of these postings by submitting your application, CV and/or cover letter to us, we will collect and use your information to assess your qualifications.\n" +
      "\n" +
      "\n" +
      "### **B. Information Collected Automatically**\n" +
      "\n" +
      "We may collect personal information automatically when you use our Services:\n" +
      "\n" +
      "\n" +
      "- **Automatic Data Collection Through Our Services**. We may collect certain information automatically when you use our Services, such as your Internet protocol (IP) address, user settings, MAC address, cookie identifiers, mobile carrier, mobile advertising and other unique identifiers, browser or device information, location information (including approximate location derived from IP address), and Internet service provider. We may also automatically collect information regarding your use of our Services, such as pages that you visit before, during and after using our Services, information about the links you click, the types of content you interact with, the frequency and duration of your activities, and other information about how you use our Services.\n" +
      "\n" +
      "\n" +
      '-  **Cookies, Pixel Tags/Web Beacons, and Other Technologies**. We, as well as third parties that provide content, advertising, or other functionality on our Services, may use cookies, pixel tags, local storage, and other technologies ("**Technologies**") to automatically collect information through your use of our Services.\n' +
      "\n" +
      "### ‍\n" +
      "\n" +
      "### **C. Information Collected from Other Sources**\n" +
      "\n" +
      "\n" +
      'We may obtain information about you from other sources, including through third-party services and organizations. For example, we may obtain information related to your medical history through your healthcare provider (a "**Healthcare Provider**").\n' +
      "\n" +
      "\n" +
      "**3\\. HOW WE USE YOUR INFORMATION**\n" +
      "-----------------------------------\n" +
      "\n" +
      "\n" +
      "We use your information for a variety of business purposes, including to provide our Services, for administrative purposes, and to market our products and Services, as described below.\n" +
      "\n" +
      "**‍**\n" +
      "\n" +
      "### **A.** **Provide Our Services**\n" +
      "\n" +
      "\n" +
      "We use your information to fulfill our contract with you and provide you with our Services, such as:\n" +
      "\n" +
      "-        Managing your information and accounts;\n" +
      "\n" +
      "-        Providing access to certain areas, functionalities, and features of our Services;\n" +
      "\n" +
      "-        Answering requests for customer or technical support;\n" +
      "\n" +
      "-        Communicating with you about your account, activities on our Services, and policy changes;\n" +
      "\n" +
      "-        Processing your financial information and other payment methods for products or Services purchased;\n" +
      "\n" +
      "-        Processing applications if you apply for a job we post on our Services; and\n" +
      "\n" +
      "-        Allowing you to register for events.\n" +
      "\n" +
      "**‍**\n" +
      "\n" +
      "### **B.** **Administrative Purposes**\n" +
      "\n" +
      "\n" +
      "We use your information for various administrative purposes, such as:\n" +
      "\n" +
      "-        The purposes set forth in the authorization you provide to Flourish Health;\n" +
      "\n" +
      "-        Pursuing our legitimate interests such as direct marketing, research and development (including marketing research), network and information security, and fraud prevention;\n" +
      "\n" +
      "-        Detecting security incidents, protecting against malicious, deceptive, fraudulent or illegal activity, and prosecuting those responsible for that activity;\n" +
      "\n" +
      "-        Measuring interest and engagement in our Services;\n" +
      "\n" +
      "-        Short-term, transient use, such as contextual customization of ads;\n" +
      "\n" +
      "-        Improving, upgrading or enhancing our Services;\n" +
      "\n" +
      "-        Developing new products and Services;\n" +
      "\n" +
      "-        Ensuring internal quality control and safety;\n" +
      "\n" +
      "-        Authenticating and verifying individual identities, including requests to exercise your rights under this policy;\n" +
      "\n" +
      "-        Debugging to identify and repair errors with our Services;\n" +
      "\n" +
      "-        Auditing relating to interactions, transactions and other compliance activities;\n" +
      "\n" +
      "-        Enforcing our agreements and policies; and\n" +
      "\n" +
      "-        Complying with our legal obligations.\n" +
      "\n" +
      "\n" +
      "### **C. Marketing and Advertising our Products and Services**\n" +
      "\n" +
      "\n" +
      "We may use personal information to tailor and provide you with content and advertisements. We may provide you with these materials as permitted by applicable law.\n" +
      "\n" +
      'Some of the ways we market to you include email campaigns, custom audiences advertising, and "interest-based" or "personalized advertising," including through cross-device tracking.\n' +
      "\n" +
      'If you have any questions about our marketing practices or if you would like to opt out of the use of your personal information for marketing purposes, you may contact us at any time as set forth in "Contact Us" below.\n' +
      "\n" +
      "\n" +
      "### **D.** **Analytics**\n" +
      "\n" +
      "\n" +
      "We may use Technologies and other third-party tools to process analytics information on our Services. Some of our analytics partners include:\n" +
      "\n" +
      "\n" +
      "- **Google Analytics**. We use analytics services provided by Google Inc. This allows us to process usage data to better understand how our website and web-related Services are used. This enables us to continually improve our website, making it relevant to your interests and needs. This may involve personal data, such as cookie and other device identifiers. The data collected by Google Analytics is usually transferred to a Google server in the USA and processed there. In addition, Google uses your data for its own purposes, e.g. for profiling or linking it to other data. For more information, please visit [Google Analytics' Privacy Policy](http://www.google.com/policies/privacy/partners/). To learn more about how to opt-out of Google Analytics' use of your information, please click [here](http://tools.google.com/dlpage/gaoptout).\n" +
      "\n" +
      "\n" +
      "### **E. Other Purposes**\n" +
      "\n" +
      "\n" +
      "We also use your information for other purposes as requested by you or as permitted by applicable law.\n" +
      "\n" +
      "\n" +
      "- **Consent**. **** We may use personal information for other purposes that are clearly disclosed to you at the time you provide personal information or with your consent.\n" +
      "\n" +
      "\n" +
      "- **De-identified and Aggregated Information**. We may use your personal information and other information about you to create de-identified and/or aggregated information, such as de-identified demographic information, de-identified location information, de-identified health information, de-identified information about your use of the connected medical device, information about the device from which you access our Services, or other analyses we create. De-identified and/or aggregated information is not personal information, and we may use and disclose such information in a number of ways, including the development of future learnings, research, internal analysis, analytics, and any other legally permissible purposes.\n" +
      "\n" +
      "\n" +
      "- **Share Content with Patients and Healthcare Providers.** Our Services may offer various tools and functionalities. For example, we may allow you to provide information about your patients or Healthcare Provider through our referral services. Our referral services may allow you to forward or share certain content with a patient or Healthcare Provider, such as an email inviting a patient to enroll in our Services. Please only share with us contact information of people with whom you have a relationship (e.g., your patients or Healthcare Provider) and in all cases in accordance with all applicable law.\n" +
      "\n" +
      "\n" +
      "**4.** **HOW WE DISCLOSE YOUR INFORMATION**\n" +
      "-------------------------------------------\n" +
      "\n" +
      "\n" +
      "We disclose your information to third parties for a variety of business purposes, including to provide our Services, to protect us or others, or in the event of a major business transaction such as a merger, sale, or asset transfer, as described below.\n" +
      "\n" +
      "\n" +
      "### **A.** **Disclosures to Provide our Services**\n" +
      "\n" +
      "\n" +
      "The categories of third parties with whom we may share your information are described below.\n" +
      "\n" +
      "\n" +
      "- **Service Providers**. We may share your personal information with our third-party service providers who use that information to help us provide our Services. This includes service providers that provide us with IT support, hosting, payment processing, customer service, and related services.\n" +
      "\n" +
      "\n" +
      "- **Business Partners**. We may share your personal information with business and co-branding partners to provide you with a product or service you have requested. We may also share your personal information to business and co-branding partners with whom we jointly offer products or services.\n" +
      "\n" +
      "\n" +
      "- **Affiliates**. We may share your personal information with our company affiliates for our administrative purposes, including activities such as IT management, for them to provide services to you or support and supplement the Services we provide.\n" +
      "\n" +
      "\n" +
      "- **Healthcare Providers.** We may share your personal information with your Healthcare Provider in order to provide the Services.\n" +
      "\n" +
      "\n" +
      '- **Other Users/Website Visitors.** As described above in "Personal Information We Collect," our Services allow you to share User Content publicly, including to those who do not use our Services.\n' +
      "\n" +
      "\n" +
      "- **Third-Party Services**. Certain features and functionalities within the Services may allow you to interface or interact with, access, use, and/or disclose personal information to third-party services, products, technology, medical device implant manufacturers, health care providers, and applications (collectively, \"**Third-Party Services**\"). Flourish Health does not provide any aspect of the Third-Party Services and is not responsible for your use of or sharing of information with the Third-Party Services. Once your personal information has been shared with the Third-Party Service, it will also be subject to the Third-Party Service's privacy policy and the Third-Party Service may use your personal information for its own purposes. We encourage you to closely read each Third-Party Service's privacy policy with whom you share your personal information. You acknowledge sole responsibility for and assume all risk arising from your use of or sharing of information with any Third-Party Services. Flourish Health\n" +
      "\n" +
      "\n" +
      '- **Advertising Partners**. We may share your personal information with third-party advertising partners, subject to applicable law. These third-party advertising partners may set Technologies and other tracking tools on our Services to collect information regarding your activities and your device (e.g., your IP address, cookie identifiers, page(s) visited, location, time of day). These advertising partners may use this information (and similar information collected from other services) for purposes of delivering personalized advertisements to you when you visit digital properties within their networks. This practice is commonly referred to as "interest-based advertising" or "personalized advertising."\n' +
      "\n" +
      "\n" +
      '- **APIs/SDKs**. We may use third-party Application Program Interfaces ("APIs") and Software Development Kits ("SDKs") as part of the functionality of our Services. For more information about our use of APIs and SDKs, please contact us as set forth in "Contact Us" ****below.\n' +
      "\n" +
      "\n" +
      "### **B.** **Disclosures to Protect Us or Others**\n" +
      "\n" +
      "\n" +
      "We may access, preserve, and disclose any information we store associated with you to external parties if we, in good faith, believe doing so is required or appropriate to: comply with law enforcement or national security requests and legal process, such as a court order or subpoena; protect your, our, or others' rights, property, or safety; enforce our policies or contracts; collect amounts owed to us; or assist with an investigation or prosecution of suspected or actual illegal activity.\n" +
      "\n" +
      "\n" +
      "### **C.** **Disclosure in the Event of Merger, Sale, or Other Asset Transfers**\n" +
      "\n" +
      "\n" +
      "If we are involved in a merger, acquisition, financing due diligence, reorganization, bankruptcy, receivership, purchase or sale of assets, or transition of service to another provider, your information may be sold or transferred as part of such a transaction, as permitted by law and/or contract.\n" +
      "\n" +
      "\n" +
      "**5\\. YOUR PRIVACY CHOICES AND RIGHTS**\n" +
      "---------------------------------------\n" +
      "\n" +
      "\n" +
      "**Your Privacy Choices**. The privacy choices you may have about your personal information are determined by applicable law and are described below.\n" +
      "\n" +
      "\n" +
      "- **Email and Telephone Communications**. If you receive an unwanted email from us, you can use the unsubscribe link found at the bottom of the email to opt out of receiving future emails. Note that you will continue to receive transaction-related emails regarding products or Services you have requested. We may also send you certain non-promotional communications regarding us and our Services, and you will not be able to opt out of those communications (e.g., communications regarding our Services or updates to our Terms or this Privacy Policy).\n" +
      "\n" +
      "\n" +
      "- **Text Messages.** You may opt out of receiving text messages from us by following the instructions in the text message you have received from us or by otherwise contacting us.\n" +
      "\n" +
      "\n" +
      "- **Mobile Devices**. We may send you push notifications through our mobile application. You may opt out from receiving these push notifications by changing the settings on your mobile device. With your consent, we may also collect precise location-based information via our mobile application. You may opt out of this collection by changing the settings on your mobile device.\n" +
      "\n" +
      "\n" +
      '- **"Do Not Track**.**"** Do Not Track ("DNT") is a privacy preference that users can set in certain web browsers. Please note that we do not respond to or honor DNT signals or similar mechanisms transmitted by web browsers.\n' +
      "\n" +
      "\n" +
      "**Your Privacy Rights**. In accordance with applicable law, you may have the right to:\n" +
      "\n" +
      "\n" +
      "- **Access Personal Information** about you, including: (i) confirming whether we are processing your personal information; (ii) obtaining access to or a copy of your personal information;\n" +
      "\n" +
      "\n" +
      "- **Request Correction** of your personal information where it is inaccurate, incomplete or outdated. In some cases, we may provide self-service tools that enable you to update your personal information;\n" +
      "\n" +
      "\n" +
      "- **Request Deletion, Anonymization or Blocking** of your personal information when processing is based on your consent or when processing is unnecessary, excessive or noncompliant;\n" +
      "\n" +
      "\n" +
      "- **Request Restriction of or Object to** our ****processing of your personal information when processing is noncompliant;\n" +
      "\n" +
      "\n" +
      "- **Withdraw your Consent** to our processing of your personal information. If you refrain from providing personal information or withdraw your consent to processing, some features of our Service may not be available;\n" +
      "\n" +
      "\n" +
      "- **Request data portability** and **receive an electronic copy of personal information that you have provided to us;**\n" +
      "\n" +
      "\n" +
      "- **Be informed** about third parties with which your personal information has been shared; and\n" +
      "\n" +
      "\n" +
      "- **Request the review** **of decisions taken exclusively based on automated processing** if that could affect data subject rights.\n" +
      "\n" +
      "\n" +
      'If you would like to exercise any of these rights, please contact us as set forth in "Contact Us" ****below. We will process such requests in accordance with applicable laws.\n' +
      "\n" +
      "\n" +
      "**6.** **SECURITY OF YOUR INFORMATION**\n" +
      "---------------------------------------\n" +
      "\n" +
      "\n" +
      "We take steps to ensure that your information is treated securely and in accordance with this Privacy Policy. **Unfortunately, no system is 100% secure, and we cannot ensure or warrant the security of any information you provide to us. We have taken appropriate safeguards to require that your personal information will remain protected and require our third-party service providers and partners to have appropriate safeguards as well. To the fullest extent permitted by applicable law, we do not accept liability for unauthorized disclosure.\n" +
      "\n" +
      "\n" +
      "By using our Services or providing personal information to us, you agree that we may communicate with you electronically regarding security, privacy, and administrative issues relating to your use of our Services. If we learn of a security system's breach, we may attempt to notify you electronically by posting a notice on our Services, by mail or by sending an email to you.\n" +
      "\n" +
      "\n" +
      "**7.** **INTERNATIONAL DATA TRANSFERS**\n" +
      "---------------------------------------\n" +
      "\n" +
      "\n" +
      "All information processed by us may be transferred, processed, and stored anywhere in the world, including, but not limited to, the United States or other countries, which may have data protection laws that are different from the laws where you live. We endeavor to safeguard your information consistent with the requirements of applicable laws.\n" +
      "\n" +
      "\n" +
      "**8.** **RETENTION OF PERSONAL INFORMATION**\n" +
      "--------------------------------------------\n" +
      "\n" +
      "\n" +
      "We store the personal information we collect as described in this Privacy Policy for as long as you use our Services or as necessary to fulfill the purpose(s) for which it was collected, provide our Services, resolve disputes, establish legal defenses, conduct audits, pursue legitimate business purposes, enforce our agreements, and comply with applicable laws.\n" +
      "\n" +
      "\n" +
      "**9\\. SUPPLEMENTAL NOTICE FOR CALIFORNIA RESIDENTS**\n" +
      "----------------------------------------------------\n" +
      "\n" +
      "\n" +
      'This Supplemental California Privacy Notice only applies to our processing of personal information that is subject to the California Consumer Privacy Act of 2018 ("CCPA"). The CCPA provides California residents with the right to know what categories of personal information Flourish Health has collected about them and whether Flourish Health disclosed that personal information for a business purpose (e.g., to a service provider) in the preceding 12 months. California residents can find this information below:\n' +
      "\n" +
      "\n" +
      "Category of Personal Information Collected by Flourish Health\n" +
      "\n" +
      "Category of Third Parties Information is Disclosed to for a Business Purpose\n" +
      "\n" +
      "**Identifiers.‍**A real name, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, or other similar identifiers.\n" +
      "\n" +
      "-        Advertising networks-        Data analytics providers-        Service providers-        Healthcare Providers-        Business Partners and Affiliates-        Government entities\n" +
      "\n" +
      "**Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(error))‍**A name, signature,  physical characteristics or description, address, telephone number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information.\n" +
      "\n" +
      "-        Service providers-        Healthcare Providers-        Business Partners and Affiliates-        Government entities\n" +
      "\n" +
      "**Protected classification characteristics under California or federal law‍**Age, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions),  genetic information (including familial genetic information).\n" +
      "\n" +
      "-        Government entities-        Service providers-        Healthcare Providers-        Business Partners and Affiliates\n" +
      "\n" +
      "**Commercial information‍**Records of products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.\n" +
      "\n" +
      "-        Service providers-        Healthcare Providers-        Business Partners and Affiliates\n" +
      "\n" +
      "**Biometric information‍**Physiological, biological, or behavioral, characteristics (including DNA) that can be used to establish individual identity, and rhythms, gait patterns, or rhythms, and sleep, health or exercise data that contain identifying information.\n" +
      "\n" +
      "-        Service providers-        Healthcare Providers-        Business Partners and Affiliates\n" +
      "\n" +
      "**Internet or other electronic network activity‍**Browsing history, search history, information on a consumer's interaction with an internet website, application, or advertisement.\n" +
      "\n" +
      "-        Service providers-        Business Partners and Affiliates-        Advertising networks-        Data analytics providers\n" +
      "\n" +
      "**Geolocation data‍**Physical location.\n" +
      "\n" +
      "-        Service providers\n" +
      "\n" +
      "**Sensory data‍**Audio, electronic, visual, thermal, olfactory, or similar information.\n" +
      "\n" +
      "-        Service providers-        Healthcare Providers\n" +
      "\n" +
      "**Professional or employment-related information (from healthcare providers)‍**Current or past job history.\n" +
      "\n" +
      "-        Service providers-        Business Partners and Affiliates\n" +
      "\n" +
      "**Inferences drawn from other personal information to create a profile about a consumer‍**Profile reflecting a consumer's characteristics, psychological trends, behavior, abilities, and aptitudes.\n" +
      "\n" +
      "-        Service providers-        Healthcare Providers-        Business Partners and Affiliates\n" +
      "\n" +
      "\n" +
      'The categories of sources from which we collect personal information and our business and commercial purposes for using personal information are set forth in "Personal Information We Collect" and "How We Use Your Personal Information" above, respectively.\n' +
      "\n" +
      "\n" +
      '**"Sales" of Personal Information under the CCPA**\n' +
      "\n" +
      'For purposes of the CCPA, Flourish Health does not "sell" personal information, nor do we have actual knowledge of any "sale" of personal information of minors under 16 years of age.\n' +
      "\n" +
      "\n" +
      "**Additional Privacy Rights for California Residents**\n" +
      "\n" +
      "\n" +
      "- **Non-Discrimination.** California residents have the right not to receive discriminatory treatment by us for the exercise of their rights conferred by the CCPA.\n" +
      "\n" +
      "\n" +
      '- **Authorized Agent.** Only you, or someone legally authorized to act on your behalf, may make a verifiable consumer request related to your personal information. You may also make a verifiable consumer request on behalf of your minor child. To designate an authorized agent, please contact us as set forth in "Contact Us" below and provide written authorization signed by you and your designated agent.\n' +
      "\n" +
      "\n" +
      "**Verification**. To protect your privacy, we will take the following steps to verify your identity before fulfilling your request. When you make a request, we will ask you to provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative, which may include asking you to answer questions regarding your account and use of our Services.\n" +
      "\n" +
      "\n" +
      'If you are a California resident and would like to exercise any of your rights under the CCPA, please contact us as set forth in "Contact Us" below. We will process such requests in accordance with applicable laws.\n' +
      "\n" +
      "\n" +
      "**Accessibility**. This Privacy Policy uses industry-standard technologies and was developed in line with the World Wide Web Consortium's Web Content Accessibility Guidelines, version 2.1**.** If you wish to print this policy, please do so from your web browser or by saving the page as a PDF.\n" +
      "\n" +
      "\n" +
      '**California Shine the Light**. The California "Shine the Light" law permits users who are California residents to request and obtain from us once a year, free of charge, a list of the third parties to whom we have disclosed their personal information (if any) for their direct marketing purposes in the prior calendar year, as well as the type of personal information disclosed to those parties.\n' +
      "\n" +
      "\n" +
      "**Right for minors to remove posted content.** Where required by law, California residents under the age of 18 may request to have their posted content or information removed from the publicly-viewable portions of the Services by contacting us directly as set forth in Contact Us below.\n" +
      "\n" +
      "\n" +
      "**10.** **SUPPLEMENTAL NOTICE FOR NEVADA RESIDENTS**\n" +
      "----------------------------------------------------\n" +
      "\n" +
      "\n" +
      'If you are a resident of Nevada, you have the right to opt-out of the sale of certain Personal Information to third parties who intend to license or sell that Personal Information. You can exercise this right by contacting us at info@flourish.health with the subject line "Nevada Do Not Sell Request" and providing us with your name and the email address associated with your account.  Please note that we do not currently sell your Personal Information as sales are defined in Nevada Revised Statutes Chapter 603A. If you have any questions, please contact us as set forth below.\n' +
      "\n" +
      "**11\\. CHILDREN'S INFORMATION**\n" +
      "-------------------------------\n" +
      "\n" +
      "\n" +
      "The Services are not directed to children under 13 (or other age as required by local law), and we do not knowingly collect personal information from children, except where we have parent's verifiable consent.\n" +
      "\n" +
      'If you are a parent or guardian and wish to review information collected from your child, or have that information modified or deleted, you may contact us as described in "Contact Us" below. If we become aware that a child has provided us with personal information in violation of applicable law, we will delete any personal information we have collected, unless we have a legal obligation to keep it, and terminate the child\'s account if applicable.\n' +
      "\n" +
      "\n" +
      "**12.** **OTHER PROVISIONS**\n" +
      "----------------------------\n" +
      "\n" +
      "\n" +
      "**Third-Party Websites/Applications.** The Services may contain links to other websites/applications and other websites/applications may reference or link to our Services. These third-party services are not controlled by us. We encourage our users to read the privacy policies of each website and application with which they interact. We do not endorse, screen or approve, and are not responsible for, the privacy practices or content of such other websites or applications. Providing personal information to third-party websites or applications is at your own risk.\n" +
      "\n" +
      "\n" +
      "**Supervisory Authority**.\n" +
      "\n" +
      "If you are located in the European Economic Area, Switzerland, the United Kingdom or Brazil, you have the right to lodge a complaint with a supervisory authority if you believe our processing of your personal information violates applicable law.\n" +
      "\n" +
      "\n" +
      "**Changes to our Privacy Policy**. We may revise this Privacy Policy from time to time in our sole discretion. If there are any material changes to this Privacy Policy, we will notify you as required by applicable law. You understand and agree that you will be deemed to have accepted the updated Privacy Policy if you continue to use our Services after the new Privacy Policy takes effect.\n" +
      "\n" +
      "\n" +
      "**13.** **CONTACT US**\n" +
      "----------------------\n" +
      "\n" +
      "\n" +
      "If you have any questions about our privacy practices or this Privacy Policy, or to exercise your rights as detailed in this Privacy Policy, please contact us at:\n" +
      "\n" +
      "Flourish Health, Inc.\n" +
      "\n" +
      "info@flourish.health",
  },
  {
    consentFormId: 1,
    title: "HIPAA",
    consentFormType: "hipaa",
    text:
      "Notice of Privacy Practices\n" +
      "===========================\n" +
      "\n" +
      "\n" +
      "Your Information. Your Rights. Our Responsibilities.\n" +
      "\n" +
      "\n" +
      'Flourish Health, P.C., Flourish Health, L.L.C, and Flourish Health Inc.\'s wholly owned subsidiaries (collectively "Flourish Health", "we", "us" "our") make up an organized health care arrangement.  Flourish Health is a clinically integrated care setting in which our patients receive health care from more than one health care provider. In addition, we are an organized health care system that jointly participates in numerous activities including quality assessment and improvement activities.  The list of entities making up such organized health care arrangements may be updated from time to time.  Flourish Health respects and is committed to protecting the privacy of your medical information. In performing its services, Flourish Health will receive, create, and disclose your protected health information (\'PHI\'). Flourish Health is required by law to maintain the privacy and security of your PHI and to provide you with notice of our legal duties and privacy practices with respect to your PHI. For information about our collection, use, and disclosure of personal information other than PHI, please see our privacy policy at [[link to Flourish Privacy Policy](https://www.notion.so/Privacy-Policy-Draft-d948050bdab24d2eb1909d905802e525)] and the privacy policies posted on the websites of the affiliates listed above.  This notice describes how medical information about you may be used and disclosed and how you can get access to this information.  Please review it carefully.\n' +
      "\n" +
      "**YOUR RIGHTS**\n" +
      "---------------\n" +
      "\n" +
      "\n" +
      "When it comes to your health information, you have certain rights.\n" +
      "\n" +
      "\n" +
      "This section explains your rights and some of our responsibilities to help you.\n" +
      "\n" +
      "\n" +
      "Get an electronic or paper copy of your medical record:\n" +
      "\n" +
      "- You can ask to see or get an electronic or paper copy of your medical record and other health         information we have about you. Ask us how to do this.\n" +
      "\n" +
      "- We will provide a copy or a summary of your health information, usually within 30 days of your         request. We may charge a reasonable, cost-based fee.\n" +
      "\n" +
      "- Ask us to correct your medical record\n" +
      "\n" +
      "- You can ask us to correct health information about you that you think is incorrect or         incomplete. Ask us how to do this.\n" +
      "\n" +
      '- We may say "no" to your request, but we\'ll tell you why in writing within 60 days.\n' +
      "\n" +
      "\n" +
      "Request confidential communications:\n" +
      "\n" +
      "- You can ask us to contact you in a specific way (for example, home or office phone) or to send        mail to a different address.\n" +
      "\n" +
      '- We will say "yes" to all reasonable requests.\n' +
      "\n" +
      "\n" +
      "Ask us to limit what we use or share:\n" +
      "\n" +
      "- You can ask us not to use or share certain health information for treatment or our        operations.\n" +
      "\n" +
      '- We are not required to agree to your request, and we may say "no" if it would affect your care.\n' +
      "\n" +
      "Get a list of those with whom we've shared information:\n" +
      "\n" +
      "- You can ask for a list (accounting) of the times we've shared your health information for six years prior to the date you ask, who we shared it with, and why.\n" +
      "\n" +
      "- We will include all the disclosures except for those about treatment, health care operations, and certain other disclosures (such as any you asked us to make). We'll provide one accounting a year for free but will charge a reasonable, cost-based fee if you ask for another one within 12 months.\n" +
      "\n" +
      "Get a copy of this privacy notice:\n" +
      "\n" +
      "- You can ask for a paper copy of this notice at any time, even if you have agreed to receive the         notice electronically. We will provide you with a paper copy promptly.\n" +
      "\n" +
      "Choose someone to act for you:\n" +
      "\n" +
      "- If you have given someone medical power of attorney or if someone is your legal guardian, that        person can exercise your rights and make choices about your health information.\n" +
      "\n" +
      "- We will make sure the person has this authority and can act for you before we take any action.\n" +
      "\n" +
      "\n" +
      "File a complaint if you feel your rights are violated:\n" +
      "\n" +
      "- You can complain if you feel we have violated your rights by contacting us using the        information on the back page.\n" +
      "\n" +
      "- You can file a complaint with the U.S. Department of Health and Human Services Office for        Civil Rights by sending a letter to 200 Independence Avenue, S.W., Washington, D.C. 20201,        calling 1-877-696- 6775, or visiting [](https://www.hhs.gov/hipaa/filing-a-complaint/what-to-expect/index.html)<https://www.hhs.gov/hipaa/filing-a-complaint/what-to-expect/index.html>.\n" +
      "\n" +
      "- We will not retaliate against you for filing a complaint.\n" +
      "\n" +
      "**YOUR CHOICES**\n" +
      "----------------\n" +
      "\n" +
      "\n" +
      "For certain health information, you can tell us your choices about what we share. If you have a clear preference for how we share your information in the situations described below, talk to us. Tell us what you want us to do, and we will follow your instructions.\n" +
      "\n" +
      "\n" +
      "In these cases, you have both the right and choice to tell us to:\n" +
      "\n" +
      "- Share information with your family, close friends, or others involved in your care\n" +
      "\n" +
      "- Share information in a disaster relief situation\n" +
      "\n" +
      "- Include your information in a hospital directory\n" +
      "\n" +
      "\n" +
      "If you are not able to tell us your preference, for example if you are unconscious, we may go ahead and share your information if we believe it is in your best interest. We may also share your information when needed to lessen a serious and imminent threat to health or safety.\n" +
      "\n" +
      "\n" +
      "In these cases, we never share your information unless you give us written permission:\n" +
      "\n" +
      "- Marketing purposes\n" +
      "\n" +
      "- Sale of your information\n" +
      "\n" +
      "- Most sharing of psychotherapy notes\n" +
      "\n" +
      "\n" +
      "In the case of fundraising:\n" +
      "\n" +
      "- We may contact you for fundraising efforts, but you can tell us not to contact you again.\n" +
      "\n" +
      "**OUR USES AND DISCLOSURES**\n" +
      "----------------------------\n" +
      "\n" +
      "\n" +
      "How do we typically use or share your health information?\n" +
      "\n" +
      "\n" +
      "We typically use or share your health information in the following ways.\n" +
      "\n" +
      "\n" +
      "Treat you:\n" +
      "\n" +
      "- We can use your health information and share it with other professionals who are treating you.\n" +
      "\n" +
      "\n" +
      "*Example: A doctor treating you for an injury asks another doctor about your overall health condition.*\n" +
      "\n" +
      "\n" +
      "Run our organization:\n" +
      "\n" +
      "- We can use and share your health information to run our practice, improve your care, and        contact you when necessary.\n" +
      "\n" +
      "\n" +
      "*Example: We use health information about you to manage your treatment and services.*\n" +
      "\n" +
      "\n" +
      "Bill for your services:\n" +
      "\n" +
      "- We can use and share your health information to bill and get payment from health plans or         other entities.\n" +
      "\n" +
      "*Example: We give information about you to your health insurance plan so it will pay for your services.*\n" +
      "\n" +
      "\n" +
      "How else can we use or share your health information?\n" +
      "\n" +
      "\n" +
      "We are allowed or required to share your information in other ways -- usually in ways that contribute to the public good, such as public health and research. We have to meet many conditions in the law before we can share your information for these purposes. For more information see: [www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/index.html](http://www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/index.html).\n" +
      "\n" +
      "\n" +
      "Help with public health and safety issues:\n" +
      "\n" +
      "- We can share health information about you for certain situations such as:\n" +
      "\n" +
      "- Preventing disease\n" +
      "\n" +
      "- Helping with product recalls\n" +
      "\n" +
      "- Reporting adverse reactions to medications\n" +
      "\n" +
      "- Reporting suspected abuse, neglect, or domestic violence\n" +
      "\n" +
      "- Preventing or reducing a serious threat to anyone's health or safety\n" +
      "\n" +
      "\n" +
      "Do research:\n" +
      "\n" +
      "- We can use or share your information for health research.\n" +
      "\n" +
      "\n" +
      "Comply with the law:\n" +
      "\n" +
      "- We will share information about you if state or federal laws require it, including with the         Department of Health and Human Services if it wants to see that we're complying with federal privacy law.\n" +
      "\n" +
      "Respond to organ and tissue donation requests:\n" +
      "\n" +
      "- We can share health information about you with organ procurement organizations.\n" +
      "\n" +
      "Work with a medical examiner or funeral director:\n" +
      "\n" +
      "- We can share health information with a coroner, medical examiner, or funeral director when an         individual dies.\n" +
      "\n" +
      "\n" +
      "Address workers' compensation, law enforcement, and other government requests:\n" +
      "\n" +
      "- We can use or share health information about you:\n" +
      "\n" +
      "- For workers' compensation claims\n" +
      "\n" +
      "- For law enforcement purposes or with a law enforcement official\n" +
      "\n" +
      "- With health oversight agencies for activities authorized by law\n" +
      "\n" +
      "- For special government functions such as military, national security, and presidential protective services\n" +
      "\n" +
      "\n" +
      "Respond to lawsuits and legal actions:\n" +
      "\n" +
      "- We can share health information about you in response to a court or administrative order, or in response to a subpoena.\n" +
      "\n" +
      "\n" +
      "Flourish Health shall grant access to records to residents who request them in compliance with established standards and state laws and when such access is in the best interest of the resident.\n" +
      "\n" +
      "\n" +
      "In order to request access to files:\n" +
      "\n" +
      "1.  Submit a written request to info@flourish.health.\n" +
      "\n" +
      "2.  The request will be reviewed by the appropriate staff and the Clinical Director.\n" +
      "\n" +
      "3.  An appointment will be made with a counselor to review the record, whenever possible.\n" +
      "\n" +
      "4.  If appropriate and in compliance with established standards and state laws, a copy of the          clinical record will be provided within the state required time frames (usually between 14 and 30 days) of the date of the approval of the written request.\n" +
      "\n" +
      "5.  This procedure may be subject to a small fee if copies are requested.  You will be informed of          the exact fee prior to being charged.\n" +
      "\n" +
      "**OUR RESPONSIBILITIES**\n" +
      "------------------------\n" +
      "\n" +
      "\n" +
      "- We are required by law to maintain the privacy and security of your protected health         information.\n" +
      "\n" +
      "- We will let you know promptly if a breach occurs that may have compromised the privacy or         security of your information.\n" +
      "\n" +
      "- We must follow the duties and privacy practices described in this notice and give you a copy         of it.\n" +
      "\n" +
      "- We will not use or share your information other than as described here unless you tell us we        can in writing. If you tell us we can, you may change your mind at any time. Let us know in        writing if you change your mind.\n" +
      "\n" +
      "\n" +
      "For more information see:\n" +
      "\n" +
      "[](https://www.hhs.gov/hipaa/for-individuals/notice-privacy-practices/index.html)<https://www.hhs.gov/hipaa/for-individuals/notice-privacy-practices/index.html>.\n" +
      "\n" +
      "Changes to the Terms of This Notice: We can change the terms of this notice, and the changes will apply to all information we have about you. The new notice will be available upon request, in our office, and on our website.\n" +
      "\n" +
      "‍",
  },
  {
    consentFormId: 1,
    title: "Patient Agreement",
    consentFormType: "patientAgreement",
    text:
      "I understand that, if I am to receive care and treatment at Flourish Health, I will be expected to fully comply with the following:\n" +
      "\n" +
      "\n" +
      "1. Dedicate myself to working with all members of my Flourish health care team assigned to achieve the goals that will best maintain my stability and care.\n" +
      "2. Interact with courtesy with all members of my health care team.\n" +
      "3. Attend all of my scheduled appointments. \n" +
      "    a.     I can reschedule my appointments if I contact the team more than 24 hours in advance. \n" +
      "    b.     Being 15 minutes late will be considered a no-show. \n" +
      "    c.     Missing 3 scheduled appointments in a row without notifying the team 24 hours in advance will be considered criteria for disqualification from the program. \n" +
      "    d.     If my child regularly misses appointments, even if they are not missed in a row, without notifying the team 24 hours in advance, it will also be considered criteria for disqualification from the program.\n" +
      "    e.     Patterns of tardiness to appointments will be discussed on a case by case basis. \n" +
      "4. Communicate with my assigned guide once per week through the Flourish Health app or by phone.\n" +
      "    a.     Failure to respond to communication from my guide or initiate communicate myself will be considered criteria for disqualification from the program. \n" +
      "5. Comply with my health care team's instructions regarding my care and treatment, or if I have not been able to comply, let my health care team know what is going on. \n" +
      "6. Use medication(s) as prescribed and report side effects or other issues immediately to my psychiatrist. \n" +
      "7. Report symptoms that may result from new or worsening complications of my condition so that these symptoms can be assessed by my health care team.\n" +
      "8. Understand I have the right to refuse care if I have decision-making capacity, but I do not have the right to demand care that my health care team feels is medically inappropriate.\n" +
      "9. Understand that I cannot hold Flourish Health or any individual of my health team accountable or liable for consequences of my refusal of care.\n" +
      "10. Discuss disagreements about my treatment/care at Flourish Health or any of its affiliates with my health care team members.\n" +
      "11. Understand that outside of Flourish Health’s hours of operation, if I have a mental health crisis, I need to call 988 or 911. \n" +
      "\n" +
      "My agreement indicates that I have read and understand this Contract and agree to comply fully with its terms. I understand that my failure to comply fully with its terms will be grounds for my dismissal as a patient of Flourish Health and will initiate a thirty (30) day period of time within which I must transfer my care/treatment to a facility other than Flourish Health and its providers.",
  },
  {
    consentFormId: 1,
    title: "Family Member/Caregiver Agreement",
    consentFormType: "familyMemberAgreement",
    text:
      "I understand that, if my child is to receive care and treatment at Flourish Health, I will be expected to fully comply with the following:\n" +
      "\n" +
      "1. Dedicate myself to working with all members of the Flourish health care team assigned to achieve the goals that will best maintain my child's stability and care.\n" +
      "2. Agree to utilize my assigned Family Guide as my primary contact for all communication with the Flourish Health team, and understand that communication with my child’s psychiatrist and therapist will occur only during scheduled sessions. \n" +
      "3. Interact with courtesy with all members of the health care team.\n" +
      "4. Ensure that my child attends all of the scheduled appointments. \n" +
      "    a.    I can reschedule their appointments if I contact the team more than 24 hours in advance. \n" +
      "    b.    Being 15 minutes late will be considered a no-show. \n" +
      "    c.    If my child misses 3 scheduled appointments in a row without notifying the team 24 hours in advance, it will be considered criteria for disqualification from the program. \n" +
      "    d.    If my child regularly misses appointments, even if they are not missed in a row, without notifying the team 24 hours in advance, it will also be considered criteria for disqualification from the program.\n" +
      "    e.    Patterns of tardiness to appointments will be discussed on a case by case basis. \n" +
      "    f.    I must attend psychiatric appointments with my child either via Video Platform link or make sure that I provide a number to the psychiatrist where I can be called during psychiatric appointments.  \n" +
      "5. Comply with my health care team's instructions regarding my child's care and treatment, or if they have not been able to comply, let my health care team know what is going on. \n" +
      "6. I understand that this is a “virtual first” program and that I need to make sure that my child has access to an electronic device to engage in care even when such devices are removed due to consequences of behavior. \n" +
      "7. My child will use medication(s) as prescribed and report side effects or other issues immediately to my designated family guide and the psychiatrist.\n" +
      "8. Report symptoms that my child may be experiencing that may result from new or worsening complications of my condition so that these symptoms can be assessed by my health care team.\n" +
      "9. Meet with my designated family guide upon enrollment to discuss my child’s school advocacy needs and partner with them to advocate for accommodations or support\n" +
      "10. Communicate major life changes or disruptions in the home to my designated family guide \n" +
      "11. Understand I have the right to refuse care for my child if I have decision-making capacity, but I do not have the right to demand care that my health care team feels is medically inappropriate.\n" +
      "12. Understand that I cannot hold Flourish Health or any individual of my health team\n" +
      "accountable or liable for consequences of my child's refusal of care.\n" +
      "13. Discuss disagreements about my child's treatment/care at Flourish Health or any of its affiliates with my health care team members.\n" +
      "14. Understand that outside of Flourish Health’s hours of operation, if my child has a mental health crisis, I need to call 988 or 911. \n" +
      "15. Understand that the treatment team at Flourish Health are mandated reporters. Mandated reporters are certain persons who are identified in the Code of Virginia § [63.2-1509](https://law.lis.virginia.gov/vacode/63.2-1509/) as having a legal responsibility to report suspected abuse, neglect and exploitation. The purpose of mandated reporting is to identify suspected abused and neglected children or vulnerable adults as soon as possible so that they can be protected from further harm. \n" +
      "\n" +
      "\n" +
      "My agreement indicates that I have read and understand this Contract and agree to comply fully with its terms. I understand that my failure to comply fully with its terms will be grounds for dismissal from Flourish Health and will initiate a thirty (30) day period of time within which I must transfer my child's care/treatment to a facility other than Flourish Health and its providers.",
  },
  // TODO: Only show Human Rights Consent to Virginia patients
  {
    consentFormId: 1,
    title: "Rights of Individuals Receiving Services",
    consentFormType: "virginiaRights",
    text:
      "All individuals receiving services from Flourish Health shall be assured their legal rights and care consistent with basic human dignity insofar as it is within the reasonable capabilities and limitations of Flourish Health and is consistent with sound therapeutic treatment. Each individual receiving services from Flourish Health shall:\n" +
      "\n" +
      "1. Retain their legal rights as provided by state and federal law;\n" +
      "2. Receive prompt evaluation and treatment or training about which they are informed\n" +
      "    \n" +
      "    insofar as they are capable of understanding;\n" +
      "    \n" +
      "3. Be treated with dignity as a human being and be free from abuse or neglect;\n" +
      "4. Not be the subject of experimental or investigational research without their prior written\n" +
      "    \n" +
      "    and informed consent or that of their legally authorized representative;\n" +
      "    \n" +
      "5. Be afforded an opportunity to have access to consultation with a private physician at\n" +
      "    \n" +
      "    their own expense and, in the case of hazardous treatment or irreversible surgical procedures, have, upon request, an impartial review prior to implementation, except in case of emergency procedures required for the preservation of their health;\n" +
      "    \n" +
      "6. Be treated under the least restrictive conditions consistent with their condition and not be subjected to unnecessary physical restraint and isolation;\n" +
      "7. Be allowed to send and receive sealed letter mail;\n" +
      "8. Have access to their medical and clinical treatment, training, or habilitation records and\n" +
      "    \n" +
      "    be assured of their confidentiality but, notwithstanding other provisions of law, this right shall be limited to access consistent with their condition and sound therapeutic treatment;\n" +
      "    \n" +
      "9. Have the right to an impartial review of violations of the rights assured under this section and the right of access to legal counsel;\n" +
      "10. Be afforded appropriate opportunities, consistent with the individual's capabilities and capacity, to participate in the development and implementation of their individualized services plan; and\n" +
      "11. Be afforded the opportunity to have a person of their choice notified of their general condition, location, and transfer to another facility.\n" +
      "\n" +
      "If Flourish Health is believed to have violated the human rights of its members and / or their families / caregivers, individuals have the right to submit a complaint via email to humanrights@flourish.health using the Grievance Procedure Form template created by Flourish\n" +
      "\n" +
      "Health. Forms should be submitted within 30 days of the triggering incident. Flourish Health commits to reviewing all complaints in a fair and timely fashion.\n" +
      "\n" +
      "If individuals have questions, and / or experience difficulty submitting complaints in written form, they may contact Flourish Health’s designated human rights advocate, Kathyrn Ray, who is responsible for supporting the promotion and protection of individuals’ rights. The human rights advocate can be reached by calling (804) 214-6688.\n" +
      "\n" +
      "I understand my legal rights and agree to follow the above-outlined process in the event of an incident believed to be in violation with my rights.\n",
  },
];

import {ScheduleItem, User} from "@store";
import {BooleanField, Box, DateTimeField, Modal, TextArea, TextField, useToast} from "ferns-ui";
import {DateTime} from "luxon";
import React, {useCallback, useState} from "react";

interface VacationFormProps {
  scheduleItem?: Partial<ScheduleItem>;
  staff: User;
  onSave: (scheduleItem: Partial<ScheduleItem>) => Promise<void>;
  onDismiss: () => void;
}

export const VacationForm = ({
  scheduleItem,
  onSave,
  staff,
  onDismiss,
}: VacationFormProps): React.ReactElement | null => {
  const toast = useToast();
  const [title, setTitle] = useState<ScheduleItem["title"]>(scheduleItem?.title ?? "");
  const [staffNotes, setStaffNotes] = useState<ScheduleItem["staffNotes"]>(
    scheduleItem?.staffNotes ?? ""
  );
  const [startDatetime, setStartDatetime] = useState<ScheduleItem["startDatetime"]>(
    scheduleItem?.startDatetime
      ? DateTime.fromISO(scheduleItem.startDatetime).toISO()!
      : DateTime.now().plus({days: 1}).set({hour: 12, minute: 0, second: 0}).toISO()!
  );
  const [endDatetime, setEndDatetime] = useState<ScheduleItem["endDatetime"]>(
    startDatetime
      ? DateTime.fromISO(startDatetime).plus({minutes: 50}).toISO()!
      : DateTime.now().plus({days: 1}).set({hour: 13, minute: 0, second: 0}).toISO()!
  );

  const [allDay, setAllDay] = useState<ScheduleItem["allDay"]>(scheduleItem?.allDay ?? false);

  const timezone = staff.timezone ?? "America/New_York";

  const doOnSave = useCallback(async (): Promise<void> => {
    const start = allDay
      ? DateTime.fromISO(startDatetime, {zone: timezone}).startOf("day").toISO()!
      : startDatetime;
    const end = allDay
      ? DateTime.fromISO(endDatetime, {zone: timezone}).endOf("day").toISO()!
      : endDatetime;

    await onSave({
      _id: scheduleItem?._id,
      title,
      startDatetime: start,
      endDatetime: end,
      allDay,
      timezone,
      staffNotes,
    }).catch(toast.catch);
  }, [
    allDay,
    endDatetime,
    onSave,
    scheduleItem?._id,
    staffNotes,
    startDatetime,
    timezone,
    title,
    toast.catch,
  ]);

  return (
    <Modal
      primaryButtonOnClick={doOnSave}
      primaryButtonText="Save"
      size="md"
      title={`Vacation For ${staff?.name ?? "Unknown"}`}
      visible={Boolean(scheduleItem)}
      onDismiss={onDismiss}
    >
      <Box color="base" gap={4} padding={4} rounding="md" width="100%">
        <TextField title="Title" value={title} onChange={(r: string): void => setTitle(r)} />
        <BooleanField
          title="All Day"
          value={Boolean(allDay)}
          onChange={(r: boolean): void => {
            setAllDay(r);
            if (r) {
              setStartDatetime(
                DateTime.fromISO(startDatetime).setZone(staff.timezone).startOf("day").toISO()!
              );
              setEndDatetime(DateTime.fromISO(endDatetime).endOf("day").toISO()!);
            } else {
              setStartDatetime(
                DateTime.fromISO(startDatetime).set({hour: 12, minute: 0, second: 0}).toISO()!
              );
              setEndDatetime(
                DateTime.fromISO(endDatetime).set({hour: 13, minute: 0, second: 0}).toISO()!
              );
            }
          }}
        />
        <Box direction="row" width="100%">
          <Box flex="grow" marginRight={2} maxWidth="50%">
            <DateTimeField
              timezone={staff.timezone ?? "America/New_York"}
              title="Start"
              type={allDay ? "date" : "datetime"}
              value={startDatetime}
              onChange={(r: string): void => {
                if (!r) {
                  return;
                }
                if (allDay) {
                  setStartDatetime(DateTime.fromISO(r).setZone(timezone).startOf("day").toISO()!);
                  setEndDatetime(
                    DateTime.fromISO(endDatetime).setZone(timezone).endOf("day").toISO()!
                  );
                } else {
                  const diff = DateTime.fromISO(endDatetime).diff(
                    DateTime.fromISO(startDatetime),
                    "minutes"
                  ).minutes;
                  setEndDatetime(DateTime.fromISO(r).plus({minutes: diff}).toISO()!);
                  setStartDatetime(r);
                }
              }}
            />
          </Box>
          <Box flex="grow" maxWidth="50%">
            <DateTimeField
              timezone={staff.timezone ?? "America/New_York"}
              title="End"
              type={allDay ? "date" : "datetime"}
              value={endDatetime}
              onChange={(r: string): void => {
                if (!r) {
                  return;
                }
                if (allDay) {
                  setEndDatetime(DateTime.fromISO(r).endOf("day").toISO()!);
                } else {
                  setEndDatetime(r);
                }
              }}
            />
          </Box>
        </Box>
        <TextArea
          helperText="Internal notes about vacation. These will not show up in patient/family members' app."
          title="Staff-Facing Notes"
          value={staffNotes}
          onChange={(value: string): void => {
            setStaffNotes(value);
          }}
        />
      </Box>
    </Modal>
  );
};

import {AlertsControlPanel, AlertsDocumentation} from "@components";
import {StaffStackScreenProps} from "@types";
import {IsMobileDevice, IsWeb} from "@utils";
import {Box, Button, Heading, IconButton, ScrollView, SplitPage, Text} from "ferns-ui";
import React, {useRef, useState} from "react";
import {ListRenderItemInfo} from "react-native";

interface ListItem {
  id: string;
  name: string;
  component: React.ReactElement;
  scrollReferences?: string[];
}

interface ComponentMap {
  AlertsDocumentation: typeof AlertsDocumentation;
  AlertsControlPanel: typeof AlertsControlPanel;
}

interface Props extends StaffStackScreenProps<"AlertsAdmin"> {}

export const AlertsAdminScreen = ({navigation}: Props): React.ReactElement => {
  const scrollViewRef = useRef<ScrollView>(null);
  const [showMobileItemList, setShowMobileItemList] = useState<boolean>(true);
  const [selectedComponent, setSelectedComponent] = useState<string>("AlertsDocumentation");
  const [selectedScrollRef, setSelectedScrollRef] = useState<string | undefined>(undefined);
  const componentMap: ComponentMap = {
    AlertsDocumentation,
    AlertsControlPanel,
  };

  const isComponentMapKey = (key: string): key is keyof ComponentMap => {
    return key in componentMap;
  };

  const AlertComponent = isComponentMapKey(selectedComponent)
    ? componentMap[selectedComponent]
    : null;

  const scrollToPosition = (yOffset: number): void => {
    scrollViewRef.current?.scrollTo({
      y: yOffset,
      animated: true,
    });
  };

  // TODO: Get dropdown with clicking to work on mobile
  const handleDropDownRefClick = (componentId: string, refName: string): void => {
    setSelectedScrollRef(refName);
    setSelectedComponent(componentId);
  };

  const [isDocumentationCollapsed, setIsDocumentationCollapsed] = useState<boolean>(true);
  const documentationListItem: ListItem = {
    id: "AlertsDocumentation",
    name: "Documentation",
    scrollReferences: [
      "recipientsRef",
      "triggeringEventsRef",
      "centerVsBannerRef",
      "textMergeFieldsRef",
    ],
    component: (
      <Box padding={3}>
        <Box alignItems="center" direction="row" justifyContent="between">
          <Text bold>Documentation</Text>
          {IsWeb && (
            <IconButton
              accessibilityLabel={`Documentation toggle is ${
                isDocumentationCollapsed ? "collapsed" : "expanded"
              }`}
              iconName={isDocumentationCollapsed ? "chevron-right" : "chevron-down"}
              onClick={(): void => {
                setIsDocumentationCollapsed(!isDocumentationCollapsed);
              }}
            />
          )}
        </Box>
        {IsWeb && !isDocumentationCollapsed && (
          <Box marginLeft={2}>
            <Button
              text="Alert Recipients"
              variant="muted"
              onClick={() => handleDropDownRefClick("AlertsDocumentation", "recipientsRef")}
            />
            <Button
              text="Triggering Events"
              variant="muted"
              onClick={() => handleDropDownRefClick("AlertsDocumentation", "triggeringEventsRef")}
            />
            <Button
              text="Center vs Banner"
              variant="muted"
              onClick={() => handleDropDownRefClick("AlertsDocumentation", "centerVsBannerRef")}
            />
            <Button
              text="Text Merge Fields"
              variant="muted"
              onClick={() => handleDropDownRefClick("AlertsDocumentation", "textMergeFieldsRef")}
            />
          </Box>
        )}
      </Box>
    ),
  };

  const alertControlPanelListItem: ListItem = {
    id: "AlertsControlPanel",
    name: "Control Panel",
    component: (
      <Box alignItems="start" padding={3}>
        <Text bold>Control Panel</Text>
      </Box>
    ),
  };

  const listViewData: ListItem[] = [documentationListItem, alertControlPanelListItem];

  const renderListViewHeader = (): React.ReactElement => (
    <Box marginBottom={4} padding={1}>
      <Box alignItems="center" justifyContent="center" paddingY={1}>
        <Heading size="sm">Alerts Directory</Heading>
      </Box>
    </Box>
  );

  const renderListViewItem = (itemInfo: ListRenderItemInfo<ListItem>): React.ReactElement =>
    itemInfo.item.component;

  return (
    <SplitPage
      bottomNavBarHeight={0}
      listViewData={listViewData}
      renderListViewHeader={renderListViewHeader}
      renderListViewItem={renderListViewItem}
      showItemList={showMobileItemList}
      onSelectionChange={(itemInfo: {item: ListItem}): void => {
        if (!itemInfo?.item?.id) {
          return;
        }
        if (IsMobileDevice) {
          // set sooner in order to avoid flicker/double click
          setShowMobileItemList(false);
        }
        scrollToPosition(0);
        if (
          !itemInfo.item.scrollReferences ||
          (itemInfo.item?.scrollReferences &&
            selectedScrollRef &&
            !itemInfo.item.scrollReferences.includes(selectedScrollRef))
        ) {
          setSelectedScrollRef(undefined);
        }
        setSelectedComponent(itemInfo.item.id);
        if (IsMobileDevice) {
          navigation.setOptions({
            headerLeft: () => {
              return (
                <IconButton
                  accessibilityLabel="deselect mobile item"
                  iconName="arrow-left"
                  onClick={(): void => {
                    setSelectedScrollRef(undefined);
                    setShowMobileItemList(true);
                    navigation.setOptions({
                      headerLeft: undefined,
                      headerTitle: "Alerts Center",
                    });
                  }}
                />
              );
            },
            headerTitle: () => {
              return (
                <Box>
                  <Text align="center">{itemInfo?.item?.name}</Text>
                </Box>
              );
            },
          });
        }
      }}
    >
      <ScrollView ref={scrollViewRef}>
        <Box
          alignContent="start"
          color="base"
          flex="grow"
          height="100%"
          padding={IsWeb ? 5 : 2}
          width="100%"
        >
          {AlertComponent && (
            <AlertComponent
              scrollToPosition={scrollToPosition}
              selectedScrollRef={selectedScrollRef}
            />
          )}
        </Box>
      </ScrollView>
    </SplitPage>
  );
};

import {skipToken} from "@reduxjs/toolkit/query/react";
import {FetchPopulatedByIdRes, PopulatedConversation, useGetConversationsByIdQuery} from "@store";
import flatten from "lodash/flatten";
import uniq from "lodash/uniq";
import {useMemo} from "react";

import {useGetPopulateUserLookup} from "./useGetPopulateUserLookup";

export function useGetPopulatedConversationById(
  id: string | typeof skipToken
): FetchPopulatedByIdRes<PopulatedConversation> {
  const queryRes = useGetConversationsByIdQuery(id);
  const populateIdList = uniq(
    flatten(
      [
        ...(queryRes.data?.referencedUsers ?? [])?.map((u) => u.userId),
        ...(queryRes.data?.archivedUsers ?? [])?.map((u) => u.userId),
        ...(queryRes.data?.users ?? [])?.map((u) => u.userId),
      ].filter((userId) => userId) as string[] as string[]
    )
  );

  const userLookupTableQuery = useGetPopulateUserLookup(populateIdList);
  const userLookupTable = userLookupTableQuery.userLookup;

  const userData = useMemo(() => {
    const item = queryRes.data;

    if (!Object.keys(userLookupTable).length || !item) {
      return undefined;
    }

    const populated = {...queryRes.data} as any as PopulatedConversation;

    populated.users = item.users.map((u) => ({
      userId: userLookupTable?.[u?.userId],
    }));
    populated.archivedUsers = item.archivedUsers.map((u) => ({
      userId: u?.userId ? userLookupTable?.[u?.userId] : undefined,
    }));
    populated.referencedUsers = item.referencedUsers.map((u) => ({
      userId: u?.userId ? userLookupTable?.[u?.userId] : undefined,
    }));

    return populated;
  }, [queryRes.data, userLookupTable]);

  return {
    data: userData,
    isSuccess: queryRes.isSuccess && userLookupTableQuery.isSuccess,
    error: queryRes.error || userLookupTableQuery.error,
    isError: queryRes.isError || userLookupTableQuery.isError,
    isLoading: queryRes.isLoading || userLookupTableQuery.isLoading,
    isFetching: queryRes.isFetching || userLookupTableQuery.isFetching,
  };
}

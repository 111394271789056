import {useGetUserStatusesQuery, usePatchUsersByIdMutation, User} from "@store";
import {Box, Icon, SelectField, Text, useToast} from "ferns-ui";
import React, {useCallback, useState} from "react";

import {UserStatusBadge} from "./UserStatusBadge";

interface TapToEditUserStatusProps {
  currentObj: User;
}

export const TapToEditUserStatus = ({
  currentObj: currentUser,
}: TapToEditUserStatusProps): React.ReactElement => {
  const toast = useToast();
  const {data: userStatuses} = useGetUserStatusesQuery({});
  const [updateUser] = usePatchUsersByIdMutation();
  const [edit, setEdit] = useState(false);

  const onEdit = useCallback(() => {
    setEdit(!edit);
  }, [edit]);

  const onChange = useCallback(
    async (statusId?: string) => {
      await updateUser({id: currentUser._id, body: {statusId}}).unwrap().catch(toast.catch);
      setEdit(false);
    },
    [currentUser._id, toast.catch, updateUser]
  );

  const options = userStatuses?.data?.map((status) => ({label: status.name, value: status._id}));

  return (
    <Box borderBottom="default" direction="row" justifyContent="between" paddingY={2} width="100%">
      <Box flex="grow">
        <Text bold>User Status:</Text>
      </Box>
      <Box alignItems="center" direction="row">
        <Box alignItems="center" justifyContent="start">
          {Boolean(edit) ? (
            <Box marginRight={4}>
              <SelectField
                options={options ?? []}
                requireValue={false}
                value={currentUser.statusId}
                onChange={onChange}
              />
            </Box>
          ) : (
            <UserStatusBadge statusId={currentUser.statusId} />
          )}
        </Box>

        <Box
          accessibilityHint=""
          accessibilityLabel="Edit user status"
          marginLeft={2}
          width={16}
          onClick={onEdit}
        >
          <Icon iconName={edit ? "x" : "pencil"} size="md" />
        </Box>
      </Box>
    </Box>
  );
};

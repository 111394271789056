import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  FetchPopulatedRes,
  GetScheduleItemsArgs,
  PopulatedScheduleItem,
  useGetScheduleItemsQuery,
} from "@store";
import flatten from "lodash/flatten";
import uniq from "lodash/uniq";
import {useMemo} from "react";

import {useGetPopulateUserLookup} from "./useGetPopulateUserLookup";

export function useFetchPopulatedScheduleItems(
  query: GetScheduleItemsArgs | typeof skipToken = {}
): FetchPopulatedRes<PopulatedScheduleItem> {
  const scheduledItemQuery = useGetScheduleItemsQuery(query);
  const populateIdList = uniq(
    flatten(
      scheduledItemQuery.data?.data?.map(
        (si) => [...si?.users?.map((u) => u?.userId), ...si.staff.map((u) => u?.userId)] as string[]
      )
    )
  ).filter((id) => id);

  // The downside of doing this is every spot that fetches users will have to do this query.
  // We don't normalize the users store in such a way we could share this with other queries.
  const lookupData = useGetPopulateUserLookup(populateIdList);
  const populateUserLookup = lookupData.userLookup;

  const userData = useMemo(() => {
    if (Object.keys(populateUserLookup).length === 0) {
      return [];
    }

    const populatedList: PopulatedScheduleItem[] = [];
    for (const item of scheduledItemQuery.data?.data ?? []) {
      // Force case it since we're going to be updating this object to be populated.
      const populated = {...item} as any as PopulatedScheduleItem;
      populated.users = item.users.map((u) => ({
        userId: populateUserLookup?.[u?.userId],
      }));
      populated.staff = item.staff.map((u) => ({
        userId: populateUserLookup?.[u?.userId],
        role: u?.role,
      }));

      populatedList.push(populated);
    }
    return populatedList;
  }, [populateUserLookup, scheduledItemQuery.data?.data]);

  return {
    data: {...scheduledItemQuery.data, data: userData},
    isSuccess: scheduledItemQuery.isSuccess && lookupData.isSuccess,
    error: scheduledItemQuery.error || lookupData.error,
    isError: scheduledItemQuery.isError || lookupData.isError,
    isLoading: scheduledItemQuery.isLoading || lookupData.isLoading,
    isFetching: scheduledItemQuery.isFetching || lookupData.isFetching,
  };
}

import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  FetchPopulatedRes,
  GetAlertInstancesArgs,
  PopulatedAlertInstance,
  useGetAlertInstancesQuery,
  useGetUsersQuery,
} from "@store";
import flatten from "lodash/flatten";
import uniq from "lodash/uniq";
import {useMemo} from "react";

export function useFetchPopulatedAlertInstances(
  query: GetAlertInstancesArgs | typeof skipToken
): FetchPopulatedRes<PopulatedAlertInstance> {
  const alertInstancesQuery = useGetAlertInstancesQuery(query);
  const populateIdList = uniq(
    flatten(
      alertInstancesQuery?.data?.data
        ?.map((a) => a?.associatedUserId)
        .filter((id) => id) as string[]
    )
  );

  // Use useGetUsersQuery directly with populateIdList
  const {
    data: usersData,
    isSuccess: usersQuerySuccess,
    isLoading: usersQueryLoading,
    isFetching: usersQueryFetching,
  } = useGetUsersQuery(
    populateIdList.length > 0 ? {_id: {$in: populateIdList}, limit: 200} : skipToken
  );

  // Creating user lookup table directly in useFetchPopulatedAlertInstances
  const userLookupTable = useMemo(() => {
    const lookup: any = {};
    usersData?.data?.forEach((user) => {
      lookup[user._id] = user;
    });
    return lookup;
  }, [usersData]);

  const userData = useMemo(() => {
    const populatedList: PopulatedAlertInstance[] = [];
    alertInstancesQuery.data?.data?.forEach((item) => {
      const populated = {...item, associatedUserId: userLookupTable[item.associatedUserId] || {}};
      populatedList.push(populated);
    });
    return populatedList;
  }, [userLookupTable, alertInstancesQuery.data?.data]);

  return {
    data: {...alertInstancesQuery.data, data: userData},
    isSuccess: alertInstancesQuery.isSuccess && usersQuerySuccess,
    error: alertInstancesQuery.error,
    isError: alertInstancesQuery.isError,
    isLoading: alertInstancesQuery.isLoading || usersQueryLoading,
    isFetching: alertInstancesQuery.isFetching || usersQueryFetching,
  };
}

import {baseUrl} from "@ferns-rtk";
import NetInfo from "@react-native-community/netinfo";
import {captureException} from "@utils";
import axios from "axios";
import Constants from "expo-constants";

interface AnalyticsPayload {
  prevValue?: string;
  newValue?: string;
  [key: string]: string | number | boolean | undefined;
}
interface AnalyticsParams {
  name: string;
  appliedUserId?: string;
  collectionModel?: string;
  docId?: string;
  payload?: AnalyticsPayload;
  isActivityLogEvent?: boolean;
  isUserUpdateEvent?: boolean;
  [key: string]: string | number | boolean | any | undefined;
}

interface ConnectionDetails {
  /** The type of network connection the user is connected with at this point in time. */
  networkType?: string | null;
  /** The name of the wifi network the user is connected to. */
  wifiNetwork?: string | null;
  /** The strength of the network connection at this point in time. */
  wifiSignalStrength?: number | null;
  /** The strength of the cellular network connection at this point in time. */
  cellularSignalStrength?: number | null;
  /** The name of the cellular carrier the user is connected to. */
  cellularCarrier?: string | null;
  /** The generation of the cellular network the user is connected to. */
  cellularGeneration?: string | null;
}

export interface PostAnalyticsParams {
  name: string;
  appliedUserId?: string;
  collectionModel?: string;
  docId?: string;
  payload?: AnalyticsPayload;
  isActivityLogEvent?: boolean;
  isUserUpdateEvent?: boolean;
  connectionDetails?: ConnectionDetails;
}

const postAuditLogRequest = async ({
  name,
  appliedUserId,
  collectionModel,
  docId,
  payload,
  isActivityLogEvent = false,
  isUserUpdateEvent = false,
  connectionDetails,
}: PostAnalyticsParams): Promise<void> => {
  try {
    await axios.post(`${baseUrl}/auditLog`, {
      appVersion: Constants.expoConfig?.version || "Unknown",
      eventName: name,
      appliedUserId,
      collectionModel,
      docId,
      payload,
      isActivityLogEvent,
      isUserUpdateEvent,
      connectionDetails,
    });
  } catch (error: any) {
    console.error(`Error posting audit log ${error}`);
    captureException(error);
  }
};

export function useAnalytics(): ({
  name,
  appliedUserId,
  collectionModel,
  docId,
  payload,
  isActivityLogEvent,
  isUserUpdateEvent,
}: AnalyticsParams) => Promise<void> {
  const postAuditLog = async ({
    name,
    appliedUserId,
    collectionModel,
    docId,
    payload,
    isActivityLogEvent = false,
    isUserUpdateEvent = false,
  }: PostAnalyticsParams): Promise<void> => {
    try {
      const netInfo = await NetInfo.fetch();
      const netType = netInfo.type;
      const connectionDetails: ConnectionDetails = {
        networkType: netType,
      };
      if (netType === "wifi") {
        connectionDetails.wifiSignalStrength = netInfo.details.strength;
      } else if (netType === "cellular") {
        connectionDetails.cellularCarrier = netInfo.details.carrier;
        connectionDetails.cellularGeneration = netInfo.details.cellularGeneration;
      }

      await postAuditLogRequest({
        name,
        appliedUserId,
        collectionModel,
        docId,
        payload,
        isActivityLogEvent,
        isUserUpdateEvent,
        connectionDetails,
      });
    } catch (error) {
      console.error(error);
      captureException(error);
    }
  };

  return async ({
    name,
    appliedUserId,
    collectionModel,
    docId,
    payload,
    isActivityLogEvent = false,
    isUserUpdateEvent = false,
  }: AnalyticsParams): Promise<void> => {
    await postAuditLog({
      name,
      appliedUserId,
      collectionModel,
      docId,
      payload,
      isActivityLogEvent,
      isUserUpdateEvent,
    });
  };
}

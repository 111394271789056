import {useNavigation} from "@react-navigation/native";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {useGetUsersByIdQuery, usePatchUsersByIdMutation, useUIError} from "@store";
import {StaffStackParamList} from "@types";
import {UserTypes} from "@utils";
import {Box, Button, formatAddress, Heading, IconButton, Text} from "ferns-ui";
import React from "react";

interface PreferredPharmacyProps {
  userId: string;
}

export const PreferredPharmaciesPane = ({
  userId,
}: PreferredPharmacyProps): React.ReactElement | null => {
  const navigation =
    useNavigation<NativeStackNavigationProp<StaffStackParamList, UserTypes.Staff>>();
  const uiError = useUIError();
  const user = useGetUsersByIdQuery(userId).data;
  const [updateUser] = usePatchUsersByIdMutation();

  if (!user) {
    return null;
  }

  const deletePharmacy = async (index: number): Promise<void> => {
    const preferredPharmacies = [...user.preferredPharmacies];
    preferredPharmacies.splice(index, 1);
    try {
      await updateUser({
        id: userId,
        body: {preferredPharmacies},
      }).unwrap();
    } catch (error: any) {
      uiError("Failed to delete preferred pharmacy", error);
    }
  };

  return (
    <>
      <Box paddingY={1}>
        <Heading size="sm">Pharmacies</Heading>
      </Box>
      {(user.preferredPharmacies ?? []).map((pharmacy, index) => (
        <Box
          key={index}
          borderBottom="default"
          direction="row"
          justifyContent="between"
          paddingY={2}
          width="100%"
        >
          <Box flex="grow">
            <Text bold>
              {pharmacy.name} {pharmacy.primary ? "(Primary)" : ""}
            </Text>
            {Boolean(pharmacy?.address?.city) && (
              <Box paddingY={1}>
                <Text>
                  Address: {pharmacy.address ? formatAddress(pharmacy.address as any) : "Not set"}
                </Text>
              </Box>
            )}
          </Box>
          <IconButton
            accessibilityLabel="Edit Contact"
            iconName="pencil"
            onClick={(): void => {
              navigation.push("PreferredPharmacy", {userId, pharmacyIndex: index});
            }}
          />

          <IconButton
            accessibilityLabel="Delete Pharmacy"
            confirmationText="Are you sure you want to delete this pharmacy?"
            iconName="trash"
            variant="destructive"
            withConfirmation
            onClick={async (): Promise<void> => {
              await deletePharmacy(index);
            }}
          />
        </Box>
      ))}
      <Box marginLeft={2} paddingY={2}>
        <Button
          iconName="plus"
          text="Add Pharmacy"
          onClick={(): void => {
            navigation.push("PreferredPharmacy", {userId});
          }}
        />
      </Box>
    </>
  );
};

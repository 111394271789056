import {
  DateInput,
  EmailInput,
  FollowUpResponseBox,
  FreeformTextArea,
  HeadingBox,
  MultiSelectBox,
  NumberInput,
  PhoneNumberInput,
  QuestionCheckBox,
  QuestionPromptText,
  ShortformTextField,
  SingleSelect,
} from "@components";
import {useReadProfile} from "@hooks";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  FormQuestion,
  FormValidator,
  isSuperUserOrSupervisor,
  SingleCheckboxOptions,
  useGetUsersByIdQuery,
} from "@store";
import {isStaff} from "@utils";
import {Box, Text} from "ferns-ui";
import React, {ReactElement, useMemo} from "react";

interface ComponentMap {
  Date: typeof DateInput;
  CarePlan: typeof FreeformTextArea;
  ClinicalStatus: typeof SingleSelect;
  SafetyPlan: typeof FreeformTextArea;
  Email: typeof EmailInput;
  Freeform: typeof FreeformTextArea;
  Number: typeof NumberInput;
  PhoneNumber: typeof PhoneNumberInput;
  Shortform: typeof ShortformTextField;
  Select: typeof SingleSelect;
  Multiselect: typeof MultiSelectBox;
  SingleCheckbox: typeof QuestionCheckBox;
}
interface FormInstanceQuestionProps {
  question: FormQuestion;
  index: number;
  onChange: (value: any, isFollowUpResponse?: boolean) => void | Promise<void>;
  onBlur: (value: any, isFollowUpResponse?: boolean) => Promise<void>;
  answers: string[];
  disabled: boolean;
  followUpResponse?: string;
  userId?: string;
  onFocus?: () => void;
}

const QuestionSupport = ({
  question,
  isStaf,
  isSupervisor,
}: {
  question: FormQuestion;
  isStaf: boolean;
  isSupervisor: boolean;
}): ReactElement | null => {
  const populateFieldsText = useMemo(() => {
    return question.populateFields?.map((field) => `${field.schema} ${field.key}`).join(", ");
  }, [question.populateFields]);

  return (
    <Box width="100%">
      {Boolean(question.description) && (
        <Box marginTop={2}>
          <Text>{question.description}</Text>
        </Box>
      )}
      {Boolean(isStaf && question.charmKey) && (
        <Box marginTop={2}>
          <Text size="sm">Charm Key: {question.charmKey}</Text>
        </Box>
      )}
      {Boolean(isStaf && populateFieldsText) && (
        <Box marginTop={2}>
          <Text size="sm">Updates {populateFieldsText} upon completion</Text>
        </Box>
      )}
      {Boolean(isStaf && ["CarePlan", "SafetyPlan"].includes(question.type)) && (
        <Box marginTop={2}>
          <Text size="sm">**Visible in Patient App**</Text>
        </Box>
      )}
      {Boolean(!isSupervisor && question.isSupervisorOnly) && (
        <Box marginTop={2}>
          <Text size="sm">This question is to be completed by your supervisor.</Text>
        </Box>
      )}
    </Box>
  );
};

export const FormQuestionComponent = ({
  answers,
  question,
  index,
  onChange,
  onBlur,
  onFocus,
  disabled,
  followUpResponse,
  userId,
}: FormInstanceQuestionProps): ReactElement | null => {
  const profile = useReadProfile();
  const {data: formUser} = useGetUsersByIdQuery(userId ?? skipToken);
  const isStaf = isStaff(profile?.type);
  const isSupervisor = isSuperUserOrSupervisor(profile);

  const determineInitialValue = (): string[] => {
    if (answers.length > 0) {
      return answers;
    } else if (["Freeform", "Shortform"].includes(question.type)) {
      return [""];
    } else if (["ClinicalStatus", "Multiselect", "Select"].includes(question.type)) {
      return [];
    } else if (question.type === "SingleCheckbox") {
      return [SingleCheckboxOptions.unchecked];
    } else if (question.type === "CarePlan" && formUser) {
      return [formUser.carePlan ?? ""];
    } else if (question.type === "SafetyPlan" && formUser) {
      return [formUser.safetyPlan ?? ""];
    }
    return [];
  };
  const value = determineInitialValue();

  const componentMap: ComponentMap = {
    Date: DateInput,
    CarePlan: FreeformTextArea,
    ClinicalStatus: SingleSelect,
    SafetyPlan: FreeformTextArea,
    Email: EmailInput,
    Number: NumberInput,
    PhoneNumber: PhoneNumberInput,
    Freeform: FreeformTextArea,
    Shortform: ShortformTextField,
    Select: SingleSelect,
    Multiselect: MultiSelectBox,
    SingleCheckbox: QuestionCheckBox,
  };
  function isComponentMapKey(key: any): key is keyof ComponentMap {
    return key in componentMap;
  }
  const QuestionComponent = isComponentMapKey(question.type) ? componentMap[question.type] : null;
  const answerRequiredErr = FormValidator.validateAnswerRequirements(question, value, isSupervisor);

  return (
    <Box key={question._id} paddingY={2}>
      {question.type === "SingleCheckbox" ? (
        <Box paddingY={2}>
          <Box marginTop={2} width="100%">
            {QuestionComponent && (
              <QuestionComponent
                {...{
                  answerRequiredErr,
                  index,
                  question,
                  value,
                  title: "",
                  onChange,
                  onBlur,
                  disabled,
                  onFocus,
                }}
              />
            )}
            <QuestionSupport isStaf={isStaf} isSupervisor={isSupervisor} question={question} />
          </Box>
        </Box>
      ) : (
        <Box paddingY={2}>
          {question.type === "Heading" ? (
            <HeadingBox prompt={question.prompt} />
          ) : (
            <QuestionPromptText index={index} prompt={question.prompt} />
          )}
          <QuestionSupport isStaf={isStaf} isSupervisor={isSupervisor} question={question} />
          <Box marginTop={2} width="100%">
            {QuestionComponent && (
              <QuestionComponent
                {...{
                  index,
                  question,
                  value,
                  onChange,
                  onBlur,
                  title: "",
                  disabled,
                  answerRequiredErr,
                  onFocus,
                }}
              />
            )}
            <FollowUpResponseBox
              {...{followUpResponse, question, onChange, onBlur, selectedOptions: value, disabled}}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

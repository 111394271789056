import {getSdkHook, UserDotNotation} from "@store";
import {
  BaseTapToEditProps,
  Box,
  printDateAndTime,
  printOnlyDate,
  printTime,
  SelectFieldProps,
  TapToEditProps,
  Text,
  useOpenAPISpec,
  useToast,
} from "ferns-ui";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import set from "lodash/set";
import startCase from "lodash/startCase";
import React, {useCallback} from "react";

import {TapToEditRow} from "./TapToEditRow";

// Because TapToEditProps is a union type, we can't extend from it.
// So we duplicate some of the props for simplicty.
// Omit title and value because we'll derive them from the model and field.
export interface AutoTapToEditProps extends Omit<BaseTapToEditProps, "title" | "value"> {
  model: string;
  // We should generalize this to work with any model, not just User.
  field: UserDotNotation;
  instanceId: string;
  setValue: TapToEditProps["setValue"];
  currentObj?: any; // the current object, e.g. a User. We'll use `field` to get the value.
  border?: boolean;
  type?: TapToEditProps["type"];
  options?: SelectFieldProps["options"];
  title?: string;
  placeholder?: string;
  value?: any;
  timezone?: string;
}

export const AutoTapToEdit = ({
  model,
  field,
  instanceId,
  setValue,
  currentObj,
  type = "text",
  ...rest
}: AutoTapToEditProps): React.ReactElement | null => {
  const toast = useToast();
  const {getModelField} = useOpenAPISpec();

  const modelField = getModelField(model, field);
  const prevObj = cloneDeep(currentObj);

  const [updateHook] = getSdkHook(model, "update")?.useMutation();

  if (!updateHook) {
    throw new Error(`Update hook not found for ${model}`);
  }

  const onSave = useCallback(
    async (value: any) => {
      if (type && ["textarea", "text"].includes(type)) {
        value = value?.trim();
      }
      if (!value && type === "select") {
        value = null;
      }
      // Update using dot notation to be able to handle nested values correctly.
      const body = {id: instanceId, body: {[field]: value}};
      await updateHook(body)
        .unwrap()
        .catch((error: any) => {
          setValue?.((prevState: any) => {
            const newState = cloneDeep(prevState);
            set(newState, field, prevObj[field]);
            return newState;
          });
          toast.catch(error, "Error updating");
        });
    },
    [field, instanceId, setValue, toast, type, updateHook, prevObj]
  );

  if (!modelField) {
    console.error(`Model field not found for ${model}.${field}`);
    return (
      <Box paddingY={2}>
        <Text>Field loading...</Text>
      </Box>
    );
  }

  // Split the field name by "." and get the last part, then start case it.
  const title = startCase(field.split(".").pop() ?? "");

  const description = modelField.description;

  if (modelField.type === "boolean") {
    type = "boolean";
  } else if (modelField.format === "date-time" && type !== "date" && type !== "time") {
    type = "datetime";
  } else if (modelField.enum) {
    type = "select";
  }

  return (
    <TapToEditRow
      helperText={description}
      options={
        type === "select" ? modelField.enum?.map((value) => ({label: startCase(value), value})) : []
      }
      placeholder="None Selected"
      setValue={(value) => {
        const newObj = cloneDeep(currentObj);
        set(newObj, field, value);
        setValue?.(newObj);
      }}
      title={title}
      transform={(value: string): string => {
        if (modelField.type === "boolean") {
          return value ? "Yes" : "No";
        } else if (type === "date" || modelField.type === "date") {
          return value ? printOnlyDate(value) : "Not set";
        } else if (type === "datetime" || modelField.type === "datetime") {
          return value ? printDateAndTime(value) : "Not set";
        } else if (type === "time") {
          return value ? printTime(value) : "Not set";
        }
        return value ? value : "Not set";
      }}
      type={type as any}
      value={get(currentObj, field)}
      onSave={onSave}
      {...rest}
    />
  );
};

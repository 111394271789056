import {useReadProfile} from "@hooks";
import {useNavigation} from "@react-navigation/native";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {usePatchUsersByIdMutation} from "@store";
import {ConsentStackParamList} from "@types";
import {BooleanField, Box, Button, Heading, Text} from "ferns-ui";
import React, {ReactElement, useEffect} from "react";

import {ConsentFormContents} from "../constants/ConsentFormContents";

const consentFormContents = ConsentFormContents[ConsentFormContents.length - 1];

export const PermissionsScreen = (): ReactElement => {
  const user = useReadProfile();
  const [updateUser, {isLoading}] = usePatchUsersByIdMutation();
  const [pushNotifications, setPushNotifications] = React.useState(
    user?.pushNotifications ?? false
  );
  const [smsNotifications, setSmsNotifications] = React.useState(user?.smsNotifications ?? false);
  const [smsMessaging, setSmsMessaging] = React.useState(user?.smsMessaging ?? false);
  const navigation =
    useNavigation<NativeStackNavigationProp<ConsentStackParamList, "ConsentFormScreen">>();

  // Set the title of the screen to the title of the consent form.
  useEffect(() => {
    navigation.setOptions({title: consentFormContents.title});
  }, [navigation]);

  if (!user) {
    return <Text>Loading...</Text>;
  }

  const handleSave = async (): Promise<void> => {
    await updateUser({
      id: user._id,
      body: {smsMessaging, smsNotifications, pushNotifications},
    }).unwrap();
  };
  return (
    <>
      <Box alignItems="center" color="base" flex="grow" justifyContent="center" scroll width="100%">
        <Box maxWidth={800} paddingX={5} paddingY={2} width="100%">
          <Box marginBottom={2} paddingY={2}>
            <Heading size="sm">Push Notifications</Heading>
            <Box paddingY={1}>
              <Text>We use push notifications to blah blah</Text>
            </Box>
            <Box marginTop={2} width="100%">
              <BooleanField
                title="Push Notifications"
                value={pushNotifications}
                onChange={setPushNotifications}
              />
            </Box>
          </Box>
          <Box marginBottom={2} paddingY={2}>
            <Heading size="sm">SMS Notifications</Heading>
            <Box paddingY={1}>
              <Text>We use push notifications to blah blah</Text>
            </Box>
            <Box marginTop={2} width="100%">
              <BooleanField
                title="SMS Notifications"
                value={smsNotifications}
                onChange={setSmsNotifications}
              />
            </Box>
          </Box>
          <Box marginBottom={2} paddingY={2}>
            <Heading size="sm">SMS Messaging</Heading>
            <Box paddingY={1}>
              <Text>We use push notifications to blah blah</Text>
            </Box>
            <Box marginTop={2} width="100%">
              <BooleanField title="SMS Messaging" value={smsMessaging} onChange={setSmsMessaging} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box paddingY={2} width="100%">
        <Box
          alignSelf="center"
          color="neutral"
          direction="row"
          display="flex"
          maxWidth={800}
          paddingX={5}
          paddingY={2}
          width="100%"
        >
          <Box flex="grow" marginRight={6}>
            <Button
              disabled={isLoading}
              loading={isLoading}
              text="No to all"
              variant="destructive"
              onClick={handleSave}
            />
          </Box>

          <Box flex="grow">
            <Button
              disabled={isLoading}
              loading={isLoading}
              text={
                smsMessaging || smsNotifications || pushNotifications ? "Continue" : "Yes to all"
              }
              onClick={handleSave}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

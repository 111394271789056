import {useGetFormInstancesQuery} from "@store";
import {IsMobileDevice} from "@utils";
import {Box, printOnlyDate, Text, useTheme} from "ferns-ui";
import React from "react";
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";

type AssessmentType = "PHQ-9" | "GAD-7" | "Flourishing";

// Scoring ranges for these found at the bottom of this PRD:
// https://www.notion.so/flourishhealth/ISP-Assessments-Display-5156befd6ed54f50ae65dbe58c299765
const getMax = function (type: AssessmentType): number {
  if (type === "PHQ-9") {
    return 27;
  } else if (type === "GAD-7") {
    return 21;
  } else if (type === "Flourishing") {
    return 28;
  } else {
    throw new Error(`Unsupported assessment type: ${type}`);
  }
};

const getMin = function (type: AssessmentType): number {
  if (type === "PHQ-9") {
    return 0;
  } else if (type === "GAD-7") {
    return 0;
  } else if (type === "Flourishing") {
    return 0;
  } else {
    throw new Error(`Unsupported assessment type: ${type}`);
  }
};

interface Props {
  userId: string;
  assessmentType: AssessmentType;
}

export const AssessmentHistoryLineChart = ({
  userId,
  assessmentType,
}: Props): React.ReactElement | null => {
  const {theme} = useTheme();
  const {data: assessmentsData} = useGetFormInstancesQuery({
    userId,
    type: "Assessment",
    "form.assessmentType": assessmentType,
    sort: "-serviceDate",
  });
  if (!assessmentsData?.data) {
    return null;
  }
  let noChartInfoMessage;
  if (IsMobileDevice) {
    noChartInfoMessage = "Charts not currently supported on mobile.";
  } else if (assessmentsData.data.length < 1) {
    noChartInfoMessage = "No data yet.";
  }
  const processedAssessmentData = assessmentsData.data
    .filter((d) => Boolean(d.serviceDate))
    .reverse();
  return (
    <Box marginBottom={6}>
      <Text bold size="md">{`${assessmentType} score over time:`}</Text>
      {noChartInfoMessage ? (
        <Text>{noChartInfoMessage}</Text>
      ) : (
        <Box color="base" marginBottom={2} marginTop={2} paddingY={6} rounding="md">
          <ResponsiveContainer height={300} width="95%">
            <LineChart
              data={processedAssessmentData.map((d) => {
                return {date: printOnlyDate(d.serviceDate), score: Number(d.totalScore)};
              })}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis domain={[getMin(assessmentType), getMax(assessmentType)]} />
              <Tooltip />
              <Line dataKey="score" stroke={theme.surface.primary} type="linear" />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      )}
    </Box>
  );
};

import {
  ActivityLogView,
  ClinicalView,
  FitbitView,
  StaffRightBar,
  UserInfoView,
  WorkflowChatView,
  WorkflowItemData,
  WorkflowListHeader,
  WorkflowMappingItem,
} from "@components";
import {useCurrentWorkflowMapping, useGetPopulateUserLookup, useReadProfile} from "@hooks";
import {useBottomTabBarHeight} from "@react-navigation/bottom-tabs";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  setWorkflowMappingId,
  useAppDispatch,
  useGetConversationsQuery,
  useGetUsersByIdQuery,
  useGetWorkflowMappingsQuery,
  useOutboundPhoneCallMutation,
  useSelectUserSettings,
  useSelectWorkflowStaffId,
  WorkflowMapping,
} from "@store";
import {StaffTabScreenProps} from "@types";
import {hasFeatureFlag, IsMobileDevice, isPatientOrFamilyMember, UserTypes} from "@utils";
import {Box, Button, humanDate, IconButton, ScrollView, SplitPage, Text, useToast} from "ferns-ui";
import {DateTime} from "luxon";
import React, {ReactElement, useCallback, useEffect, useMemo, useState} from "react";
import {ListRenderItemInfo, StyleProp, ViewStyle} from "react-native";

interface WorkflowScreenProps extends StaffTabScreenProps<"Workflows"> {}

export interface SendWorkflowItemsProps {
  userId: string;
  role: string;
}

export const StaffScreen = ({navigation}: WorkflowScreenProps): ReactElement => {
  const dispatch = useAppDispatch();
  const staffId = useSelectWorkflowStaffId();
  const [outboundPhoneCall] = useOutboundPhoneCallMutation();
  const toast = useToast();
  const profile = useReadProfile();
  const userSettings = useSelectUserSettings();

  const currentWorkflowMapping = useCurrentWorkflowMapping();
  const workflowMappings = useGetWorkflowMappingsQuery(staffId ? {staffId} : skipToken);
  const [showMobileItemList, setShowMobileItemList] = useState<boolean>(true);
  const [listFilter, setListFilter] = useState<string>("");
  const [showHelpModal, setShowHelpModal] = useState(false);

  // Patient, family member, or staff user who the workflow mapping is about
  const userId = currentWorkflowMapping?.userId;
  const {data: selectedUser} = useGetUsersByIdQuery(userId ?? skipToken);

  const userIdsInWorkflowMappings = workflowMappings?.data?.data?.map((wm) => wm.userId) ?? [];
  const {data: allWorkflowConversations} = useGetConversationsQuery(
    staffId && userIdsInWorkflowMappings.length
      ? ({
          $and: [{"users.userId": staffId}, {"users.userId": {$in: userIdsInWorkflowMappings}}],
          type: {$in: [UserTypes.Patient, UserTypes.FamilyMember]},
        } as any)
      : skipToken
  );
  const {userLookup} = useGetPopulateUserLookup(userIdsInWorkflowMappings);

  const bottomTabBarHeight = useBottomTabBarHeight();

  const createWorkflowItemData = useCallback(
    (workflowMapping: WorkflowMapping): WorkflowItemData => {
      const convo = allWorkflowConversations?.data?.find(
        (c) =>
          isPatientOrFamilyMember(c.type as UserTypes) &&
          c.users &&
          c.users.find((u) => u.userId === workflowMapping.userId) &&
          c.users.find((u) => u.userId === workflowMapping.staffId)
      );

      const hasUnread = Boolean(convo?.unreadCount);

      let sortKey = new Date(workflowMapping.created);

      // If the conversation has been created, we use that as the sort key
      if (convo?.created) {
        sortKey = new Date(convo.created);
      }

      // We add hasUnread to this conditional to ignore the lastMessageSentDate if the conversation
      // has been read/responded to
      if (convo?.lastMessageSentDate) {
        sortKey = new Date(convo?.lastMessageSentDate);
      }

      const workflowMappingUser = userLookup?.[workflowMapping.userId ?? ""];

      let workflowStatus: WorkflowItemData["status"] | undefined;
      let snoozedUntil: string | undefined;

      const reorganizedWorkflowMapping =
        userSettings?.panelConfig?.reorganizedWorkflowMappings?.find(
          (rwm) => rwm.workflowMappingId === workflowMapping._id
        );
      if (profile && reorganizedWorkflowMapping) {
        if (reorganizedWorkflowMapping.pinned) {
          workflowStatus = "pinned";
        } else if (
          reorganizedWorkflowMapping.snoozedUntil &&
          DateTime.fromISO(reorganizedWorkflowMapping.snoozedUntil) > DateTime.now()
        ) {
          workflowStatus = "snoozed";
          snoozedUntil = humanDate(reorganizedWorkflowMapping.snoozedUntil || "");
        } else if (reorganizedWorkflowMapping.completed) {
          workflowStatus = "completed";
        }
      }

      return {
        _id: workflowMapping._id,
        // SplitPage uses .id as the key
        id: workflowMapping._id,
        hasUnread,
        sortKey,
        workflowMapping,
        workflowMappingUser,
        lastMessageSentDate: convo?.lastMessageSentDate,
        status: workflowStatus,
        snoozedUntil,
      };
    },
    [
      allWorkflowConversations?.data,
      profile,
      userLookup,
      userSettings?.panelConfig?.reorganizedWorkflowMappings,
    ]
  );

  const listViewData: WorkflowItemData[] = useMemo(() => {
    if (!profile || !workflowMappings?.data?.data) {
      return [];
    }

    const mappingData: WorkflowItemData[] = workflowMappings?.data?.data.map((wm) =>
      createWorkflowItemData(wm)
    );

    // Within pinned messages, sort the ones with new messages first
    const pinnedNewMessageWorkflowMappings = mappingData.filter(
      (md) => md.status === "pinned" && md.hasUnread
    );
    const pinnedWorkflowMappings = mappingData.filter(
      (md) => md.status === "pinned" && !md.hasUnread
    );

    // Pinned takes precedence over unread
    const unreadWorkflowMappings = mappingData.filter(
      (md) => md.hasUnread && md.status !== "pinned"
    );
    const snoozedWorkflowMappings = mappingData.filter(
      (md) => md.status === "snoozed" && !md.hasUnread
    );
    const completedWorkflowMappings = mappingData.filter(
      (md) => md.status === "completed" && !md.hasUnread
    );

    const noStatusWorkflowMappings = mappingData.filter(
      (md) =>
        md.status !== "pinned" &&
        md.status !== "snoozed" &&
        md.status !== "completed" &&
        !md.hasUnread
    );

    // Sort by status then by last message sent date
    // Statuses: pinned (with new message), pinned, unread, no status, snoozed, completed
    return [
      ...pinnedNewMessageWorkflowMappings.sort(
        (wm1, wm2) => wm2.sortKey.getTime() - wm1.sortKey.getTime()
      ),
      ...pinnedWorkflowMappings.sort((wm1, wm2) => wm2.sortKey.getTime() - wm1.sortKey.getTime()),
      ...unreadWorkflowMappings.sort((wm1, wm2) => wm2.sortKey.getTime() - wm1.sortKey.getTime()),
      ...noStatusWorkflowMappings.sort((wm1, wm2) => wm2.sortKey.getTime() - wm1.sortKey.getTime()),
      ...snoozedWorkflowMappings.sort((wm1, wm2) => wm2.sortKey.getTime() - wm1.sortKey.getTime()),
      ...completedWorkflowMappings.sort(
        (wm1, wm2) => wm2.sortKey.getTime() - wm1.sortKey.getTime()
      ),
    ].filter((wm) => wm.workflowMappingUser?.name.toLowerCase().includes(listFilter.toLowerCase()));
  }, [workflowMappings?.data?.data, createWorkflowItemData, profile, listFilter]);

  const renderOutboundPhoneCallButton = useCallback((): ReactElement | null => {
    if (!profile || !selectedUser || !hasFeatureFlag(profile, "enableTwilioCalling")) {
      return null;
    }
    return (
      <IconButton
        accessibilityLabel="Call User"
        iconName="phone"
        onClick={async (): Promise<void> => {
          toast.show(`Calling phone number: "${selectedUser.name}"`);
          try {
            await outboundPhoneCall({
              callRecipient: {
                id: selectedUser._id,
                name: selectedUser.name,
                phoneNumber: selectedUser.phoneNumber,
              },
            }).unwrap();
          } catch (error: any) {
            toast.error(`${error?.data?.title ?? "Unknown error calling user"}`);
          }
        }}
      />
    );
  }, [outboundPhoneCall, profile, selectedUser, toast]);

  const renderCrisisEventHelpButton = useCallback((): ReactElement | null => {
    if (!profile || !selectedUser) {
      return null;
    }
    return (
      <Box alignItems="center" marginRight={1}>
        <Button text="Help" variant="outline" onClick={() => setShowHelpModal(true)} />
      </Box>
    );
  }, [profile, selectedUser, setShowHelpModal]);

  const renderHeaderRightButtons = useCallback((): ReactElement | null => {
    if (!profile || !selectedUser) {
      return null;
    }
    return (
      <Box alignItems="center" direction="row" gap={2}>
        {hasFeatureFlag(profile, "enableTwilioCalling") && renderOutboundPhoneCallButton()}
        {renderCrisisEventHelpButton()}
      </Box>
    );
  }, [renderOutboundPhoneCallButton, renderCrisisEventHelpButton, profile, selectedUser]);

  // For mobile, set the header to show the selected user's name and pronouns.
  useEffect(() => {
    if (IsMobileDevice && selectedUser) {
      navigation.setOptions({
        headerLeft: () => {
          return (
            <Box paddingX={2}>
              <IconButton
                accessibilityLabel="deselect mobile item"
                iconName="arrow-left"
                variant="muted"
                onClick={(): void => {
                  setShowMobileItemList(true);
                  dispatch(setWorkflowMappingId(undefined));

                  // reset navigation to default options
                  navigation.reset({stale: true, routes: [{name: "Workflows"}]});
                }}
              />
            </Box>
          );
        },
        headerTitle: () => {
          return (
            <Box>
              <Text align="center">{selectedUser?.name}</Text>
              {Boolean(selectedUser?.pronouns) && (
                <Text align="center" color="secondaryLight" size="sm">
                  {selectedUser?.pronouns}
                </Text>
              )}
            </Box>
          );
        },
        headerRight: () => renderHeaderRightButtons(),
      });
    }
  }, [selectedUser, dispatch, navigation, renderHeaderRightButtons]);

  const contentContainerStyle: StyleProp<ViewStyle> = {backgroundColor: "#ffffff"};

  return (
    <SplitPage
      bottomNavBarHeight={(bottomTabBarHeight || 0) + 35}
      listViewData={listViewData}
      renderListViewHeader={(): ReactElement => (
        <WorkflowListHeader onListFilterChange={({name}) => setListFilter(name || "")} />
      )}
      renderListViewItem={(itemInfo): ReactElement => <WorkflowMappingItem itemInfo={itemInfo} />}
      selectLimit={2}
      showItemList={showMobileItemList}
      tabs={["Chat", "Clinical", "User", "Fitbit"]}
      onSelectionChange={async (itemInfo: ListRenderItemInfo<WorkflowMapping>): Promise<void> => {
        if (itemInfo?.item?._id) {
          if (IsMobileDevice) {
            setShowMobileItemList(false);
          }
          dispatch(setWorkflowMappingId(itemInfo?.item?._id));
        }
      }}
    >
      <WorkflowChatView
        key={userId}
        setShowHelpModal={setShowHelpModal}
        showHelpModal={showHelpModal}
      />

      {/* These ScrollViews allow the nested ScrollViews within each component View to be scrollable within the swiping container for mobile app */}
      {Boolean(IsMobileDevice) ? (
        <>
          <ScrollView contentContainerStyle={contentContainerStyle}>
            <ClinicalView userId={userId} />
          </ScrollView>
          <ScrollView contentContainerStyle={contentContainerStyle}>
            <UserInfoView
              userId={userId}
              onRemoveUser={(): void => {
                dispatch(setWorkflowMappingId(undefined));
              }}
            />
          </ScrollView>

          <ScrollView contentContainerStyle={contentContainerStyle}>
            <FitbitView userId={userId} />
          </ScrollView>
          <ScrollView contentContainerStyle={contentContainerStyle}>
            <ActivityLogView userId={userId || ""} />
          </ScrollView>
        </>
      ) : (
        <StaffRightBar userId={userId} />
      )}
    </SplitPage>
  );
};

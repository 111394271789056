import {UserList} from "@components";
import {useGetPopulatedConversationById} from "@hooks";
import {skipToken} from "@reduxjs/toolkit/query";
import {
  Conversation,
  usePatchConversationsByIdMutation,
  usePostConversationsMutation,
  User,
} from "@store";
import {StaffStackScreenProps} from "@types";
import {pageOnError} from "@utils";
import {APIError, Box, Button, Heading, Page, Text, TextField, useToast} from "ferns-ui";
import React, {ReactElement, useEffect, useState} from "react";

// Transform from populated users to user IDs to interact with the API
function transformConversationParticipants(
  conversationParticipants?: User[]
): Conversation["users"] {
  return (conversationParticipants ?? []).map((participant) => ({
    userId: participant._id,
  }));
}

interface ManageConversationsScreenProps
  extends StaffStackScreenProps<"ManageConversationsScreen"> {}

export const ManageConversationsScreen = ({
  route,
  navigation,
}: ManageConversationsScreenProps): ReactElement => {
  const [
    createConversation,
    {isLoading: createLoading, error: createError, isSuccess: createSuccess},
  ] = usePostConversationsMutation();
  const [
    updateConversation,
    {isLoading: updateLoading, error: updateError, isSuccess: updateSuccess},
  ] = usePatchConversationsByIdMutation();

  const toasts = useToast();

  // Show a toast when the conversation is created or updated
  useEffect(() => {
    if (updateSuccess) {
      toasts.show("Conversation changes saved successfully");
    }
    if (createSuccess) {
      toasts.show("Conversation created successfully");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSuccess, createSuccess]);

  const [name, setName] = useState<string>("");
  const [conversationParticipants, setConversationParticipants] = useState<User[]>([]);
  const [referencedUsers, setReferencedUsers] = useState<User[]>([]);

  const {data: conversationData} = useGetPopulatedConversationById(
    route.params.conversationId ? route.params.conversationId : skipToken
  );

  function getUsers(users: {userId?: User | undefined}[]): User[] {
    return users.map((u: any) => u.userId).filter((u: any) => u);
  }

  // If we have conversation data, set the name and participants
  useEffect(() => {
    if (!conversationData) {
      return;
    }
    conversationData.name && setName(conversationData.name);
    conversationData.users && setConversationParticipants(getUsers(conversationData.users));
    conversationData.referencedUsers &&
      setReferencedUsers(getUsers(conversationData.referencedUsers));
  }, [conversationData]);

  // If we're creating a new conversation, and we have a user to start with, add them to the
  useEffect(() => {
    // we only want to do this if it's a new conversation
    if (!route.params.conversationId && route.params.currentUser) {
      setConversationParticipants([route.params.currentUser]);
    }
  }, [route.params.conversationId, route.params.currentUser]);

  const headingTitle = route.params.conversationId ? "Edit Conversation" : "Create Conversation";

  const disableCreateOrSave =
    createLoading || updateLoading || conversationParticipants?.length < 2;

  return (
    <Page navigation={navigation} onError={pageOnError}>
      <Box paddingY={2}>
        <Heading>{headingTitle}</Heading>
      </Box>
      <Box paddingY={2} />
      <Box paddingY={2}>
        <TextField
          title="Name"
          value={name}
          onChange={(value): void => {
            setName(value);
          }}
        />
      </Box>

      {/* 1:1 staff-to-staff conversations should not allow user editing or referenced users */}
      {Boolean(conversationData?.type !== "Staff") && (
        <>
          <Box paddingY={2}>
            <Heading size="sm">Conversation Participants</Heading>
            <Text>Staff users who will participate in the conversation.</Text>
            <UserList
              buttonText="Add Staff"
              staff
              userPickerTitle="Conversation Staff"
              users={conversationParticipants}
              onChangeUsers={(users): void => {
                setConversationParticipants(users);
              }}
            />
          </Box>
          <Box paddingY={2}>
            <Heading size="sm">Referenced Users</Heading>
            <Text>
              Patients and family members whose care will be discussed in the conversation. These
              users do not have access to this conversation.
            </Text>

            <UserList
              buttonText="Add Referenced User"
              familyMember
              patient
              userPickerTitle="Referenced Users"
              users={referencedUsers}
              onChangeUsers={(users): void => {
                setReferencedUsers(users);
              }}
            />
          </Box>
        </>
      )}

      <Box paddingY={4} width={225}>
        <Button
          disabled={disableCreateOrSave}
          loading={createLoading || updateLoading}
          text={conversationData?._id ? "Save Conversation" : "Create Conversation"}
          onClick={async (): Promise<void> => {
            if (conversationData?._id) {
              await updateConversation({
                id: conversationData?._id,
                body: {
                  name,
                  users: transformConversationParticipants(conversationParticipants),
                  referencedUsers: transformConversationParticipants(referencedUsers),
                },
              });
            } else {
              await createConversation({
                _id: conversationData?._id,
                name,
                users: transformConversationParticipants(conversationParticipants),
                referencedUsers: transformConversationParticipants(referencedUsers),
                type: "Multi",
              });
            }
            if (!createError && !updateError) {
              navigation.pop();
            }
          }}
        />
      </Box>
      {Boolean(!createError || !updateError) && (
        <Box paddingY={2}>
          <Text bold color="error">
            {(createError as APIError)?.data?.title || (updateError as APIError)?.data?.title}
          </Text>
        </Box>
      )}
    </Page>
  );
};

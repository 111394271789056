import {BILLINGGENDEROPTIONS} from "@constants";
import {
  BillingInfo,
  useGetInsurancePlansQuery,
  useGetUsersByIdQuery,
  usePatchUsersByIdMutation,
  useUIError,
} from "@store";
import {StaffStackScreenProps} from "@types";
import {pageOnError} from "@utils";
import {Box, Button, DateTimeField, Page, SelectField, Spinner, TextField} from "ferns-ui";
import React, {useEffect, useState} from "react";

interface Props extends StaffStackScreenProps<"BillingInfo"> {}

export const BillingInfoScreen = ({route, navigation}: Props): React.ReactElement => {
  const userId = route.params.userId;
  const {data: insuranceData} = useGetInsurancePlansQuery({});

  const user = useGetUsersByIdQuery(userId).data;
  const [updateUser, {isLoading}] = usePatchUsersByIdMutation();

  const uiError = useUIError();
  const [insurancePlan, setInsurancePlan] = useState<string | undefined>();
  const [billingInfo, setBillingInfo] = useState<Partial<BillingInfo>>({
    firstName: "",
    lastName: "",
    gender: "male",
    // The date a user's Medicaid plan would renew,
    // required so we can make sure they stay enrolled and don't lose coverage
    renewalDate: undefined,
    // unique user id for the users health insurance
    memberId: "",
    // Sometimes called a policy id, an optionally issued ID unique to a group of users enrolled in
    // the same plan, such as through an employer
    groupId: "",
    // This id identifies the specific plan the user is enrolled in
    healthPlanId: "",
    policyHolder: "self",
    authorizationCode: "",
  });

  // Once we fetch the user, update local state.
  useEffect(() => {
    if (!user) {
      return;
    }
    setBillingInfo({
      firstName: user.billingInfo?.firstName,
      lastName: user.billingInfo?.lastName,
      renewalDate: user.billingInfo?.renewalDate,
      memberId: user.billingInfo?.memberId,
      groupId: user.billingInfo?.groupId,
      healthPlanId: user.billingInfo?.healthPlanId,
      policyHolder: user.billingInfo?.policyHolder,
      authorizationCode: user.billingInfo?.authorizationCode,
    });
    setInsurancePlan(user.billingInfo?.insurancePlan);
  }, [user]);

  if (!user) {
    return <Spinner />;
  }

  const saveBillingInfo = async (): Promise<void> => {
    try {
      await updateUser({
        id: user._id,
        body: {
          billingInfo: {
            ...billingInfo,
            insurancePlan: insurancePlan || (null as any),
          } as BillingInfo,
        },
      }).unwrap();
    } catch (error: any) {
      uiError(`Error saving billing info`, error);
      return;
    }
    navigation.goBack();
  };

  return (
    <Page navigation={navigation} onError={pageOnError}>
      <Box direction="column" paddingX={4} paddingY={2} scroll>
        <TextField
          helperText="First name as it appears on their insurance card/legal name"
          title="Billing First Name"
          value={billingInfo?.firstName}
          onChange={(value: any): void => {
            setBillingInfo({...billingInfo, firstName: value});
          }}
        />
        <TextField
          helperText="Last name as it appears on their insurance card/legal name"
          title="Billing Last Name"
          value={billingInfo?.lastName}
          onChange={(value: any): void => {
            setBillingInfo({...billingInfo, lastName: value});
          }}
        />
        <SelectField
          helperText="Gender as it appears on their insurance card"
          options={BILLINGGENDEROPTIONS.map((gender) => ({label: gender, value: gender}))}
          placeholder="---"
          requireValue={false}
          title="Billing Gender"
          value={billingInfo?.gender}
          onChange={(value: any): void => {
            setBillingInfo({...billingInfo, gender: value});
          }}
        />
        <SelectField
          helperText="Insurance Plan"
          options={[
            ...(insuranceData?.data ?? []).map((insurance) => ({
              label: insurance.name,
              value: insurance._id,
            })),
          ]}
          placeholder="---"
          requireValue={false}
          title="Insurance Plan"
          value={insurancePlan}
          onChange={(value: any): void => {
            setInsurancePlan(value);
          }}
        />
        <DateTimeField
          helperText="Date plan renews so we can make sure to support them in getting renewed."
          title="Renewal Date"
          type="date"
          value={billingInfo?.renewalDate}
          onChange={(value: any): void => {
            setBillingInfo({...billingInfo, renewalDate: value});
          }}
        />
        <TextField
          title="Health Plan Member ID"
          value={billingInfo?.memberId}
          onChange={(value: any): void => {
            setBillingInfo({...billingInfo, memberId: value});
          }}
        />
        <TextField
          title="Health Plan Group ID"
          value={billingInfo?.groupId}
          onChange={(value: any): void => {
            setBillingInfo({...billingInfo, groupId: value});
          }}
        />
        <TextField
          title="Health Plan ID"
          value={billingInfo?.healthPlanId}
          onChange={(value: any): void => {
            setBillingInfo({...billingInfo, healthPlanId: value});
          }}
        />
        <TextField
          title="Health Plan Policy Holder"
          value={billingInfo?.policyHolder}
          onChange={(value: any): void => {
            setBillingInfo({...billingInfo, policyHolder: value});
          }}
        />
        <TextField
          title="Authorization Code"
          value={billingInfo?.authorizationCode}
          onChange={(value: any): void => {
            setBillingInfo({...billingInfo, authorizationCode: value});
          }}
        />
        <Box paddingY={2} width={200}>
          <Button disabled={isLoading} loading={isLoading} text="Save" onClick={saveBillingInfo} />
        </Box>
      </Box>
    </Page>
  );
};
